import {useContext, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import {AppContext} from "../AppContext";

const StudyLogConfirmProcess = ({log, id, fetchData}) => {

    const app = useContext(AppContext)

    function hasNullProperty(obj) {
        // Tạo một bản sao mới của object để không ảnh hưởng đến object gốc
        const objWithoutTimestamps = {...obj};

        // Loại bỏ thuộc tính "created_at" và "updated_at"
        delete objWithoutTimestamps.created_at;
        delete objWithoutTimestamps.updated_at;
        delete objWithoutTimestamps.accepted_time;
        delete objWithoutTimestamps.accepted_user;

        // Kiểm tra xem bản sao mới có bất kỳ thuộc tính nào bị null hoặc undefined
        const keys = Object.keys(objWithoutTimestamps);
        return keys.some(key => objWithoutTimestamps[key] === null || objWithoutTimestamps[key] === undefined);
    }

    const confirmProcess = ({type}) => {
        confirmAlert({
            title: 'Xác nhận',
            message: 'Khi xác nhận sẽ không thể hủy, bạn là người chịu trách nhiệm cho hành động này !',
            buttons: [
                {
                    label: 'Xác nhận',
                    onClick: () => {
                        app.studyLogService.confirmProcess({type: type, study_log_id: id}).then((r) => {
                            fetchData()
                        })
                    }
                },
                {
                    label: 'Hủy',
                    onClick: () => {
                        // Xử lý hành động khi hủy bỏ
                    }
                }
            ]
        });
    };
    const [currentProcess, setCurrentProcess] = useState({
        message: "Xác nhận KHÔNG ĐIỂM DANH THỪA học sinh",
        status_type: ""
    })


    return (
        <div className={"my-5"}>
            <div className={"my-5"}>
                <div className={""}>1. Kiểm tra điểm danh thừa học sinh</div>
                {log.process?.more_student_check_time === null ?
                    <div className={"mb-5"}>
                        <div className={"bg-white p-2 border rounded"}>
                            <div className={"text-red-600 font-bold mb-3"}>{currentProcess.message}</div>
                            <div
                                onClick={() => {
                                    confirmProcess({type: "more_student_check"})
                                }}
                                className={"hover:bg-green-900 cursor-pointer bg-green-400 transition-all text-white p-2 w-[10rem] text-center rounded"}>Xác
                                nhận
                            </div>
                        </div>
                    </div> :
                    <div className={"text-green-800"}>Đã xác nhận bởi <b>{log.process?.more_student_check_user}</b> vào
                        lúc {log.process?.more_student_check_time}</div>
                }
            </div>

            <div className={"my-5"}>
                <div className={""}>2. Kiểm tra điểm danh thiếu học sinh</div>
                {log.process?.less_student_check_time === null && log.process?.more_student_check_time !== null ?
                    <div className={"mb-5"}>
                        <div className={"bg-white p-2 border rounded"}>
                            <div className={"text-red-600 font-bold mb-3"}>{currentProcess.message}</div>
                            <div
                                onClick={() => {
                                    confirmProcess({type: "less_student_check"})
                                }}
                                className={"hover:bg-green-900 cursor-pointer bg-green-400 transition-all text-white p-2 w-[10rem] text-center rounded"}>Xác
                                nhận
                            </div>
                        </div>
                    </div> :
                    <div>
                        {log.process?.less_student_check_time !== null ?
                            <div className={"text-green-800"}>Đã xác nhận
                                bởi <b>{log.process?.less_student_check_user}</b> vào
                                lúc {log.process?.less_student_check_time}</div>
                            :
                            <div></div>
                        }
                    </div>
                }
            </div>

            <div className={"my-5"}>
                <div className={""}>3. Kiểm tra điểm danh sai trạng thái</div>
                {log.process?.wrong_student_check_time === null && log.process?.less_student_check_time !== null ?
                    <div className={"mb-5"}>
                        <div className={"bg-white p-2 border rounded"}>
                            <div className={"text-red-600 font-bold mb-3"}>{currentProcess.message}</div>
                            <div
                                onClick={() => {
                                    confirmProcess({type: "wrong_student_check"})
                                }}
                                className={"hover:bg-green-900 cursor-pointer bg-green-400 transition-all text-white p-2 w-[10rem] text-center rounded"}>Xác
                                nhận
                            </div>
                        </div>
                    </div> :
                    <div>
                        {log.process?.wrong_student_check_time !== null ?
                            <div className={"text-green-800"}>Đã xác nhận
                                bởi <b>{log.process?.wrong_student_check_user}</b> vào
                                lúc {log.process?.wrong_student_check_time}
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                }
            </div>

            <div className={"my-5"}>
                <div className={""}>4. Kiểm tra điểm danh ca học</div>
                {log.process?.working_shift_check_time === null && log.process?.wrong_student_check_time !== null ?
                    <div className={"mb-5"}>
                        <div className={"bg-white p-2 border rounded"}>
                            <div className={"text-red-600 font-bold mb-3"}>{currentProcess.message}</div>
                            <div
                                onClick={() => {
                                    confirmProcess({type: "working_shift_check"})
                                }}
                                className={"hover:bg-green-900 cursor-pointer bg-green-400 transition-all text-white p-2 w-[10rem] text-center rounded"}>Xác
                                nhận
                            </div>
                        </div>
                    </div> :
                    <div>
                        {log.process?.working_shift_check_time !== null ?
                            <div className={"text-green-800"}>Đã xác nhận
                                bởi <b>{log.process?.working_shift_check_user}</b> vào
                                lúc {log.process?.working_shift_check_time}
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                }
            </div>

            <div className={"my-5"}>
                <div className={""}>5. Kiểm tra đúng thông tin giáo viên</div>
                {log.process?.teacher_check_time === null && log.process?.working_shift_check_time !== null ?
                    <div className={"mb-5"}>
                        <div className={"bg-white p-2 border rounded"}>
                            <div className={"text-red-600 font-bold mb-3"}>{currentProcess.message}</div>
                            <div
                                onClick={() => {
                                    confirmProcess({type: "teacher_check"})
                                }}
                                className={"hover:bg-green-900 cursor-pointer bg-green-400 transition-all text-white p-2 w-[10rem] text-center rounded"}>Xác
                                nhận
                            </div>
                        </div>
                    </div> :
                    <div>
                        {log.process?.teacher_check_time !== null ?
                            <div className={"text-green-800"}>Đã xác nhận
                                bởi<b>{log.process?.teacher_check_user}</b> vào lúc {log.process?.teacher_check_time}
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                }
            </div>
            <div className={"my-5"}>
                <div className={""}>6. Kiểm tra đúng thông tin trợ giảng</div>
                {log.process?.supporter_check_time === null && log.process?.teacher_check_time !== null ?
                    <div className={"mb-5"}>
                        <div className={"bg-white p-2 border rounded"}>
                            <div className={"text-red-600 font-bold mb-3"}>{currentProcess.message}</div>
                            <div
                                onClick={() => {
                                    confirmProcess({type: "supporter_check"})
                                }}
                                className={"hover:bg-green-900 cursor-pointer bg-green-400 transition-all text-white p-2 w-[10rem] text-center rounded"}>Xác
                                nhận
                            </div>
                        </div>
                    </div> :
                    <div>
                        {log.process?.supporter_check_time !== null ?
                            <div className={"text-green-800"}>Đã xác nhận
                                bởi <b>{log.process?.supporter_check_user}</b> vào
                                lúc {log.process?.supporter_check_time}</div>
                            :
                            <div></div>
                        }
                    </div>
                }
            </div>
            {!hasNullProperty(log.process ?? {}) ?
                <div>
                    {log.status === 0 ? <div
                            onClick={() => {
                                app.studyLogService.acceptStudyLog({id}).then((r) => {
                                    fetchData()
                                })
                            }}
                            className={"cursor-pointer bg-green-900 w-[15rem] text-center p-2 rounded text-white font-bold"}>Hoàn
                            tất quy trình duyệt</div> :
                        <div className={"text-green-800 font-bold flex items-center"}>
                            <i className='bx bxs-badge-check mr-2'></i>
                            Đã duyệt buổi học bởi {log.process?.accepted_user} lúc {log.process?.accepted_time}</div>
                    }
                </div>
                :
                <div>
                    <div className={"text-sm mb-3"}>Hoàn tất 6 bước kiểm duyệt để hoàn tất quy trình duyệt buổi học
                    </div>
                    <div
                        onClick={() => {
                            app.studyLogService.deleteStudyLog()
                        }}
                        className={"cursor-pointer bg-red-900 w-[15rem] text-center p-2 rounded text-white font-bold"}>Xóa
                        buổi học
                    </div>
                </div>
            }
        </div>
    )
}
export default StudyLogConfirmProcess