import {AppContext} from "../../../AppContext";
import React, {useContext, useState, useEffect} from 'react';
import SearchInput from "../../Common/SearchInput";
import SelectInput from "../../../components/inputs/SelectInput";
import {toast} from "react-toastify";

function PermissionList() {
    const [groups, setGroup] = useState([])
    const app = useContext(AppContext);
    const [permissions, setPermissions] = useState([]);
    const [user_permissions, setUserPermissions] = useState([]);
    const [data, setData] = useState({
        permission_group: {}
    })
    const [allUsers, setAllUsers] = useState([])
    useEffect(() => {
        app.ruleService.getAllPermission({setPermissions}).then();
        app.authService.getAllUserForSelect({setAllUsers: setAllUsers}).then()
    }, []);

    useEffect(() => {
        getUserPermissionByUsername()
    }, [data.username]);

    const [permissionsAvailability, setPermissionsAvailability] = useState({});

    const togglePermissionAvailability = (permissionId) => {
        if (user_permissions.includes(permissionId)) {
            setUserPermissions(user_permissions.filter((p) => p !== permissionId))
        } else {
            setUserPermissions([...user_permissions, permissionId])
        }
    };

    const getUserPermissionByUsername = () => {
        if (data.username !== undefined) {
            app.ruleService.getPermissionsByUsername({
                username: data.username?.value,
                setUserPermissions: setUserPermissions
            }).then((r) => {
                setData({...data, permission_group: {}})
            })
        }
    };

    const applyPermission = ({user_permissions}) => {
        if (user_permissions !== [] && data.username?.value !== undefined) {
            app.ruleService.applyPermissions({
                postData: {
                    username: data.username?.value,
                    permissions: user_permissions
                }
            }).then()
        }
    }

    useEffect(() => {
        app.ruleService.getAllGroupSelect({
            setGroups: setGroup
        }).then()
    }, [])

    useEffect(() => {
        if (data.permission_group !== {} && data.permission_group?.value !== undefined) {
            app.ruleService.getPermissionsByGroup({id: data.permission_group.value}).then((r) => {
                setUserPermissions(r.data.permissions)
            })
        }
        // setData({...data, permission_group: {}})
    }, [data.permission_group])
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Danh sách quyền:</h1>
            <div className={"my-5 flex flex-wrap"}>
                <div className={"basis-1/4"}>
                    <SelectInput
                        options={allUsers || []}
                        placeholder={"Chọn người dùng"}
                        data={data}
                        setData={setData}
                        name={'username'}
                        label={'Người dùng'}
                    />
                </div>
                <div className={"basis-1/4"}>
                    <SelectInput
                        label={'Nhóm phân quyền'}
                        options={groups || []}
                        placeholder={'Áp dụng nhóm phân quyền'}
                        data={data}
                        setData={setData}
                        name={'permission_group'}
                    />
                </div>
                <div className={"basis-1/6"}>
                    <div className={"mb-7"}></div>
                    <button onClick={() => {
                        applyPermission({user_permissions: user_permissions})
                    }} className={"bg-blue-900 text-white p-1.5 rounded"}>Áp dụng phân quyền
                    </button>
                </div>
            </div>
            <table className="table-auto w-full border border-collapse">
                <thead>
                <tr className="bg-gray-200">
                    <th className="px-4 py-2 border">ID</th>
                    <th className="px-4 py-2 border">Mã</th>
                    <th className="px-4 py-2 border">Tên phân quyền</th>
                    <th className="px-4 py-2 border">Cho phép truy cập</th>
                </tr>
                </thead>
                <tbody>
                {permissions.map(permission => (
                    <tr key={permission.id} className="border-t hover:bg-gray-100">
                        <td className="px-4 py-2 border">{permission.id}</td>
                        <td className="px-4 py-2 border">{permission.name}</td>
                        <td className="px-4 py-2 border">{permission.label}</td>
                        <td className="px-4 py-2 border text-center">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={user_permissions.includes(permission.id)}
                                    onChange={() => togglePermissionAvailability(permission.id)}
                                />
                                <span className="slider"></span>
                            </label>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default PermissionList;
