import {Link} from "react-router-dom";

const AttendanceComment = ({comment}) => {
    const attendance = comment.action_information;
    return (
        <div
            className={"hover:bg-gray-100 cursor-pointer transition-all log bg-white border-b  p-1 py-2 rounded"}>
            <div className={"interactive_user"}>
                <div className={"flex items-center"}>
                    <img
                        className={"w-[2.5rem] h-[2.5rem] rounded-full border mr-2"}
                        src={comment.user_avatar}/>
                    <div className={"text-sm"}>
                        <div className={'font-bold'}>{comment.user_name}</div>
                        <div className={"text-sm text-gray-400"}> {comment.time}</div>
                    </div>
                </div>
            </div>
            <div className={"pl-[3rem] mt-2"}>
                <div>
                    <div>
                        <span className={"mr-2"}>Sử dụng thẻ điểm danh lớp:</span>
                        <Link className={"font-bold text-blue-900"}
                              to={"/classroom/detail/" + attendance.classroom_id}>{attendance.classroom_uuid}</Link>
                    </div>
                    <div>
                        <span className={"mr-2"}>Học sinh được điểm danh:</span>
                        <Link to={"/student/detail/" + attendance.student_id}
                              className={"font-bold text-blue-900"}>{attendance.student_name}</Link>
                    </div>
                    <div>
                        <span className={"mr-2"}>Giáo viên điểm danh:</span>
                        <Link to={"/teacher/detail/" + attendance.study_log.teacher_id}
                              className={"font-bold text-blue-900"}>"Đang ẩn"</Link>
                    </div>
                    <div>
                        <span className={"mr-2"}>Trợ giảng điểm danh:</span>
                        <Link to={"/student/detail/" + attendance.study_log.supporter_id}
                              className={"font-bold text-blue-900"}>"Đang ẩn"</Link>
                    </div>
                    <div>
                        <span className={"mr-2"}>Thời gian điểm danh:</span>
                        <Link className={" text-blue-900"}>{attendance.study_log.created_at}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AttendanceComment