const LateCol = ({value}) => {
    return (
        <td className={"bg-white shadow-lg p-2 px-3 border-[#dee2e6] border-[0.2px] min-w-[5rem]"}>
            <div className={"flex justify-center items-center"}>
                {value ?
                    <img className={"w-[2rem]"} src={"https://cdn0.iconfinder.com/data/icons/shift-interfaces/32/Error-512.png"} alt={""}/>
                    : <span>Không có</span>
                }
            </div>
        </td>
    )
}
export default LateCol