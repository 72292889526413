import {useContext, useEffect, useState} from "react";
import PopupLayout from "../layouts/PopupLayout";
import {AppContext} from "../AppContext";
import SelectInput from "../components/inputs/SelectInput";
import TextInput from "../components/inputs/TextInput";
import NumberInput from "../components/inputs/NumberInput";
import FetchingAnimation from "../components/FetchingAnimation";
import CardCreateScreen from "../screens/Card/CardCreateScreen";
import RichTextInput from "../components/inputs/RichTextInput";

const QuickCreateCardPopup = () => {
    const app = useContext(AppContext)
    const [init, setInit] = useState({})
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({
        card_transaction: [],
        card_status: 1,
        bonus_lessons:0,
        reality_lessons:0,
        promotion_amount:0,
        reality_amount:0
    })
    const uploadCard = () => {
        app.cardService.quickCreateCard({postData: data, setFetching: setFetching}).then()
    }


    useEffect(() => {
        setData({...data, total_lessons: parseInt(data.bonus_lessons || "0") + parseInt(data.reality_lessons)})
    }, [data.bonus_lessons, data.reality_lessons])


    useEffect(() => {
        setData({
            ...data,
            total_amount: parseInt(data.reality_amount) - parseInt(data.promotion_amount || "0"),
            promotion_percent: ((data.promotion_amount || 0) / (data.reality_amount)) * 100,
        })
    }, [data.reality_amount, data.promotion_amount])

    useEffect(() => {
        setData({...data, amount_per_lesson: (parseFloat(data.total_amount) / parseInt(data.total_lessons))})
    }, [data.total_lessons, data.total_amount])

    useEffect(() => {
        app.cardService.initForCard({setInit, setFetching}).then()
    }, [])

    return (
        <PopupLayout>
            <div>
                {!fetching ? <div>
                        <div className={"flex flex-wrap h-full transition-all"}>
                            <div className={"lg:w-3/5 px-1 h-full"}>
                                <div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                                        <div className={"text-blue-900 w-full px-2 mb-3 font-bold text-lg"}>Kết nối với học
                                            sinh
                                        </div>
                                        <div className={" w-full mb-3"}>
                                            <SelectInput
                                                options={init.studentInit || []}
                                                name={"student"} label={"Học sinh được thụ hưởng sử dụng"}
                                                placeholder={"Chọn"}
                                                data={data}
                                                setData={setData}
                                                nullable={true}
                                                required={true}
                                            />
                                        </div>

                                    </div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1 mt-2"}>
                                        <div className={"text-blue-900 w-full px-2 mb-3 font-bold text-lg"}>Thông tin thẻ
                                            học
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"classroom_type"}
                                                label={"Ghi chú về LOẠI LỚP mà thẻ học này được sử dụng, theo đơn giá."}
                                                data={data}
                                                setData={setData}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"commitment"} label={"Ghi chú về cam kết đầu ra nếu có."}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"card_pdf"} label={"Link drive PDF đơn đăng ký"}
                                                data={data}
                                                setData={setData}
                                                image={"https://play-lh.googleusercontent.com/t-juVwXA8lDAk8uQ2L6d6K83jpgQoqmK1icB_l9yvhIAQ2QT_1XbRwg5IpY08906qEw=w600-h300-pc0xffffff-pd"}
                                            />
                                        </div>
                                    </div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1 mt-2"}>
                                        <div className={"text-blue-900 w-full px-2 mb-3 font-bold text-lg"}>Đăng ký buổi
                                            học
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"reality_lessons"} label={"Số buổi đăng ký gốc"}
                                                data={data}
                                                setData={setData}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"bonus_lessons"} label={"Số buổi được tặng thêm"}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"bonus_note"} label={"Ghi chú lý do tặng"}
                                                data={data}
                                                setData={setData}

                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"total_lessons"} label={"Số buổi thực tế đăng ký"}
                                                data={data}
                                                setData={setData}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"reality_amount"} label={"Học phí gốc"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"promotion_amount"} label={"Ưu đãi (vnđ)"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                            />
                                        </div>
                                        <div className={"md:w-1/6 w-full mb-3"}>
                                            <NumberInput
                                                name={"promotion_percent"}
                                                label={"Ưu đãi (%)"}
                                                data={data}
                                                setData={setData}
                                                suffix={"%"}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"md:w-5/12 w-full mb-3"}>
                                            <NumberInput
                                                name={"total_amount"} label={"Học phí thực tế cần thanh toán"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"md:w-5/12 w-full mb-3"}>
                                            <NumberInput
                                                name={"amount_per_lesson"} label={"Đơn giá mỗi buổi của thẻ học"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"w-full mb-3"}>
                                            <RichTextInput
                                                name={"note"} label={"Ghi chú kế hoạch thanh toán dự kiến"}
                                                data={data}
                                                setData={setData}
                                                height={"200px"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"w-full mb-3 mt-2"}>
                                    <button
                                        onClick={uploadCard}
                                        className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Thêm
                                        mới
                                        thẻ học
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <FetchingAnimation/>}
            </div>
        </PopupLayout>
    )
}
export default QuickCreateCardPopup