import Select from "react-select";
import {useContext, useEffect} from "react";
import SelectInput from "./SelectInput";
import {AppContext} from "../../AppContext";

const StudentAttendanceInput = ({name, data, setData, init, setInit}) => {
    const app = useContext(AppContext)
    useEffect(() => {
        setData({
            ...data, [name]: init.initStudents
        })
    }, [init.initStudents]);
    const updateStatus = ({changeName, id, changeValue}) => {
        const newData = data[name].map((item, key) => {
            if (key === id) {
                return {...item, [changeName]: changeValue}
            }
            return item
        })
        setData({...data, [name]: newData})
    }
    return (
        <div className={"basis-full text-center rounded my-5"}>
            {init.initStudents !== undefined && data[name] !== undefined ?
                <div className="relative shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase hohotext-gray-400">
                        <tr className={"text-[0.6rem]"}>
                            <th scope="col" className="border px-6 py-3 bg-gray-50 hohobg-gray-800">Mã học sinh</th>
                            <th scope="col" className="border px-6 py-3 bg-gray-50 hohobg-gray-800">Tên học sinh</th>
                            <th scope="col" className="border px-6 py-3">Tên tiếng Anh</th>
                            <th scope="col" className="border px-6 py-3 bg-gray-50 hohobg-gray-800">Mã thẻ học</th>
                            <th scope="col" className="border px-6 py-3">Số buổi được dùng</th>
                            <th scope="col" className="border px-6 py-3 bg-gray-50 hohobg-gray-800">Số buổi còn lại
                            </th>
                            <th scope="col" className="border px-6 py-3">Điểm danh</th>
                            <th scope="col" className="border px-6 py-3 bg-gray-50 hohobg-gray-800">Tính buổi học</th>
                            <th scope="col" className="border px-6 py-3 ">Nhận xét của giáo viên</th>
                            <th scope="col" className="border px-6 py-3 ">Nhận xét của trợ giảng</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data[name].map((initStudent, key) =>
                            <tr key={key} className="border-b border-gray-200 hohoborder-gray-700">
                                <td scope="row"
                                    className="border p-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50  hohobg-gray-800">{initStudent.student_uuid}</td>
                                <td scope="row" className="border p-2">{initStudent.student_name}</td>
                                <td scope="row" className="border p-2">{initStudent.student_english_name}</td>
                                <td scope="row"
                                    className="border p-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50  hohobg-gray-800">{initStudent.card_uuid}</td>
                                <td scope="row" className="border p-2">{initStudent.available_lesson}</td>
                                <td scope="row"
                                    className="border p-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50  hohobg-gray-800">{initStudent.available_lesson}</td>
                                <td scope="row" className={"border p-2 min-w-[15rem]"}>
                                    <Select
                                        value={initStudent.attendance_status || ''}
                                        options={init.initStatus || []}
                                        onChange={r => {
                                            updateStatus({
                                                changeName: "attendance_status",
                                                id: key,
                                                changeValue: r
                                            })
                                        }}
                                    />
                                </td>
                                <td scope="row" className={"border p-2 min-w-[15rem]"}>
                                    <Select
                                        value={initStudent.calculate_status || ''}
                                        options={init.initCalculateLesson || []}
                                        onChange={r => {
                                            updateStatus({
                                                changeName: "calculate_status",
                                                id: key,
                                                changeValue: r
                                            })
                                        }}
                                    />
                                </td>
                                <td scope="row p-2" className={"border p-2"}>
                                    <textarea
                                        onChange={r => {
                                            updateStatus({
                                                changeName: "teacher_comment",
                                                id: initStudent.student_id,
                                                changeValue: r.target.value
                                            })
                                        }}
                                        placeholder={"Viết nhận xét"}
                                        className={"focus:ring-0 text-sm w-full border-none rounded-md"}></textarea>
                                </td>
                                <td scope="row p-2" className={"border p-2"}>
                                    <textarea
                                        onChange={r => {
                                            updateStatus({
                                                changeName: "supporter_comment",
                                                id: initStudent.student_id,
                                                changeValue: r.target.value
                                            })
                                        }}
                                        placeholder={"Viết nhật xét"}
                                        className={"focus:ring-0 text-sm w-full border-none rounded-md"}></textarea>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                : null}
            {data.classroom.value !== undefined ?
                <div onClick={() => {
                    const width = 600;
                    const height = 400;
                    const left = (window.innerWidth - width) / 2;
                    const top = (window.innerHeight - height) / 2;

                    if (data.classroom.value !== undefined) {
                        const popup = window.open('/add_card_to_classroom?classroom_id=' + data.classroom.value, 'popup', `width=${width},height=${height},left=${left},top=${top}`);
                    }

                    window.addEventListener('message', (event) => {
                        if (event.origin === window.location.origin) {
                            app.studyLogService.getStudyLogInitData({
                                init: init,
                                setInit: setInit,
                                postData: {
                                    classroom_id: data.classroom.value
                                }
                            }).then()
                        }
                        console.log(event)
                    });
                }} className={"bg-green-800 max-w-[20rem] p-2 text-white my-3 rounded"}>Thêm học sinh
                </div> : null
            }
        </div>
    )
}
export default StudentAttendanceInput