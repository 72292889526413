const TextInput = ({label, name, data, setData, placeholder,suffix, image, required,disable}) => {
    return (
        <div className={"px-2"}>
            <label htmlFor={name}
                   className="flex mb-2 text-sm font-medium text-gray-900 ">

                {label ?? name ?? ""} {image?<img className={'w-[2.5rem]'} src={image} alt={""}></img>:null}
                {required?<span className={"ml-1 font-black text-md text-red-600"}>*</span>:null}
            </label>
            <div className="flex">
                <input type="text" id={name}
                       disabled={disable}
                       value={data[name] || ''}
                       onChange={(r) => {
                           setData({...data, [name]: r.target.value})
                       }}
                       className={"bg-white  border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " + (suffix ? "rounded-l-lg" : "rounded-lg")}
                       placeholder={placeholder} required/>
                {suffix ? <span
                    className="flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md">
                    <div className={"p-1 border "}>{suffix}</div>
                </span> : null}
            </div>
        </div>
    )
}
export default TextInput