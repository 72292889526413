import {createContext, useState} from "react";
import {StudentServices} from "./services/StudentServices";
import {AuthServices} from "./services/AuthServices";
import {ClassroomServices} from "./services/ClassroomServices";
import {CardServices} from "./services/CardServices";
import {StudyLogServices} from "./services/StudyLogServices";
import {CommentServices} from "./services/CommentServices";
import {TransactionService} from "./services/TransactionService";
import {SettingService} from "./services/SettingService";
import {TeacherService} from "./services/TeacherService";
import {SupporterService} from "./services/SupporterService";
import {AccountService} from "./services/AccountService";
import {RuleService} from "./services/RuleService";
import {FeedbackService} from "./services/FeedbackService";
import {toast} from "react-toastify";
import axios from '../src/services/globalApi'
import {appVendor} from "./config";
import {BranchService} from "./services/BranchService";

export const AppContext = createContext({});

export const AppProvider = ({children}) => {
    const getSystemInfo = async () => {
        try {
            return await (await axios.get(appVendor.base_url + "/system", {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
            }))
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }
    const [acl, setAcl] = useState();
    const studentService = new StudentServices(localStorage.getItem("token"))
    const authService = new AuthServices(localStorage.getItem("token"))
    const classroomService = new ClassroomServices(localStorage.getItem("token"))
    const cardService = new CardServices(localStorage.getItem("token"))
    const studyLogService = new StudyLogServices(localStorage.getItem("token"))
    const commentService = new CommentServices(localStorage.getItem("token"))
    const transactionService = new TransactionService(localStorage.getItem("token"))
    const settingService = new SettingService(localStorage.getItem("token"))
    const teacherService = new TeacherService(localStorage.getItem("token"))
    const supporterService = new SupporterService(localStorage.getItem("token"))
    const accountService = new AccountService(localStorage.getItem("token"))
    const ruleService = new RuleService(localStorage.getItem("token"))
    const feedbackService = new FeedbackService(localStorage.getItem("token"))
    const branchService = new BranchService(localStorage.getItem("token"))
    const init = {
        getSystemInfo: getSystemInfo,
        acl: acl,
        setAcl: setAcl,
        studentService: studentService,
        authService: authService,
        classroomService: classroomService,
        cardService: cardService,
        studyLogService: studyLogService,
        commentService: commentService,
        transactionService: transactionService,
        settingService: settingService,
        teacherService: teacherService,
        supporterService: supporterService,
        accountService: accountService,
        ruleService: ruleService,
        feedbackService: feedbackService,
        branchService: branchService
    }
    return (
        <AppContext.Provider value={init}>
            {children}
        </AppContext.Provider>
    )
}