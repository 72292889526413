import {appVendor} from "../config";
import {toast} from "react-toastify";
import axios from './globalApi'


export class FeedbackService {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async initForCreateFeedback({card_id, setInit}) {
        try {
            const initDataResponse = await (await axios.post(this._base_url + "/feedback/init", {
                card_id: card_id
            }, this._header));
            setInit(initDataResponse.data)
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    async createFeedback({data}) {
        try {
            const feedback = await (await axios.post(this._base_url + "/feedback/store", data, this._header));
            toast.success("Thêm thành công")
            window.opener.postMessage(feedback.data, '*');
            window.close()
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }
}