import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../AppContext";
import MainLayout from "../layouts/MainLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import AvatarInput from "../components/inputs/AvatarInput";
import TextInput from "../components/inputs/TextInput";
import SelectInput from "../components/inputs/SelectInput";
import CalendarInput from "../components/inputs/CalendarInput";
import SelectMultiInput from "../components/inputs/SelectMultiInput";
import FetchingAnimation from "../components/FetchingAnimation";
import PopupLayout from "../layouts/PopupLayout";

const QuickCreateClassroomPopup = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const [init, setInit] = useState({})
    const {id} = useParams()
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({
        calendars: [],
        cards: [],
    })
    const uploadClassroom = () => {
        app.classroomService.createNewClassroom({postData: data, setFetching: setFetching}).then((r) => {
            window.opener.postMessage({
                value: r.data?.id,
                label: r.data?.name
            }, '*');
            window.close()
        })
    }
    useEffect(() => {
        app.classroomService.initForClassroom({setInit, setFetching}).then()
    }, [1])

    return (
        <PopupLayout>
            <div>
                {!fetching ? <div>
                        <div className={"flex flex-wrap h-full transition-all"}>
                            <div className={"lg:w-3/4 px-1 h-full"}>
                                <div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                                        <div className={"w-full mb-3"}>
                                            <AvatarInput
                                                name={"thumbnail"} label={"Ảnh đại diện lớp"}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"classroom_name"} label={"Tên lớp học"} placeholder={""}
                                                data={data}
                                                setData={setData}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"lesson_social_group"}
                                                label={"Link nhóm Zalo nội bộ trao đổi nội dung dạy"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"parent_group"} label={"Link nhóm Zalo hội phụ huynh"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"student_group"} label={"Link nhóm Zalo hội học sinh"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>

                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"textbook_name"}
                                                label={"Tên giáo trình sử dụng"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <SelectInput
                                                name={"account_id"} label={"Nhân viên phụ trách"} placeholder={""}
                                                data={data}
                                                setData={setData}
                                                nullable={true}
                                                options={init.accountInit || []}
                                                required={true}
                                            />
                                        </div>


                                        <div className={"w-full mb-3"}>
                                            <CalendarInput
                                                init={init}
                                                name={"calendars"}
                                                label={"Lịch học"}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"w-full mb-3 px-2 mt-5"}>
                            <button
                                onClick={uploadClassroom}
                                className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Thêm lớp
                                học
                            </button>
                        </div>
                    </div> :
                    <FetchingAnimation/>}
            </div>
        </PopupLayout>
    )
}
export default QuickCreateClassroomPopup