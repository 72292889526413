import {useContext, useEffect, useState} from "react";
import FetchingAnimation from "../components/FetchingAnimation";

import SideBarMenu from "./Menu";
import AuthMiddleware from "../screens/Middleware/AuthMiddleware";
import {Link} from "react-router-dom";


const ErrorLayout = ({children}) => {
    const [user, setUser] = useState({})
    return (
        <div className={"flex flex-row w-full"}>
            <div
                className={"min-w-[4.5rem] bg-blue-900 min-h-[calc(100vh)] transition-all duration-100 shadow-lg z-50"}>
                <div className={"flex justify-between flex-col h-full sticky top-0"}>
                    <div>
                        <div className={'text-center text-2xl pt-2 font-bold text-white mb-3'}>
                            <span>BSM</span>
                        </div>
                        <SideBarMenu/>
                    </div>
                    <div className={"text-center text-white text-xs pb-1"}>

                        <div>
                            Chi nhánh
                        </div>
                        <div>
                            B.0001
                        </div>
                        <div className={"mt-5"}>
                            Phiên bản
                        </div>
                        <div>0.0.1</div>
                        <div className={"my-2"}>
                            <Link to={'/setting/'}
                                  className={"my-2 hover:cursor-pointer"}>
                                <i className='bx bxs-cog text-2xl'></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex-1 bg-white w-[calc(100%-4.5rem)]"}>
                <div className={"w-full py-2 flex justify-between px-5 border-b-[1px] border-gray-300"}>
                    <div className={"justify-center flex items-center"}>
                        <span className={"text-blue-900 font-bold"}>Xin chào, {user.name}</span>
                    </div>
                    <div className={"group flex items-center"}>
                        <img className={"w-[2rem] border-[2px] rounded-full border-white"}
                             src={user.avatar || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"}/>
                    </div>
                </div>
                <div className={"p-5"}>
                    {children}
                </div>
            </div>
        </div>
    )
}
export default ErrorLayout