import TextCol from "./table/TextCol";

const CustomFieldsColData = ({custom_fields, data}) => {
    return (
        <>
            {custom_fields.map((field) =>
                <>
                    {data.extra_information[field.key] !== undefined
                        ?
                        <TextCol
                            style={{textAlign: "center"}}
                            value={data.extra_information[field.key] || ''}
                        />
                        :
                        <TextCol
                            style={{textAlign: "center"}}
                            value={""}
                        />
                    }
                </>
            )}
        </>
    )
}
export default CustomFieldsColData;