import MainLayout from "../../layouts/MainLayout";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import FetchingAnimation from "../../components/FetchingAnimation";
import TextInput from "../../components/inputs/TextInput";
import Select from "react-select";
import SelectInput from "../../components/inputs/SelectInput";

const PermissionGroupList = () => {
    const [data, setData] = useState({})
    const [listGroups, setListGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState({})
    const [fetching, setFetching] = useState(false)
    const [initPermissions, setInitPermissions] = useState([])
    const app = useContext(AppContext)
    useEffect(() => {
        app.ruleService.getAllPermission({
            setPermissions: setInitPermissions
        }).then()

        app.ruleService.getAllGroup({
            setFetching: setFetching,
            setGroups: setListGroups
        }).then()
    }, [])
    const updatePermissionGroup = () => {
        app.ruleService.updatePermissionGroup({
            data: {
                name: selectedGroup.name,
                permissions: selectedGroup.permissions,
                effect_method: selectedGroup.effect_method
            },
            id: selectedGroup.id,
            setFetching: setFetching
        }).then((r) => {
            app.ruleService.getAllGroup({
                setFetching: setFetching,
                setGroups: setListGroups
            }).then()
        })
    }
    const addPermissionGroup = () => {
        app.ruleService.createPermissionGroup({data: data, setFetching: setFetching}).then((r) => {
            setListGroups([...listGroups, r.data])
            setSelectedGroup(r.data)
            setData({})
        })
    }
    return (
        <MainLayout>
            <div>
                {!fetching ?
                    <div className={"flex flex-wrap"}>
                        <div className={"basis-1/2 p-5"}>
                            <div>
                                {listGroups.map((group, key) =>
                                    <div className={"cursor-pointer"} onClick={() => {
                                        setSelectedGroup(group)
                                    }} key={key}>
                                        <div
                                            className={(selectedGroup.id === group.id ? "bg-blue-900 text-white" : "bg-blue-50") + " cursor-pointer my-2  p-2 flex justify-between"}>
                                            <span>
                                                {group.name}
                                            </span>
                                            <i className={"bx bxs-package"}>

                                            </i>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={"mt-5"}>
                                <div className={"flex flex-wrap"}>
                                    <div className={"basis-1/2"}>
                                        <TextInput
                                            data={data}
                                            setData={setData}
                                            name={'name'}
                                            required={true}
                                            label={'Thêm nhóm mới'}
                                        />
                                    </div>
                                    <div className={""}>
                                        <div className={"mb-7"}></div>
                                        <button onClick={() => {
                                            addPermissionGroup()
                                        }} className={"p-2 bg-blue-900 text-white rounded"}>Thêm mới nhóm quyền
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedGroup.permissions ?
                            <div className={"basis-1/2 p-5 "}>
                                <div
                                    className={"self-end bg-blue-900 text-white sticky top-2 z-50 mt-2 p-3 flex justify-between items-center"}>
                                    <span>Phân quyền : <span className={"font-bold shadow"}>{selectedGroup.name}</span></span>
                                    <SelectInput
                                        label={false}
                                        placeholder={"Áp dụng sau khi cập nhật"}
                                        data={selectedGroup}
                                        setData={setSelectedGroup}
                                        name={'effect_method'}
                                        options={[
                                            {value: 1, label: "Cho tất cả nhân viên"},
                                            {value: 2, label: "Cho tất cả giáo viên"},
                                            {value: 3, label: "Cho tất cả trợ giảng"},
                                            {value: 4, label: "Cho tất cả học sinh"},
                                        ]}
                                    />
                                    <span
                                        onClick={updatePermissionGroup}
                                        className={"cursor-pointer bg-white p-2 text-blue-900 mx-4 shadow rounded font-semibold"}>Lưu lại</span>
                                </div>
                                <div className={"overflow-y-scroll max-h-[80vh] border-b border-l"}>
                                    {initPermissions?.map((initPermission, key) =>
                                        <div className={"my-2 p-2"}>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" className="sr-only peer"
                                                       onChange={(r) => {
                                                           if (selectedGroup?.permissions?.includes(initPermission.id)) {
                                                               setSelectedGroup({
                                                                   ...selectedGroup,
                                                                   permissions: selectedGroup.permissions.filter((p) => p !== initPermission.id)
                                                               })
                                                           } else {
                                                               setSelectedGroup({
                                                                   ...selectedGroup,
                                                                   permissions: [...selectedGroup.permissions, initPermission.id]
                                                               })
                                                           }
                                                       }}
                                                       checked={selectedGroup?.permissions?.includes(initPermission.id)}
                                                />
                                                <div
                                                    className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                                                <span
                                                    className="ms-3 text-sm font-medium text-gray-900">{initPermission.label}</span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                            : null}
                    </div>
                    : <FetchingAnimation/>}
            </div>
        </MainLayout>
    );
}
export default PermissionGroupList