import {useContext, useEffect, useState} from "react";
import {AppContext} from "../AppContext";
import {confirmAlert} from "react-confirm-alert";

const TransactionComment = ({comment}) => {
    const app = useContext(AppContext)
    const [transaction, setTransaction] = useState({});
    useEffect(() => {
        fetchNewTransaction()
    }, []);

    const fetchNewTransaction = () => {
        if (comment.action_information?.id !== undefined) {
            app.transactionService.getOneTransaction({setTransaction, id: comment.action_information?.id}).then()
        }
    }
    const confirmAccept = () => {
        confirmAlert({
            title: 'Xác nhận',
            message: 'Khi xác nhận sẽ không thể hủy, bạn là người chịu trách nhiệm cho hành động này !',
            buttons: [
                {
                    label: 'Xác nhận',
                    onClick: () => {
                        app.transactionService.acceptTransaction({id: comment.action_information?.id}).then((r) => {
                            fetchNewTransaction()
                        })
                    }
                },
                {
                    label: 'Hủy',
                    onClick: () => {
                        // Xử lý hành động khi hủy bỏ
                    }
                }
            ]
        });
    }

    return (
        <div
            className={"hover:bg-gray-100 cursor-pointer transition-all log bg-white border-b  p-1 py-2 rounded"}>
            <div className={"interactive_user"}>
                <div className={"flex items-center"}>
                    <img
                        className={"w-[2.5rem] h-[2.5rem] rounded-full border mr-2"}
                        src={comment.user_avatar || ''}/>
                    <div className={"text-sm"}>
                        <div className={'font-bold'}>{comment.user_name}</div>
                        <div className={"text-sm text-gray-400"}> {comment.time}</div>
                    </div>
                </div>
            </div>
            <div className={"pl-[3rem] mt-2 text-sm"}>

                <div className={"bg-blue-50 border p-1 rounded"}>
                    <div className={"text-sm font-bold"}>Giao dịch mới</div>
                    <div>Mã giao dịch: {transaction.uuid}</div>
                    <div className={"text-sm"}>
                        <span>Số tiền : </span>
                        <span
                            className={"text-green-800 font-bold"}>{parseInt(transaction.value).toLocaleString()} đ</span>
                    </div>
                    <div>
                        <span>Thời gian giao dịch: {transaction.create_time}</span>
                    </div>
                </div>
                <div className={"italic my-2"}>
                    <span className={""}>"{transaction?.transaction_message}"</span>
                </div>
                <div className={"my-2"}>
                    <a href={transaction.transaction_bill} target={"_blank"}>
                        <img src={transaction.transaction_bill} className={"max-w-[5rem]"} alt={""}/>
                    </a>
                </div>

                <div className={"text-sm bg-blue-50 border p-1 rounded"}>
                    <div className={"font-bold mb-2"}>Xác nhận giao dịch :</div>
                    <div className={"text-green-800"}>
                        <i className='bx bxs-badge-check mr-2'></i>
                        <span>S.0001 đã xác nhận đúng thông tin giao dịch</span>
                    </div>
                    {transaction.accepted_uuid === null
                        ?
                        <button onClick={confirmAccept} className={"my-2 text-white bg-green-800 p-1 rounded"}>Duyệt giao
                            dịch</button>
                        :
                        <div className={"text-green-800 mt-1 font-bold"}>
                            <i className='bx bxs-badge-check mr-2'></i>
                            <span>Giao dịch đã được duyệt với {transaction.accepted_uuid} vào lúc {transaction.accepted_time} </span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default TransactionComment