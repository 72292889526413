import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class TeacherService {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async listTeachers({setTeachers, setFetching, postData, config, setConfig}) {
        setFetching(true)
        try {
            const teachers = await (await axios.post(this._base_url + "/teacher/list", postData, this._header))
            setTeachers(teachers.data)

        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async initForTeacher({setInit, setFetching}) {
        setFetching(true)
        try {
            const init = await (await axios.get(this._base_url + "/teacher/init", this._header))
            setInit(init.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async getTeacherById({id, setData, setFetching}) {
        setFetching(true)
        try {
            const teacher = await (await axios.get(this._base_url + "/teacher/detail/" + id, this._header))
            setData(teacher.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async createTeacher({postData, setFetching}) {
        setFetching(true)
        try {
            const teachers = await (await axios.post(this._base_url + "/teacher/store", postData, this._header))
            toast.success("Thêm mới thành công")
            return teachers
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async updateTeacher({id, postData, setFetching}) {
        setFetching(true)
        try {
            const teachers = await (await axios.put(this._base_url + "/teacher/update/" + id, postData, this._header))
            toast.success("Cập nhât thành công")
            return true
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async getTeacherCustomFields({setCustomFields}) {
        try {
            const customFields = await (await axios.get(this._base_url + "/custom_fields/list/teacher", this._header))
            setCustomFields(customFields.data)
        } catch (e) {
            toast(e.response.data.message)
        }
    }
}