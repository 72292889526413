import {Editor} from "@tinymce/tinymce-react";
import {useRef} from "react";

const TextInput = ({label, name, data, setData, placeholder, suffix, height,required}) => {
    return (
        <div className={""}>
            {label !== undefined ? <label htmlFor={name}
                                          className="block mb-2 text-sm font-medium text-gray-900 ">{label ?? name ?? ""}
                {required?<span className={"text-red-800 ml-1"}>*</span>:null}
            </label> : null}
            <Editor
                value={data[name]}
                apiKey='v9a3hkc5xsw542n1csexyl6eaveglb8el5zminkjlklbn3v4'
                // onInit={() => setData({...data, [name]: editor.getContent()})}
                onChange={
                    r => setData({...data, [name]: r.target.getContent()})
                }
                init={{
                    height: height || "100px",
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </div>
    )
}
export default TextInput