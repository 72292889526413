import {useContext, useState} from "react";
import AvatarInput from "../../components/inputs/AvatarInput";
import NumberInput from "../../components/inputs/NumberInput";
import ImageInput from "../../components/inputs/ImageInput";
import app from "../../App";
import {AppContext} from "../../AppContext";
import DateTimeInput from "../../components/inputs/DateTimeInput";
import SelectInput from "../../components/inputs/SelectInput";
import Select from "react-select";
import CreateSelectInput from "../../components/inputs/CreateSelectInput";

const TransactionAddScreen = ({relation_uuid, type}) => {
    const app = useContext(AppContext)
    const [data, setData] = useState({
        relation_uuid: relation_uuid,
        transaction_type: type,
    })
    const addTransaction = () => {
        // console.log(data)
        app.transactionService.storeTransaction({transaction: data, setTransaction: setData}).then()
    }

    return (
        <div className={"p-1"}>
            <ImageInput
                name={'transaction_bill'}
                label={'Hóa đơn giao dịch'}
                data={data}
                setData={setData}
            />
            <div className={"px-2 mt-5"}>
                <NumberInput
                    name={'value'}
                    label={'Số tiền'}
                    data={data}
                    setData={setData}
                />
            </div>
            <div className={"px-2 mt-5"}>
                <SelectInput
                    name={'transaction_method'}
                    label={'Hình thức'}
                    options={[
                        {
                            label: "New",
                            value: 0,
                        },
                        {
                            label: "Renew",
                            value: 1,
                        },
                    ]}
                    data={data}
                    setData={setData}
                />
            </div>

            <div className={"px-2 mt-5"}>
              <CreateSelectInput
                name={"affiliate_user"}
                label={"Nhân sự được hưởng doanh số"}
                data={data}
                setData={setData}
              />
            </div>
            <div className={"px-2 mt-5"}>
                <DateTimeInput
                    name={'create_time'}
                    label={'Ngày tháng năm giao dịch'}
                    data={data}
                    setData={setData}
                />
            </div>
            <div className={"px-4 mt-5"}>
                <label htmlFor={"message"}
                       className="block mb-2 text-sm font-medium text-gray-900 ">Nội dung giao dịch
                </label>

                <div className={"flex"}>
                    <textarea
                        value={data.message || ''}
                        onChange={(r) => {
                            setData({...data, message: r.target.value})
                        }}
                        className={"opacity-100  bg-white border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded-lg"}
                        required></textarea>
                </div>
                <button onClick={addTransaction} className={"mt-2 bg-blue-900 text-white p-2 rounded"}>Thêm giao dịch
                    mới
                </button>
            </div>
        </div>
    )
}
export default TransactionAddScreen