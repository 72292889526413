import TextInput from "./TextInput";
import {useEffect, useState} from "react";
import NumberInput from "./NumberInput";
import DateInput from "./DateInput";

const CustomFields = ({data, setData, customDataKey, fieldKey, label, type,required}) => {
    const [customData, setCustomData] = useState(data[customDataKey]||{});
    useEffect(() => {
        setData({...data, [customDataKey]: customData})
    }, [customData]);
    return (
        <div className={"mb-3"}>
            {type === 'text' ?
                <TextInput
                    name={fieldKey || ''}
                    label={label || ''}
                    data={customData}
                    setData={setCustomData}
                    required={required}
                />
                : null}
            {type === 'number' ?
                <NumberInput
                    name={fieldKey || ''}
                    label={label || ''}
                    data={customData}
                    setData={setCustomData}
                    required={required}
                />
                : null}
            {type === 'date' ?
                <DateInput
                    name={fieldKey || ''}
                    label={label || ''}
                    data={customData}
                    setData={setCustomData}
                    required={required}
                />
                : null}
        </div>
    );
}
export default CustomFields