import MainLayout from "../../layouts/MainLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useContext, useEffect, useState} from "react";
import TextInput from "../../components/inputs/TextInput";
import DateInput from "../../components/inputs/DateInput";
import SelectInput from "../../components/inputs/SelectInput";
import PhoneNumberInput from "../../components/inputs/PhoneInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import AvatarInput from "../../components/inputs/AvatarInput";
import FetchingAnimation from "../../components/FetchingAnimation";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Select from "react-select";
import {AppContext} from "../../AppContext";
import CustomFields from "../../components/inputs/CustomFields";

const StudentCreate = () => {
    const [customFields, setCustomFields] = useState([])
    const app = useContext(AppContext)
    const [currentExtraInformation, setCurrentExtraInformation] = useState(null)
    const [label, setLabel] = useState("")
    const {id} = useParams()
    useEffect(() => {
        if (id) {
            setLabel("Chỉnh sửa thông tin học sinh")

        } else {
            setLabel("Thêm học sinh mới")
        }
    }, [])
    const nav = useNavigate()
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({})
    const addExtraInformation = ({label}) => {
        const newExtraInformation = {
            label: label,
            value: ""
        }
        if (data.extra_information === undefined) {
            setData({...data, extra_information: [newExtraInformation]})
        } else if (!data.extra_information.some(item => item.label === label)) {
            setData({...data, extra_information: [...data.extra_information, newExtraInformation]})
        } else {
            toast.warn("Thông tin đã có sẵn", {
                autoClose: 200
            })
        }
    }
    const removeExtraInformation = ({label}) => {
        console.log(label);
        const newData = data.extra_information.filter(item => item.label !== label)
        setData({...data, extra_information: newData})
    }
    const setValueForExtraInformation = ({value, label}) => {
        const newData = data.extra_information.map((item) => {
            if (item.label === label) {
                return {label: label, value: value}
            }
            return item
        })
        setData({...data, extra_information: newData})
    }
    const uploadStudent = async () => {
        if (id) {
            await app.studentService.updateStudent({id: id, postData: {...data}, setFetching: setFetching})
        } else {
            await app.studentService.createStudent({postData: {...data}, setFetching: setFetching})
        }

    }
    useEffect(() => {
        document.title = label
    })
    useEffect(() => {
        if (id) {
            setFetching(true)
            app.studentService.getStudentById({
                id: id,
                setData: setData,
                setFetching: setFetching
            }).then()
        }
        app.studentService.getStudentCustomFields({setCustomFields: setCustomFields}).then()

    }, [])
    const [init, setInit] = useState({})
    return (
        <MainLayout>
            <div>
                <Breadcrumbs
                    parent={
                        {
                            label: "Học sinh",
                            link: "/student/list"
                        }
                    }
                    label={label}
                />
            </div>
            {!fetching ? <div>
                    <div className={"flex flex-wrap h-full transition-all"}>
                        <div className={"lg:w-3/4 px-1 h-full"}>
                            <div>
                                <div className={"font-semibold text-xl mb-1 text-blue-900"}>Thông tin cơ bản *</div>
                                <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                                    <div className={"w-full"}>
                                        <AvatarInput data={data} setData={setData} label={"Ảnh học sinh"}
                                                     placeholder={"Chọn ảnh"}
                                                     name={"avatar"}/>
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <TextInput
                                            name={"name"} label={"Tên học sinh"} placeholder={"Nguyễn Văn A"} data={data}
                                            setData={setData}
                                            required={true}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <TextInput
                                            name={"english_name"} label={"Tên tiếng Anh"} placeholder={"Jonny"} data={data}
                                            setData={setData}
                                        />
                                    </div>

                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <SelectInput
                                            options={[
                                                {value: 0, label: "Đang học"},
                                                {value: 1, label: "Đã nghỉ"},
                                                {value: 2, label: "Bảo lưu"},
                                            ]}
                                            name={"status"} label={"Trạng thái"} placeholder={""} data={data}
                                            setData={setData}
                                            required={true}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <SelectInput
                                            options={[
                                                {value: 0, label: "Nam"},
                                                {value: 1, label: "Nữ"},
                                            ]}
                                            name={"gender"} label={"Giới tính"} placeholder={"Giới tính"} data={data}
                                            setData={setData}
                                            required={true}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <DateInput
                                            name={"birthday"} label={"Ngày sinh"} placeholder={"07/01/2002"} data={data}
                                            setData={setData}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <PhoneNumberInput
                                            name={"phone"} label={"Số điện thoại học sinh"} placeholder={"+84 012 345 678"}
                                            data={data}
                                            setData={setData}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <TextInput
                                            name={"facebook"} label={"Facebook học sinh"} placeholder={"https://fb.me/abc"}
                                            data={data}
                                            setData={setData}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <TextInput
                                            name={"email"} label={"Email học sinh"} placeholder={"nguyenvana@gmail.com"}
                                            data={data}
                                            setData={setData}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <TextInput
                                            name={"address"} label={"Địa chỉ sinh sống"} placeholder={""}
                                            data={data}
                                            setData={setData}
                                        />
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <TextInput
                                            name={"unit"} label={"Trường đang theo học hoặc Đơn vị đang theo làm"} placeholder={""}
                                            data={data}
                                            setData={setData}
                                        />
                                    </div>
                                    <div className={"my-5 px-2 text-sm italic"}>Mã học sinh ( tên tài khoản sẽ tự động tạo khi thêm mới học sinh thành công )</div>
                                </div>
                            </div>
                        </div>
                        <div className={"lg:w-1/4 px-1 "}>
                            {customFields !== [] ?
                                <div>
                                    <div className={"font-semibold text-xl mb-1"}>Thông tin thêm</div>
                                    <div>
                                        {customFields.map((customField) =>
                                            <CustomFields
                                                key={customField.key}
                                                data={data}
                                                setData={setData}
                                                customDataKey={'extra_information'}
                                                fieldKey={customField.key}
                                                label={customField.label}
                                                type={customField.type}
                                                required={customField.required !== 0}
                                            />
                                        )}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className={"w-full mb-3 px-2 mt-5"}>
                        {!id ? <button
                                onClick={uploadStudent}
                                className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Thêm mới học
                                sinh
                            </button> :
                            <button
                                onClick={uploadStudent}
                                className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Chỉnh sửa
                                học sinh
                            </button>
                        }
                    </div>
                </div> :
                <FetchingAnimation/>}

        </MainLayout>
    )
}
export default StudentCreate