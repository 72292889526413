import {Link} from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import ErrorLayout from "../../layouts/ErrorLayout";
const Error404Screen = () => {
    return (
        <ErrorLayout>
            <div className={"flex-1 flex bg-white flex-col items-center justify-center"}>
                <img src={"https://i.pinimg.com/originals/48/8c/7f/488c7f0fb8f6b046889775edaa5a6fa1.png"}
                     className={"max-w-[60rem]"} alt={""}/>
                <div className={"text-3xl uppercase"}>Bạn không có quyền truy cập , trở về <Link className={"text-blue-900 font-bold"} to={"/"}>Trang chủ</Link>
                </div>
            </div>
        </ErrorLayout>
    )
}
export default Error404Screen