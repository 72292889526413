import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../AppContext";
import {useNavigate} from "react-router-dom";
import Check from "../../../components/chip/Check";

const CustomFieldTab = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const [customField, setCustomFields] = useState([])
    useEffect(() => {
        app.settingService.getAllCustomFields({setCustomFields: setCustomFields}).then()
    }, []);
    return (
        <div className={"flex flex-wrap"}>
            <div>
                <button
                    onClick={() => {
                        nav("/setting/custom-field/create")
                    }}
                    className={"bg-blue-900 p-2 text-white rounded mb-3"}>
                    Thêm mới
                </button>
            </div>
            <table className={"border min-w-[calc(100vw-10rem)]"}>
                <thead>
                <tr className={"bg-blue-100 font-semibold"}>
                    <td className={"p-2 border"}>Tên thuộc tính</td>
                    <td className={"p-2 border"}>Mã thuộc tính</td>
                    <td className={"p-2 border text-center"}>Kiểu dữ liệu</td>
                    <td className={"p-2 border text-center"}>Module</td>
                    <td className={"p-2 border text-center"}>Bắt buộc</td>
                    <td className={"p-2 border text-center"}>Hiển thị trên danh sách</td>
                    <td className={"p-2 border"}>Hành động</td>
                </tr>
                </thead>
                <tbody>
                {customField.map((field) =>
                    <tr key={field.id}>
                        <td className={"p-2 border"}>{field.label}</td>
                        <td className={"p-2 border"}>{field.key}</td>
                        <td className={"p-2 border text-center"}>{field.type}</td>
                        <td className={"p-2 border text-center"}>{field.entity}</td>
                        <td className={"p-2 border"}><Check value={field.required}/></td>
                        <td className={"p-2 border"}><Check value={field.is_show_col}/></td>
                        <td className={"p-2 border"}>
                            <i className='bx bxs-pencil'></i>
                        </td>
                    </tr>
                )}
                </tbody>

            </table>
        </div>
    )
}
export default CustomFieldTab