import PopupLayout from "../layouts/PopupLayout";
import TextInput from "../components/inputs/TextInput";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../AppContext";
import StarInput from "../components/inputs/StarInput";
import {useLocation} from "react-router-dom";


const CardFeedbackPopup = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const card_id = searchParams.get("card_id");
    const app = useContext(AppContext)
    const [data, setData] = useState({
        card_id: card_id
    })
    const [init, setInit] = useState({});
    useEffect(() => {

        app.feedbackService.initForCreateFeedback({card_id: card_id, setInit}).then()

    }, []);

    useEffect(() => {
        setData({...data, questions: init.questions})
    }, [init.questions]);

    function sendFeedback() {
        app.feedbackService.createFeedback({data: data}).then()
    }

    return (
        <PopupLayout>
            <div className={"bg-blue-50 p-2"}>
                <div className={"text-center font-bold text-3xl bg-white p-2 rounded"}>
                    <div className={"text-red-600"}>[Form nội bộ, ko gửi PHHS]</div>
                    Phản hồi của PHHS hàng tháng
                </div>
                <div className={"p-2 rounded bg-white my-3"}>
                    <div className={"italic mb-3"}>
                        Qua phản hồi của 1 số PHỤ HUYNH + HỌC SINH thì bên mình được đánh giá là có nhiệt
                        tình, chu đáo, hay hỏi han, nhưng chủ yếu chỉ hỏi han qua TIN NHẮN, do đó chưa được
                        thân
                        thiện và tạo mối quan hệ thực sự với PHHS.
                    </div>
                    <div className={"italic mb-3"}>
                        Giai đoạn 2021, mình đã từng làm được việc là định kỳ hàng tháng gọi lấy feedback
                        PHHS,
                        rồi ghi âm và gửi ghi âm lên hệ thống sao lưu.
                        Nhưng khi đó chưa số hóa được như bây giờ nên việc quản lý các file ghi âm ko khả thi,
                        vì
                        vậy đã bỏ.
                    </div>
                    <div className={"italic mb-3"}>
                        Tuy nhiên, hiện tại bên mình đã số hóa và quy trình hóa được toàn diện, nên việc
                        trên
                        là
                        hoàn toàn khả thi.
                    </div>
                    <div className={"mb-3 font-bold"}>Vì vậy mọi người lưu ý khi lấy feedback:</div>

                    <ol className="max-w-full space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                        <li>
                            Gọi điện hỏi han PH bằng 1 thiết bị (ví dụ như laptop, gọi qua Zalo), rồi dùng 1
                            thiết bị khác ghi âm lại (ví dụ như điện thoại).
                        </li>
                        <li>Vừa gọi vừa ghi chú ra giấy nháp, hoặc điền vào form luôn.</li>
                        <li>Sau đó upload form, có kèm bản ghi âm.</li>
                    </ol>
                    <div className={"mb-3 font-bold"}>Câu hỏi quan trọng nhất cần được hỏi thường xuyên là:
                    </div>
                    <ol className="max-w-full space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                        <li>
                            Tốc độ giảng dạy hiện tại có đang bị QUÁ NHANH / QUÁ CHẬM?
                        </li>
                        <li>Nội dung giảng dạy hiện tại có đang bị QUÁ KHÓ / QUÁ DỄ?</li>
                        <li>Sau đó upload form, có kèm bản ghi âm.</li>
                    </ol>
                    <div className={"font-semibold my-3 "}>
                        => Nhằm giúp giáo viên điều chỉnh tốc độ, hoặc thêm/bớt nội dung giảng dạy cho sát
                        nhu
                        cầu
                        học tập của học sinh.
                    </div>

                    <div>
                        GỌI ĐIỆN nhiều hơn:
                    </div>
                    <div>= TẠO MỐI QUAN HỆ THỰC SỰ</div>
                    <div>= NHẬN LỜI GIỚI THIỆU và DATA NÓNG.</div>
                    <div>= PHÁT TRIỂN.</div>
                    <div className={"italic text-blue-800"}>
                        Bạn nên sử dụng ngôn ngữ và giọng điệu đúng vai trò là 1 thầy cô giáo, không nên sử
                        dụng
                        giọng của chăm sóc khách hàng.
                    </div>
                </div>
                <div className={"p-2 py-5 bg-white"}>
                    <div className={"italic text-sm text-red-600"}>* Đang feedback với tư
                        cách: <b>{init.interactive_user_name} ({init.interactive_user_uuid})</b></div>
                    <div className={"italic text-sm text-green-600"}>* Đang lấy feedback của thẻ
                        học: <b>{init.card_uuid}</b>
                    </div>
                    <div className={"italic text-sm text-green-600"}>* Học sinh thụ hưởng: <b>{init.student_name} -
                        {init.student_uuid}</b></div>
                    <div className={"italic text-sm text-green-600"}>* Lớp học sử dụng
                        thẻ: <b>{init.classroom_name || ''} - {init.classroom_uuid || ''}</b></div>
                </div>
                <div className={"mt-3 mb-3 bg-white p-2 rounded pb-5"}>
                    <TextInput
                        name={"record"}
                        data={data}
                        setData={setData}
                        label={"Link Google Drive Ghi âm cuộc gọi lấy feedback"}
                        required={true}
                    />
                </div>
                {init.questions?.map((question, key) =>
                    <div key={key} className={"mb-3 bg-white py-2 rounded"}>
                        {question.type === 'text' ?
                            <div className={"p-2"}>
                                <label className={"font-bold text-sm"}>{question.title}
                                    {question.required === 1 ? <span className={"text-red-600 ml-1"}>*</span> : null}
                                </label>
                                <div className={"text-sm text-gray-600"}>{question.description}</div>
                                <input
                                    value={data[`answers[${question.id}]`] || ''}
                                    name={`answers[${question.id}]`}
                                    placeholder={"Câu trả lời của bạn"}
                                    onChange={(r) => {
                                        setData({...data, [`answers[${question.id}]`]: r.target.value})
                                    }}
                                    className={"focus:ring-0 border-b-2 border-blue-200 focus:outline-none w-full p-2 my-2"}
                                />
                            </div>
                            : null}
                        {
                            question.type === 'star' ?
                                <StarInput
                                    description={question.description}
                                    data={data}
                                    setData={setData}
                                    label={question.title}
                                    stars={10}
                                    name={`answers[${question.id}]`}
                                    required={question.required === 1}
                                /> : null
                        }
                    </div>
                )}

                <button onClick={sendFeedback} className={"bg-blue-900 text-white p-2 rounded"}>Gửi Feedback</button>

            </div>
        </PopupLayout>
    );
}
export default CardFeedbackPopup