import {Link} from "react-router-dom";

const IconLinkCol = ({value, style, img}) => {
    return (
        <td className={"bg-white shadow-lg p-2 px-3 border-[#dee2e6] border-[0.2px] min-w-[5rem]"} style={style}>
            <a href={value} target={"_blank"}>
                <img alt={value} className={"w-[2rem] h-[2rem] m-auto rounded"} src={img}/>
            </a>
        </td>
    )
}
export default IconLinkCol