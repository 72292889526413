import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class AccountService {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async listAccounts({setAccounts, setFetching, postData, config, setConfig}) {
        setFetching(true)
        try {
            const accounts = await (await axios.post(this._base_url + "/account/list", postData, this._header))
            setAccounts(accounts.data)

        } catch (e) {
            // toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async initForAccount({setInit, setFetching}) {
        setFetching(true)
        try {
            const init = await (await axios.get(this._base_url + "/account/init", this._header))
            setInit(init.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async getAccountById({id, setData, setFetching}) {
        setFetching(true)
        try {
            const account = await (await axios.get(this._base_url + "/account/detail/" + id, this._header))
            setData(account.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async createAccount({postData, setFetching}) {
        setFetching(true)
        try {
            const accounts = await (await axios.post(this._base_url + "/account/store", postData, this._header))
            toast.success("Thêm mới thành công")
            return true
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async updateAccount({id, postData, setFetching}) {
        setFetching(true)
        try {
            const accounts = await (await axios.put(this._base_url + "/account/update/" + id, postData, this._header))
            toast.success("Cập nhât thành công")
            return true
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async getAccountCustomFields({setCustomFields}) {
        try {
            const customFields = await (await axios.get(this._base_url + "/custom_fields/list/account", this._header))
            setCustomFields(customFields.data)
        } catch (e) {
            toast(e.response.data.message)
        }
    }
}