import {useContext, useState} from "react";
import {AppContext} from "../../AppContext";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import FetchingAnimation from "../../components/FetchingAnimation";

const VerifyScreen = () => {
    const nav = useNavigate()
    const {username} = useParams()
    const [via, setVia] = useState('phone')
    const [successOtp, setSuccessOtp] = useState(false)
    const app = useContext(AppContext)
    const [data, setData] = useState({})
    const [otp, setOtp] = useState('')
    const [fetching, setFetching] = useState(false)
    const sendOTP = () => {
        app.authService.sendOtp({data: {username: username, ...data}, setFetching: setFetching}).then((r) => {
            if (r?.status === 200) {
                const message = "Đã gửi mã xác minh đến " + (via === 'phone' + data.phone ? 'số điện thoại' : 'email' + data.email)
                setSuccessOtp(true)
                toast.success(message)
            }
        })
    }

    const verifyOTP = () => {
        app.authService.verifyOtp({data: {username: username, otp: otp}, setFetching: setFetching}).then((r) => {
            if (r?.status === 200) {
                toast.success("Xác minh thành công, tiến hành đăng nhập")
                nav("/login")
            }
        })
    }

    const verifiedViaOTP = (data) => {
        app.authService.verifiedViaPhone({data:{...data, username: username},setFetching:setFetching}).then((r) => {
            if (r?.status === 200) {
                toast.success("Xác minh thành công, tiến hành đăng nhập")
                nav("/login")
            }
        })
    }

    function openSMSVerifyPopup() {
        const width = 340;
        const height = 420;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        const popup = window.open("https://sms.bsmlight.com", 'popup', `width=${width},height=${height},left=${left},top=${top}`);
        window.addEventListener('message', (event) => {
            if (event.origin === 'https://sms.bsmlight.com') {
                verifiedViaOTP(event.data)
            }
        });
    }

    return (
        <div>
            {!fetching ?
                <div className={"flex flex-wrap justify-center items-center h-[100vh]"}>
                    {!successOtp ?
                        <div className={"border p-3 py-20 rounded flex flex-col"}>
                            {via === 'phone' ?
                                <div className={"flex flex-col p-2 rounded"}>
                                    <label className={"font-semibold text-sm mb-2"}>Xác minh bằng số điện thoại</label>

                                    <button onClick={() => {
                                        openSMSVerifyPopup()
                                    }}
                                            className={"my-2 font-semibold bg-blue-900 text-white p-2 rounded"}>Xác minh
                                        ngay
                                    </button>
                                    <a className={"text-xs text-blue-600 text-center cursor-pointer"}
                                       onClick={() => setVia('email')}>Tôi muốn xác minh bằng email</a>
                                </div>
                                :
                                <div className={"flex flex-col p-2 rounded"}>
                                    <label className={"font-semibold text-sm mb-2"}>Xác minh bằng email</label>
                                    <input className={"p-1.5 border rounded"}
                                           value={data.email}
                                           onChange={(e) => setData({...data, email: e.target.value})}
                                    />
                                    <button onClick={sendOTP}
                                            className={"my-2 font-semibold bg-blue-900 text-white p-2 rounded"}>
                                        Nhận mã xác minh
                                    </button>
                                    <a className={"text-xs text-blue-600 text-center cursor-pointer"}
                                       onClick={() => setVia('phone')}>Tôi muốn xác minh bằng số điện thoại</a>
                                </div>}
                        </div> :
                        <div className={"border p-3 py-20 rounded flex flex-col"}>
                            <div className={"flex flex-col p-2 rounded"}>
                                <label className={"font-semibold text-sm mb-2"}>Nhập mã xác minh</label>
                                <input className={"p-1.5 border rounded"} value={otp}
                                       onChange={(r) => setOtp(r.target.value)}/>
                                <button onClick={verifyOTP}
                                        className={"my-2 font-semibold bg-blue-900 text-white p-2 rounded"}>Xác minh tài
                                    khoản
                                </button>
                                <a className={"text-xs text-blue-600 text-center cursor-pointer"}
                                   onClick={() => sendOTP()}>Gửi
                                    lại mã</a>
                            </div>
                        </div>
                    }
                </div> : <FetchingAnimation/>
            }
        </div>
    );
}
export default VerifyScreen