import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class SupporterService {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async listSupporters({setSupporters, setFetching, postData, config, setConfig}) {
        setFetching(true)
        try {
            const supporters = await (await axios.post(this._base_url + "/supporter/list", postData, this._header))
            setSupporters(supporters.data)

        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async initForSupporter({setInit, setFetching}) {
        setFetching(true)
        try {
            const init = await (await axios.get(this._base_url + "/supporter/init", this._header))
            setInit(init.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async getSupporterById({id, setData, setFetching}) {
        setFetching(true)
        try {
            const supporter = await (await axios.get(this._base_url + "/supporter/detail/" + id, this._header))
            setData(supporter.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async createSupporter({postData, setFetching}) {
        setFetching(true)
        try {
            const supporters = await (await axios.post(this._base_url + "/supporter/store", postData, this._header))
            toast.success("Thêm mới thành công")
            return supporters
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async updateSupporter({id, postData, setFetching}) {
        setFetching(true)
        try {
            const supporters = await (await axios.put(this._base_url + "/supporter/update/" + id, postData, this._header))
            toast.success("Cập nhât thành công")
            return true
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async getSupporterCustomFields({setCustomFields}) {
        try {
            const customFields = await (await axios.get(this._base_url + "/custom_fields/list/supporter", this._header))
            setCustomFields(customFields.data)
        } catch (e) {
            toast(e.response.data.message)
        }
    }
}