import MainLayout from "../../layouts/MainLayout";
import {useState} from "react";
import ProfileTab from "./Tab/ProfileTab";
import CustomFieldTab from "./Tab/CustomFieldTab";
import RuleTab from "./Tab/RuleTab";

const SettingScreen = () => {
    const [tab, setTab] = useState('rule')
    return (
        <MainLayout>
            <div className="border-b border-gray-200 dark:border-gray-700 mb-3">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li className="mr-2">
                        <button
                            onClick={() => {
                                setTab('profile')
                            }}
                            className={(tab==='profile'?'border-b-2 border-blue-600 text-blue-900':'text-gray-600')+" inline-flex items-center justify-center p-4 text-blue-600  rounded-t-lg dark:text-blue-500 group"}
                            aria-current="page">
                            Người dùng
                        </button>
                    </li>
                    <li className="mr-2">
                        <button
                            onClick={() => {
                                setTab('custom_fields')
                            }}
                            className={(tab==='custom_fields'?'border-b-2 border-blue-600 text-blue-900':'text-gray-600')+" inline-flex items-center justify-center p-4 text-blue-600 rounded-t-lg dark:text-blue-500 group"}
                            aria-current="page">
                            Định nghĩa trường
                        </button>
                    </li>
                    <li className="mr-2">
                        <button
                            onClick={() => {
                                setTab('rule')
                            }}
                            className={(tab==='rule'?'border-b-2 border-blue-600 text-blue-900':'text-gray-600')+" inline-flex items-center justify-center p-4 text-blue-600 rounded-t-lg dark:text-blue-500 group"}
                            aria-current="page">
                           Phân quyền
                        </button>
                    </li>
                </ul>
                {

                }
            </div>
            <div>
                {
                    tab === 'profile' ?
                        <ProfileTab/>
                        : null
                }
                {
                    tab === 'custom_fields' ?
                        <CustomFieldTab/>
                        : null
                }
                {
                    tab === 'rule' ?
                        <RuleTab/>
                        : null
                }

            </div>
        </MainLayout>
    )
}
export default SettingScreen