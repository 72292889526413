import MainLayout from "../../layouts/MainLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import UserInfoCol from "../../components/table/UserInfoCol";
import UserGroup from "../../components/UserGroup";
import HeadTable from "../../components/table/HeadTable";
import TextCol from "../../components/table/TextCol";
import ActionCol from "../../components/table/ActionCol";
import FetchingAnimation from "../../components/FetchingAnimation";
import CheckCol from "../../components/table/CheckCol";
import TextAreaCol from "../../components/table/TextAreaCol";
import {AppContext} from "../../AppContext";

const StudentDetail = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const {id} = useParams()
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({})
    const switchToEdit = () => {
        nav("/student/edit/" + id)
    }
    useEffect(() => {
        app.studentService.getStudentById({
            id: id,
            setData: setData,
            setFetching: setFetching
        }).then()
    }, [])
    return (
        <MainLayout>
            <Breadcrumbs
                label={"Thông tin học sinh"}
                parent={{
                    label: "Danh sách học sinh",
                    link: "/student/list"
                }}
            />
            {!fetching ?
                <div>
                    <div className={"flex flex-wrap p-2 h-full"}>
                        <div className={"md:basis-1/6 basis-full p-2"}>
                            <div className={"w-full relative group "}>
                                <img src={data.avatar || ''} className={"w-full shadow rounded-2xl"}/>
                                <div
                                    onClick={switchToEdit}
                                    className={"rounded transition-all duration-500 absolute bottom-0 cursor-pointer right-0 group-hover:opacity-80 opacity-0 p-5 shadow-lg rounded-br-2xl bg-white"}>
                                    <i className={"bx bx-edit text-2xl"}></i>
                                </div>
                            </div>
                        </div>
                        <div className={"md:basis-1/3 basis-full p-2"}>
                            <div className={"border p-5 bg-gray-50 rounded-2xl h-full"}>
                                <div className={"text-blue-900 text-2xl font-bold flex items-center"}>
                                    <span>{data.name || ''}</span>
                                    {data.gender !== undefined ?
                                        <span className={"ml-2"}>
                                        {data.gender.value === 0
                                            ?
                                            <img className={"w-[1rem]"}
                                                 src={"https://icons.iconarchive.com/icons/custom-icon-design/flatastic-7/512/Male-icon.png"}
                                                 alt={""}/>
                                            :
                                            <img className={"w-[1rem]"}
                                                 src={"https://icons.iconarchive.com/icons/custom-icon-design/flatastic-7/256/Female-icon.png"}
                                                 alt={""}/>
                                        }
                                    </span>
                                        : null}
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-map'></i>
                                    <span className={"ml-2"}>{data.address || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-barcode'></i>
                                    <span className={"ml-2"}>Mã HS: {data.uuid || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-user'></i>
                                    <span className={"ml-2"}>{data.username || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-envelope'></i>
                                    <span className={"ml-2"}>{data.email || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-phone'></i>
                                    <span className={"ml-2"}>{data.phone || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-cake'></i>
                                    <span className={"ml-2"}>{data.birthday || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <Link to={"/student/edit/"+id}>
                                        <i className='bx bxs-edit'></i> Sửa thông tin học sinh
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={"md:basis-1/2 basis-full p-2"}>
                            {data.extra_information !== undefined ?
                                <div className={"border p-5 bg-gray-50 rounded-2xl h-full"}>
                                    <div className={"text-blue-900 font-bold mb-3"}>Thông tin thêm</div>
                                    <div className={"flex flex-wrap"}>
                                        {data.extra_information.map((item, key) =>
                                            <div key={key} className={"md:basis-1/2 flex items-center mb-2"}>
                                                <i className='bx bxs-book-content mr-2'></i>
                                                <span className={"font-semibold mr-2"}>{item.label}:</span>
                                                <span>{item.value}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
                : <FetchingAnimation/>}
        </MainLayout>
    )
}
export default StudentDetail