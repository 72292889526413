import {Link} from "react-router-dom";

const CardListCol = ({value, style, toLink, onClick,}) => {
    return (
        <td
            onClick={onClick || null}
            className={"bg-white shadow-lg p-2 px-3 border-[#dee2e6] border-[0.2px] min-w-[5rem]"} style={style}>
            {value?.map((item,key) =>
                <div className={"text-sm"} key={key}>
                    {item.uuid} - {item.status}
                </div>
            )}
        </td>
    )
}
export default CardListCol