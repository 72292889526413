import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class CardServices {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async getListCard({setCards, setFetching, config, setConfig, postData}) {
        setFetching(true)
        try {
            const cards = await (await axios.post(this._base_url + "/card/list", postData, this._header))
            setCards(cards.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async getCardForEdit({id, setData, setFetching}) {
        setFetching(true)
        setData({
            id: 1,
            uuid: "THEHOC.0091",
            total_lesson: 8,
            total_amount: 1290000,
            student: {
                value: 1,
                label: 'S.0001 - Phạm Quang Linh - linhcuenini'
            },
            card_transaction: [
                {
                    id: 19,
                    uuid: "GIAODICH.00019",
                    title: "Thanh toán học phí thẻ học",
                    amount: "1,290,000 đ",
                    time: "19:00 01/03/2023",
                    employee: "E.0001"
                },
                {
                    id: 20,
                    uuid: "GIAODICH.00020",
                    title: "Renew thẻ học",
                    amount: "1,290,000 đ",
                    time: "19:00 01/09/2023",
                    employee: "E.0001"
                },
            ]
        })
        setFetching(false)
    }

    async initForCard({setInit, setFetching}) {
        setFetching(true)
        try {
            const init = await (await axios.get(this._base_url + "/card/init", this._header))
            setInit({
                ...init.data, statusInit: [
                    {
                        value: 0,
                        label: "Đã dừng"
                    },
                    {
                        value: 1,
                        label: "Đang hoạt động"
                    },
                    {
                        value: 2,
                        label: "Đang bảo lưu"
                    }
                ]
            })
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async createCard({postData, setFetching}) {
        try {
            await (await axios.post(this._base_url + "/card/store", postData, this._header))
            toast.success("Thêm mới thẻ học thành công")
            window.location.href = "/card/list"
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async getCardById({id, setData, setFetching}) {
        try {
            const card = await (await axios.get(this._base_url + "/card/detail/" + id, this._header))
            setData(card.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async updateCard({id, postData, setFetching}) {
        setFetching(true)
        try {
            await (await axios.put(this._base_url + "/card/update/" + id, postData, this._header))
            toast.success("Cập nhật thành công")
            window.location.href = "/card/list"
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async getCardFullById({id, setData, setFetching}) {
        setFetching(true)
        try {
            const card = await (await axios.get(this._base_url + "/card/detail/" + id + "/full", this._header))
            setData(card.data)
            console.log(card)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async applyCard({id, postData}) {

        try {
            const responseData = await (await axios.put(this._base_url + "/card/apply/" + id, postData, this._header))
            window.opener.postMessage(responseData.data, '*');
            window.close()
            toast.success("Cập nhật thành công")

        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async quickCreateCard({postData, setFetching}) {
        try {
            const card = await (await axios.post(this._base_url + "/card/store/quick", postData, this._header))
            toast.success("Thêm mới thẻ học thành công")
            window.opener.postMessage(card.data, '*');
            window.close()
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }
}