const quickAddClassroom = ({setData, name,data}) => {
    function openQuickCreateStudentPopup() {
        const width = 600;
        const height = 800;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        const popup = window.open('/quick_create_classroom', 'popup', `width=${width},height=${height},left=${left},top=${top}`);

        window.addEventListener('message', (event) => {
            if (event.origin === window.location.origin) {
                setData({...data, [name]: event.data})
            }
            console.log(event)
        });
    }

    return (
        <div onClick={openQuickCreateStudentPopup} className={"text-sm flex items-center text-blue-900 cursor-pointer"}>
            <i className='bx bxs-message-square-add mr-1'></i>
            Thêm lớp học mới
        </div>
    );
}
export default quickAddClassroom