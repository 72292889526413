import MainLayout from "../../layouts/MainLayout";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Paginate from "../../components/table/Paginate";
import Select from "react-select";
import HeadTable from "../../components/table/HeadTable";
import TextCol from "../../components/table/TextCol";
import IconLinkCol from "../../components/table/IconLinkCol";
import MultiUserInfoCol from "../../components/table/MultiUserInfoCol";
import ActionCol from "../../components/table/ActionCol";
import EmptyItem from "../../components/EmptyItem";
import FetchingAnimation from "../../components/FetchingAnimation";
import UserInfoCol from "../../components/table/UserInfoCol";
import RenewFilter from "../../components/filter/RenewFilter";

const CardListScreen = () => {
    const app = useContext(AppContext)
    const [fetching, setFetching] = useState(true)
    const perPageSelect = [
        {value: "10", label: "10"},
        {value: "20", label: "20"},
        {value: "50", label: "50"},
        {value: "100", label: "100"},
        {value: "200", label: "200"},
        {value: "500", label: "500"},
        {value: "1000", label: "1000"},
        {value: "-1", label: "Tất cả"},
    ]
    const [filter, setFilter] = useState({})
    const [cards, setCards] = useState([])
    const [config, setConfig] = useState({
        perPage: 10,
        currentPage: 1,
        totalPage: 10,
        totalRecord: 0,
        startRecord: 1,
        endRecord: 10,
    })
    const confirmDelete = (id) => {
        // app.deleteStudent({id: id, setFetching: setFetching, fetchData: fetchData}).then()
    }
    const fetchData = () => {
        const params = {
            postData: {
                limit: config.perPage,
                offset: (config.currentPage - 1) * config.perPage,
                conditions: transformFilterObject(filter),
            },
            config: config,
            setConfig: setConfig,
            setFetching: setFetching,
            setCards: setCards
        }
        app.cardService.getListCard(params).then()
    }
    const startFilter = () => {
        fetchData()
    }
    useEffect(() => {
        fetchData()
    }, [config.currentPage])

    useEffect(() => {
        if (config.currentPage !== 1) {
            setConfig({...config, currentPage: 1})
        } else {
            fetchData()
        }

    }, [config.perPage])
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        console.log(filter)
        startFilter()
    }, [filter])

    function transformFilterObject(inputObject) {
        const transformedObject = {};

        for (const key in inputObject) {
            if (inputObject[key] && typeof inputObject[key] === 'object') {
                const {operator, value} = inputObject[key];
                let rkey = key;
                if (key.includes("_max") || key.includes("_min")) {
                    rkey = rkey.replace("_max", "")
                    rkey = rkey.replace("_min", "")
                }


                transformedObject[`${rkey}:${operator}`] = value;
            }
        }

        return transformedObject;
    }

    return (
        <MainLayout>
            <div className={"p-3"}>
                <div className={"text-blue-900 font-bold text-2xl mb-3"}>
                    Danh sách thẻ học
                </div>
                <div className={"mb-3 flex flex-wrap"}>
                    <Link to={"/card/create"}
                          className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                        <i className='bx bxs-plus-circle mr-2'></i>
                        Thêm thẻ học mới
                    </Link>
                    <button
                        onClick={() => {
                            toast.success("Xuất dữ liệu thành công", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 1000,
                            })
                        }}
                        className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                        <i className='bx bxs-download mr-2'></i>
                        Xuất dữ liệu
                    </button>
                    <button
                        className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mb-3"}>
                        <i className='bx bxs-cloud-upload mr-2'></i>
                        Nhập dữ liệu
                    </button>

                </div>
                <div className={"my-2"}>
                    <RenewFilter min={-99999} max={-1} filter={filter} setFilter={setFilter} type={'S'}
                                 color={"bg-red-600"}/>
                    <RenewFilter min={-1} max={0} filter={filter} setFilter={setFilter} type={'X'}
                                 color={"bg-black"}/>
                    <RenewFilter min={1} max={7} filter={filter} setFilter={setFilter} type={'A'}
                                 color={"bg-yellow-400"}/>
                    <RenewFilter min={8} max={12} filter={filter} setFilter={setFilter} type={'B'}
                                 color={"bg-green-400"}/>
                    <RenewFilter min={13} max={24} filter={filter} setFilter={setFilter} type={'C'}
                                 color={"bg-green-700"}/>
                    <RenewFilter min={25} max={36} filter={filter} setFilter={setFilter} type={'D'}
                                 color={"bg-green-900"}/>
                    <RenewFilter min={37} max={999999} filter={filter} setFilter={setFilter} type={'E'}
                                 color={"bg-blue-800"}/>
                </div>
                <div className={"flex flex-row flex-wrap justify-between mb-3"}>

                    <Paginate config={config} setConfig={setConfig}/>
                    <div className={"flex items-center text-xl md:text-sm "}>
                        <span className={"mr-2 "}>Hiển thị: </span>
                        <Select
                            onChange={(r) => {
                                setConfig({...config, perPage: r.value})
                            }}
                            className={"text-sm p-0 m-0 z-[99]"}
                            defaultValue={perPageSelect[0]}
                            placeholder={""}
                            options={perPageSelect}/>
                        <span className={"ml-2 text-xl md:text-sm"}>hàng
                        ( Từ hàng
                            <span className={"font-bold mx-1"}>
                                {config.startRecord}
                            </span>
                            đến hàng
                            <span className={"font-bold mx-1"}>
                            {config.endRecord}
                            </span>
                            trên tổng số
                            <span className={"font-bold mx-1"}>
                            {config.totalRecord}
                            </span>
                            hàng)
                        </span>
                    </div>

                </div>
                <div className={"max-h-[65vh] overflow-auto"}>
                    <table className={"table border shadow-lg min-w-max w-full"}
                           style={{borderCollapse: "separate", borderSpacing: 0}}>
                        <thead>
                        <tr>
                            <HeadTable label={'Mã thẻ học'} style={{zIndex: "30", left: 0}}/>
                            <HeadTable label={'Học sinh'} style={{position: "sticky", zIndex: "30", left: "10rem"}}/>
                            <HeadTable label={'Tên tiếng Anh'}/>
                            <HeadTable label={'Trạng thái'}/>
                            <HeadTable label={'PDF Đơn đăng ký'}/>
                            <HeadTable label={'Loại lớp được sử dụng'}/>

                            <HeadTable label={'Số buổi đăng ký'}/>
                            <HeadTable label={'Số tiền cần thanh toán'}/>
                            <HeadTable label={'Đơn giá/buổi'}/>
                            <HeadTable label={'Tổng số buổi được sử dụng theo thanh toán'}/>
                            <HeadTable label={'Số buổi bị trừ theo điểm danh'}/>
                            <HeadTable label={'Số buổi bị còn lại có thể sử dụng'}/>
                            <HeadTable label={'Phân loại renew'}/>
                            <HeadTable label={'Số học phí còn lại có thể sử dụng'}/>


                            <HeadTable label={'Trạng thái tiến độ renew'}/>
                            <HeadTable label={'Ngày báo renew gần nhất'}/>


                            <HeadTable label={'Điểm feedback'}/>
                            <HeadTable label={'Thời gian feedback gần nhất'}/>


                            <HeadTable label={'Lớp đang được xếp'}/>

                            <HeadTable label={'Hành động'}/>
                        </tr>
                        </thead>
                        {!fetching ?
                            <tbody>
                            {cards.map((card, key) =>
                                <tr key={card.id}>
                                    <TextCol
                                        toLink={"/card/detail/" + card.id}
                                        value={card.uuid || ''}
                                        style={{position: "sticky", zIndex: "20", left: 0}}/>
                                    <UserInfoCol
                                        username={card.student_id}
                                        value={card.student_uuid || ''}
                                        avatar={card.student_avatar}
                                        name={card.student_name}
                                        address={card.student_uuid}
                                        style={{position: "sticky", zIndex: "20", left: "10rem"}}
                                    />
                                    <TextCol
                                        value={card.student_english_name || ''}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.card_status || ''}
                                        style={{textAlign: "center"}}
                                    />
                                    <IconLinkCol
                                        img={"https://cdn-icons-png.flaticon.com/512/179/179483.png"}
                                        value={card.classroom_pdf || ''}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.classroom_type || ''}
                                        style={{textAlign: "center"}}
                                    />

                                    <TextCol
                                        value={card.total_lesson || ''}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.total_amount || ''}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.amount_per_lesson || ''}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.paid_lesson || 0}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.attendance_lesson_used || ''}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.available_lesson || 0}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.renew_type || 'Chưa có'}
                                        style={{textAlign: "center"}}
                                    />
                                    <TextCol
                                        value={card.available_amount || 0}
                                        style={{textAlign: "center"}}
                                    />

                                    <TextCol
                                        value={card.latest_renew_process || 'Chưa có'}
                                        style={{textAlign: "center"}}
                                    />


                                    <TextCol
                                        value={card.latest_renew_date || 'Chưa có ngày'}
                                        style={{textAlign: "center"}}
                                    />

                                    <TextCol
                                        value={card.feedback_score || 'Chưa có điểm'}
                                        style={{textAlign: "center"}}
                                    />

                                    <TextCol
                                        value={card.feedback_time || 'Chưa có'}
                                        style={{textAlign: "center"}}
                                    />

                                    <UserInfoCol
                                        entity={'classroom'}
                                        avatar={card.classroom_thumbnail || 'https://static.ybox.vn/2019/12/2/1575964975252-Thi%E1%BA%BFt%20k%E1%BA%BF%20kh%C3%B4ng%20t%C3%AAn-74.png'}
                                        name={card.classroom_name || ''}
                                        address={card.classroom_uuid || ''}
                                        style={{textAlign: "center"}}
                                        username={card.classroom_id}
                                    />

                                    <ActionCol
                                        confirmDelete={confirmDelete}
                                        entity={'card'}
                                        id={card.id || key}
                                    />
                                </tr>
                            )}
                            </tbody> :
                            null}
                    </table>
                    {cards.length === 0 ?
                        <EmptyItem/>
                        : null}
                </div>
                {fetching ? <FetchingAnimation/> : null}
            </div>
        </MainLayout>
    )
}
export default CardListScreen