import {Link} from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import ErrorLayout from "../../layouts/ErrorLayout";
const Error500Screen = () => {
    return (
        <ErrorLayout>
            <div className={"flex-1 flex bg-white flex-col items-center justify-center"}>
                <img src={"https://cdn.dribbble.com/users/718859/screenshots/3272116/jisunpark-500-error.gif"}
                     className={"max-w-[60rem]"} alt={""}/>
                <div className={"text-3xl uppercase"}>Bạn không có quyền truy cập , trở về <Link className={"text-blue-900 font-bold"} to={"/"}>Trang chủ</Link>
                </div>
            </div>
        </ErrorLayout>
    )
}
export default Error500Screen