import {Link} from "react-router-dom";

const TextCol = ({value, style, toLink, onClick}) => {
    return (
        <td
            onClick={onClick || null}
            className={"bg-white shadow-lg p-2 px-3 border-[#dee2e6] border-[0.2px] min-w-[5rem]"} style={style}>
            {toLink !== undefined
                ?
                <Link to={toLink}>
                    <div className={"font-bold text-blue-900"}>{value}</div>
                </Link>
                :
                <div>
                    {value}
                </div>
            }
        </td>
    )
}
export default TextCol