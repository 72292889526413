import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class TransactionService {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async storeTransaction({transaction, setTransaction}) {
        try {
            await (await axios.post(this._base_url + "/transaction/store", transaction, this._header))
            setTransaction({...transaction, value: "", transaction_bill: "", message: ""})
            window.location.reload()
            toast.success("Thành công !")
        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async getOneTransaction({setTransaction, id}) {
        try {
            const transactionResponse = await (await axios.get(this._base_url + "/transaction/detail/" + id, this._header))
            setTransaction(transactionResponse.data)
        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async acceptTransaction({id}) {
        try {
            await (await axios.put(this._base_url + "/transaction/update/" + id + "/accept", {}, this._header))
            toast.success("Thành công")
        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }
}