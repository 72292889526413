const UserInteractiveChip = ({avatar,name,time})=>{
    return(
        <div className={'bg-blue-50 border rounded'}>
            <div className={"p-2 flex items-center"}>
                <img className={"w-[2rem] h-[2rem] rounded-full mr-2"}
                     src={avatar} alt={""}/>
                <div>
                    <div className={"text-sm font-bold"}>{name}</div>
                    <div className={"text-xs italic opacity-60"}>{time}</div>
                </div>
            </div>
        </div>
    )
}
export default UserInteractiveChip