import TextCol from "./table/TextCol";
import HeadTable from "./table/HeadTable";

const CustomFieldsCols = ({custom_fields}) => {
    return (
        <>
            {custom_fields?.map((field) =>
                <HeadTable label={field.label}/>
            )}
        </>
    )
}
export default CustomFieldsCols;