import MainLayout from "../../layouts/MainLayout";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import TransactionAddScreen from "../Transaction/TransactionAddScreen";
import FetchingAnimation from "../../components/FetchingAnimation";
import TextInput from "../../components/inputs/TextInput";
import TextFilter from "../../components/filter/TextFilter";
import RichTextInput from "../../components/inputs/RichTextInput";
import MessageComment from "../../components/MessageComment";
import TransactionComment from "../../components/TransactionComment";
import AttendanceComment from "../../components/AttendanceComment";
import RenewAddScreen from "../Renew/RenewAddScreen";
import InternalFeedbackComment from "../../components/InternalFeedbackComment";


const CardInfoScreen = () => {
    const {id} = useParams()
    const app = useContext(AppContext)
    const [card, setCard] = useState({})
    const [fetching, setFetching] = useState(false)
    const [filter, setFilter] = useState({})
    const [comment, setComment] = useState({})
    const [showPopup, setShowPopup] = useState(false)
    const cardInformation = () => {
        app.cardService.getCardFullById({id: id, setData: setCard, setFetching: setFetching}).then()
    }
    const storeComment = () => {
        app.commentService.storeComment({
            data: card,
            setData: setCard,
            comment: comment,
            setComment: setComment
        }).then()
    }
    useEffect(() => {
        cardInformation()
    }, []);
    useEffect(() => {
        setComment({...comment, relation_uuid: card.uuid})
    }, [card]);

    const openCardStudyLog = () => {
        setShowPopup(true)
    }

    function openCardFeedback() {
        const width = 600;
        const height = 800;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const popup = window.open('/card_feedback?card_id=' + card.id, 'popup', `width=${width},height=${height},left=${left},top=${top}`);

        window.addEventListener('message', (event) => {
            if (event.origin === window.location.origin) {
                const dataFromPopup = event.data;
                cardInformation()
            }
        });
    }

    return (
        <MainLayout>
            {!fetching ?
                <div className={"flex flex-wrap"}>

                    <div className={"md:basis-1/2 basis-full p-1 relative"}>
                        <CardStudyLogPopup
                            show={showPopup}
                            setShow={setShowPopup}
                            card={card}
                        />
                        <div className={"bg-gray-100 p-2 py-5 rounded border mb-2"}>
                            <div className={"font-semibold text-xl"}>Mã thẻ học: {card.uuid}</div>
                            <div className={"my-1"}>
                                <div className={"mb-1"}>Sở hữu:
                                    <Link to={"/student/detail/" + card.student_id}>
                                        {card.student_name} {card.student_english_name ? "(" + card.student_english_name + ")" : null}
                                    </Link>
                                </div>
                                <div className={"mb-1"}>
                                    Mã học sinh: {card.student_uuid}
                                </div>
                                <div className={"mb-1"}>
                                    Liên kết lớp : {card.classroom_uuid} - {card.classroom_name}
                                </div>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-2 py-5 rounded border mb-2"}>
                            <div className={"my-1"}>
                                <div className={"mb-1"}>
                                    Số buổi đăng ký gốc: <b>{card.reality_lessons}</b> buổi
                                </div>
                                <div className={"mb-1"}>
                                    Số buổi được tặng thêm : <b>{card.bonus_lessons}</b> buổi
                                </div>
                                <div className={"mb-1"}>
                                    <b>Số buổi thực tế được đăng ký</b> : <b
                                    className={"text-blue-900"}>{card.total_lesson}</b> buổi
                                </div>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-2 py-5 rounded border mb-2"}>
                            <div className={"mb-1"}>
                                Số tiền thực tế cần thanh toán : <b>{card.reality_amount}</b> đ
                            </div>
                            <div className={"mb-1"}>
                                Khấu trừ : <b>{card.promotion_amount}</b> đ
                            </div>
                            <div className={"mb-1"}>
                                % Khấu trừ : <b>{card.promotion_percent}</b> %
                            </div>
                            <div className={"mb-1 font-bold"}>
                                Số tiền thực tế cần thanh toán : <b
                                className={"text-blue-900"}>{card.total_amount}</b> đ
                            </div>
                            <div className={"mb-1"}>
                                Đơn giá / buổi : <b className={"text-blue-900"}>{card.amount_per_lesson}</b> đ
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-2 py-5 rounded border mb-2"}>
                            <div className={"my-1"}>
                                <div className={"mb-1"}>
                                    Số tiền đã đóng (mọi giao dịch trên thẻ) : <b
                                    className={"rounded bg-green-800 text-white p-1"}>{card.paid_amount} đ</b>
                                </div>
                                <div className={"mb-1"}>
                                    Số buổi thực tế được sử dụng tương ứng : <b>{card.available_lesson}</b> buổi
                                </div>
                                <div className={"mb-1"}>
                                    Số tiền chưa thanh toán hết : <b>{card.unpaid_amount}</b> đ
                                </div>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-2 py-5 rounded border mb-2"}>
                            <div className={"my-1"}>
                                <div className={"mb-1 flex"}>
                                    Số buổi thực tế đã bị trừ ,theo điểm danh: <b
                                    className={"mx-1"}>{card.attendance_lesson_used}</b> buổi
                                    <span onClick={openCardStudyLog}
                                          className={"cursor-pointer bg-blue-900 text-white p-1 rounded ml-2 text-sm flex justify-center items-center"}>
                                        <i className='bx bx-list-ul'></i>
                                        Lịch sử điểm danh
                                    </span>
                                </div>
                                <div className={"mb-1"}>
                                    Số tiền thực tế đã dùng : <b>{card.attendance_amount_used}</b> đ
                                </div>
                                <div className={"mb-1"}>
                                    Số buổi còn lại có thể dùng : <b>{card.can_used_lessons}</b> buổi
                                </div>
                                <div className={"mb-1"}>
                                    Số tiền còn lại có thể dùng, tương ứng
                                    : <b>{card.can_used_amount > 0 ? card.can_used_amount.toLocaleString() + " đ" : "Đã quá hạn mức sử dụng"}</b>
                                </div>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-2 py-5 rounded border"}>
                            {card.uuid !== undefined ?
                                <TransactionAddScreen relation_uuid={card.uuid || ''} type={0}/> : null
                            }
                        </div>

                    </div>
                    <div className={"md:basis-1/2 basis-full p-1 "}>
                        <div>
                            <div className={"flex flex-wrap"}>
                                <div className={"basis-full md:basis-1/2 px-1"}>
                                    <TextFilter icon={<i className={"bx bxs-user"}></i>} setFilter={setFilter}
                                                filter={filter} name={"user"}
                                                placeholder={"Tìm theo tên người"}></TextFilter>
                                </div>
                                <div className={"basis-full md:basis-1/2 px-1"}>
                                    <TextFilter icon={<i className={"bx bxs-message"}></i>} setFilter={setFilter}
                                                filter={filter} name={"message"}
                                                placeholder={"Tìm theo nội dung"}></TextFilter>
                                </div>
                            </div>
                        </div>
                        {card.comments !== undefined ?
                            <div className={"border rounded p-2 py-5 max-h-[60vh] min-h-[50vh] overflow-y-scroll"}>
                                {card.comments.map((comment, key) =>
                                    <div key={key}>
                                        {comment.comment_type === "message" ?
                                            <MessageComment comment={comment}/> : null}
                                        {comment.comment_type === "transaction" ?
                                            <TransactionComment comment={comment}/> : null}
                                        {comment.comment_type === "attendance" ?
                                            <AttendanceComment comment={comment}/> : null}
                                        {comment.comment_type === "internal_feedback" ?
                                            <InternalFeedbackComment comment={comment}/> : null}
                                    </div>
                                )}
                            </div> : null}
                        <div className={"mt-2"}>
                            <RichTextInput name={"text_content"} height={"180px"} placeholder={""} data={comment}
                                           setData={setComment}/>
                            <button onClick={storeComment} className={"p-2 bg-blue-900 text-white my-2 rounded"}>Gửi
                                bình luận
                            </button>
                        </div>
                        <button onClick={openCardFeedback} className={"p-2 bg-blue-900 text-white my-2 rounded"}>
                            Lấy Feedback
                        </button>
                        <div className={"bg-gray-100 p-2 py-5 rounded border mt-2"}>
                            {card.uuid !== undefined ?
                                <RenewAddScreen card_id={card.id || ''}/> : null
                            }
                        </div>
                    </div>
                </div> :
                <FetchingAnimation/>
            }
        </MainLayout>
    )
}

const CardStudyLogPopup = ({card, show, setShow}) => {
    return (
        <div className={(!show ? "hidden" : "") + " absolute bg-white w-full h-full"}>
            <div className={"p-1"}>
                <div className={"font-bold mb-3"}>
                    Lịch sử điểm danh
                    <button onClick={() => {
                        setShow(false)
                    }} className={"ml-2 bg-blue-900 text-sm font-normal p-1 rounded text-white"}>Đóng</button>
                </div>
                {card?.card_logs?.map((log, key) =>
                    <div key={key} className={"bg-blue-50 p-2 rounded border"}>
                        <div className={"text-sm"}>
                            <div><b>Ngày học </b>: {log.study_log?.selected_day}</div>
                            <div><b>Thứ</b>: {log.study_log?.calendar?.week_day} </div>
                            <div><b>Lớp</b> : {log.study_log?.classroom_name}</div>
                            <div><b>Trạng thái điểm danh</b> : {log.study_status}</div>
                            <div><b>Trừ buổi trên thẻ học</b>: {log.calculate}</div>
                            <div><b>Số tiền đã sử dụng</b>: {log.used_amount.toLocaleString()} đ</div>
                            <div><b>Bình luận của giáo viên</b>: {log.teacher_comment}</div>
                            <div><b>Bình luận của trợ giảng</b>: {log.supporter_comment}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CardInfoScreen