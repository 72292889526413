import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class RuleService {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async getAllRule({setRules}) {

        try {
            const rulesResponse = await (await axios.get(this._base_url + "/rule/list", this._header))
            setRules(rulesResponse.data)
        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async getAccessRuleByUsername({username, setAccess}) {
        if (username === undefined) {
            toast.warn("Không tìm thấy")
            return false
        }
        try {
            const axiosResponse = await (await axios.get(this._base_url + "/rule/detail/" + username, this._header))
            setAccess(axiosResponse.data)
            toast.success("Lấy thành công")
        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async applyPermissions({postData}) {
        try {
            await (await axios.post(this._base_url + "/permission/update", postData, this._header))
            toast.success('Cập nhật thành công')
        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async getAllPermission({setPermissions}) {
        try {
            const permissionResponse = await (await axios.get(this._base_url + "/permissions", this._header))

            setPermissions(permissionResponse.data)

        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async getPermissionsByUsername({setUserPermissions, username}) {
        try {
            const permissionResponse = await (await axios.get(this._base_url + "/" + username + "/permissions", this._header))
            setUserPermissions(permissionResponse.data)

        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async getAllGroupSelect({setGroups}) {
        try {
            const permissionResponse = await (await axios.get(this._base_url + "/group/select", this._header))
            setGroups(permissionResponse.data)

        } catch (e) {
            toast.warn(e.response.data.message)
        }
    }

    async getPermissionsByGroup({id}) {
        try {
            return await (await axios.get(this._base_url + "/group/detail/" + id, this._header))

        } catch (e) {
            toast.warn(e.response.data.message)
        }    }

    async getAllGroup({setFetching,setGroups}) {
        setFetching(true)
        try {
            const permissionResponse = await (await axios.get(this._base_url + "/group/list", this._header))
            setGroups(permissionResponse.data)

        } catch (e) {
            toast.warn(e.response.data.message)
        }finally {
            setFetching(false)
        }
    }

    async createPermissionGroup({data,setFetching}) {
        try {
            return await (await axios.post(this._base_url + "/group/create",data, this._header))
        } catch (e) {
            toast.warn(e.response.data.message)
        }finally {
            setFetching(false)
        }
    }

    async updatePermissionGroup({data,id,setFetching}) {
        setFetching(true)
        try {
            await (await axios.put(this._base_url + "/group/update/"+id,data, this._header))
            toast.success("Cập nhật thành công")
        } catch (e) {
            toast.warn(e.response.data.message)
        }finally {
            setFetching(false)
        }
    }
}