import Select from 'react-select'

const SelectFilter = ({icon, filter, setFilter, name, options, placeholder, operator}) => {
    const setCondition = (e) => {
        const newCondition = {
            key: name,
            operator: operator || 'eq',
            value: e.value
        }
        setFilter({...filter, [name]: newCondition})
        console.log(filter)
    }

    function getFilterData() {
      return  options.find(item => item.value === filter[name]?.value)
    }

    return (
        <div className="w-full md:w-[20rem] mr-2 mb-3 relative z-50">
            <Select
                className={"p-1"}
                options={options}
                placeholder={placeholder}
                value={getFilterData() || null}
                onChange={setCondition}
            />
        </div>
    )
}
export default SelectFilter