import {useEffect, useState} from "react";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";

const RenewAddScreen = ({card_id}) => {
    const [init, setInit] = useState({
        renew_status: [
            {
                value: 0,
                label: 'Chưa lấy feedback'
            },
            {
                value: 1,
                label: 'Đã lấy feedback, và feedback xấu'
            },
            {
                value: 2,
                label: 'Feedback tốt, chưa báo renew'
            }
        ]
    })
    const [data, setData] = useState({})

    function storeRenewStatus() {

    }

    return (
        <div>
            <div>
                <div className={"mb-3"}>
                    <SelectInput
                        label={'Trạng thái renew'}
                        options={init.renew_status || []}
                        data={data}
                        setData={setData}
                        name={'renew_status'}/>
                </div>
                <div className={"mb-3"}>
                    <TextInput
                        label={'Ghi chú'}
                        data={data}
                        setData={setData}
                        name={'renew_note'}
                    />
                </div>
            </div>

            <button onClick={storeRenewStatus} className={"p-2 mx-2 bg-blue-900 text-white my-2 rounded"}>
                Cập nhật trạng thái renew
            </button>
        </div>
    );
}

export default RenewAddScreen