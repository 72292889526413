import {toast} from "react-toastify";
import axios from "./globalApi";
import {appVendor} from "../config";

export class StudyLogServices {
    _baseUrl = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
    }

    async getListStudyLog({postData, setStudyLogs, config, setConfig, setFetching}) {
        setFetching(true)
        try {
            const studyLogsResponse = await (await axios.post(this._baseUrl + "/study_log/list", config, this._header))
            setStudyLogs(studyLogsResponse.data)
        } catch (e) {
            toast.error(e.response.data.message)
        }
        setFetching(false)
    }

    async getCalendarByClassAndDate({postData, data, setData}) {
        try {
            const calendarResponse = await (await axios.post(this._baseUrl + "/study_log/init/classroom/calendar", postData, this._header))
            setData({...data, initCalendar: calendarResponse.data})
        } catch (e) {
            toast.warn(e.response.data.message)
            setData({
                ...data, initCalendar: [
                    ...data.initCalendar, {
                        value: -1,
                        label: "Buổi học khác"
                    }
                ]
            })
            setData({...data, initCalendar: []})
        }
    }

    async getInitInBranch({setInit, init}) {
        try {
            const initResponse = await (await axios.get(this._baseUrl + "/study_log/init/classroom", this._header))
            setInit(initResponse.data)
        } catch (e) {
            toast.warn(e.response.data.message)
        }

    }


    async createStudyLog({data, setFetching}) {

        setFetching(true)
        console.log(data)
        try {
            const studyLog = await (await axios.post(this._baseUrl + "/study_log/store", data, this._header))
            toast.success("Điểm danh thành công")
            return true
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async getStudyLogShiftData({postData, setInit, init}) {
        try {
            const studentsResponse = await (await axios.post(this._baseUrl + "/study_log/init/classroom/shift", postData, this._header))

            setInit({...init, ...studentsResponse.data})

        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    async getDetailStudyLogForTab({id, setLog}) {
        try {
            const logResponse = await (await axios.get(this._baseUrl + "/study_log/detail/" + id, this._header))

            setLog(logResponse.data)

        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    async confirmProcess({type, study_log_id}) {
        try {
            await (await axios.post(this._baseUrl + "/study_log/process/" + study_log_id, {
                process_type: type
            }, this._header))

        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    async acceptStudyLog({id}) {
        try {
            await (await axios.put(this._baseUrl + "/study_log/accept/" + id, {}, this._header))
            toast.success('Duyệt thành công')
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    deleteStudyLog() {

    }

    async getStudyLogInitData({setInit, postData, init}) {
        try {
            const studentsResponse = await (await axios.post(this._baseUrl + "/study_log/init/classroom/students", postData, this._header))

            setInit({
                ...init, ...studentsResponse.data
            })

        } catch (e) {
            toast.error(e.response.data.message)
        }
    }
}