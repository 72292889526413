import {Link} from "react-router-dom";

const AcceptedStatusCol = ({status,user,time, style, toLink, onClick}) => {
    return (
        <td
            onClick={onClick || null}
            className={"bg-white shadow-lg p-2 px-3 border-[#dee2e6] border-[0.2px] min-w-[5rem]"} style={style}>
            {
                <Link to={toLink}>
                    <div>
                        <div className={"font-bold"}>{status}</div>
                        <div className={"text-sm font-normal"}>{user?"Người duyệt: ":""} {user}</div>
                        <div className={"text-sm font-normal"}>{time}</div>
                    </div>
                </Link>

            }
        </td>
    )
}
export default AcceptedStatusCol