import Select from "react-select";
import {useEffect} from "react";
import {toast} from "react-toastify";
import QuickAddTeacher from "../QuickAddTeacher";
import QuickAddSupporter from "../QuickAddSupporter";

const ShiftsAttendanceInput = ({name, data, setData, init, setInit}) => {
    const updateStatus = ({changeName, id, changeValue}) => {
        const newData = data[name].map((item, key) => {
            if (key === id) {
                return {...item, [changeName]: changeValue}
            }
            return item
        })
        setData({...data, [name]: newData})
    }
    const handleFileUpload = ({changeName, id, file}) => {

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const base64Data = e.target.result;
                console.log(base64Data)
                updateStatus({
                    changeName: changeName,
                    id: id,
                    changeValue: base64Data
                })
            };
            reader.readAsDataURL(file);
        }


    };

    function addBlankShift() {
        setData({...data, [name]: [...data[name], {}]})
        setInit({...init, initShifts: [...init.initShifts, {}]})
    }

    function removeShift({locate}) {
        const shifts = data[name].filter((data, key) => key !== locate)
        toast.success("Xóa ca học " + locate)
        setData({...data, [name]: shifts})
        const newInitShift = init.initShifts.filter((shift, key) => key !== locate)
        setInit({...init, initShifts: newInitShift})
    }

    return (
        <div className={''}>
            {data[name] !== undefined ?
                <div>
                    <div className={"mt-5"}>Ca học</div>
                    {data[name].map((shift, key) =>
                        <div key={key}
                             className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 mt-5 pb-5 items-center relative"}>
                            <div
                                className={"cursor-pointer absolute -top-3 -right-3 bg-red-800 text-white w-[2rem] h-[2rem] flex justify-center items-center rounded"}
                                onClick={() => removeShift({locate: key})}
                            >
                                <i className='bx bxs-trash'></i>
                            </div>
                            <div className={"basis-1/8 px-2"}>
                                <div className={"font-semibold block mb-2 text-sm"}>Bắt đầu
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <input
                                    type={"time"}
                                    onChange={(r) => {
                                        updateStatus({
                                            changeName: "start",
                                            changeValue: r.target.value,
                                            id: key
                                        })
                                    }} value={shift.start} className={"p-2 border rounded-md w-full"}/>
                            </div>
                            <div className={"basis-1/8 px-2"}>
                                <div className={"font-semibold block mb-2 text-sm"}>Kết thúc
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <input
                                    type={"time"}
                                    onChange={(r) => {
                                        updateStatus({
                                            changeName: "end",
                                            changeValue: r.target.value,
                                            id: key
                                        })
                                    }}
                                    value={shift.end} className={"p-2 border rounded-md w-full"}/>
                            </div>
                            <div className={"basis-1/6 px-2"}>
                                <div className={"font-semibold block mb-2 text-sm"}>Phòng học
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <input
                                    onChange={(r) => {
                                        updateStatus({
                                            changeName: "room",
                                            changeValue: r.target.value,
                                            id: key
                                        })
                                    }}
                                    value={shift.room} className={"p-2 border rounded-md w-full"}/>
                            </div>
                            <div className={"basis-1/6 px-2"}>
                                <div className={"font-semibold block mb-2 text-sm"}>Giáo viên
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <Select options={init.initTeachers ?? []}
                                        value={shift.teacher}
                                        onChange={(r) => {
                                            updateStatus({
                                                changeName: "teacher",
                                                changeValue: r,
                                                id: key
                                            })
                                        }}
                                        className={"rounded-md w-full"}
                                />
                                <QuickAddTeacher data={{
                                    changeName: "teacher",
                                    id: key
                                }} callback={(props) => {
                                    updateStatus({
                                        changeName: 'teacher',
                                        changeValue: props.value,
                                        id: key
                                    })
                                }}/>
                            </div>
                            <div className="basis-1/8 px-2">
                                <div className={"font-semibold block mb-2 text-sm"}>Timestamp Giáo viên
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <input
                                    onChange={r => {
                                        handleFileUpload({
                                            changeName: "teacher_timestamp",
                                            id: key,
                                            file: (r.target.files[0])
                                        })
                                    }}
                                    type={"file"}
                                    className={"focus:ring-0 text-sm w-full rounded-md border"}/>
                            </div>
                            <div className={"basis-1/6 px-2"}>
                                <div className={"font-semibold block mb-2 text-sm"}>Trợ giảng
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <Select options={init.initSupporters ?? []}
                                        value={shift.supporter}
                                        className={"rounded-md w-full"}
                                        onChange={(r) => {
                                            updateStatus({
                                                changeName: "supporter",
                                                changeValue: r,
                                                id: key
                                            })
                                        }}

                                />
                                <QuickAddSupporter data={{
                                    changeName: "supporter",
                                    id: key
                                }} callback={(props) => {
                                    updateStatus({
                                        changeName: 'supporter',
                                        changeValue: props.value,
                                        id: key
                                    })
                                }}/>
                            </div>

                            <div className="basis-1/6 px-2">
                                <div className={"font-semibold block mb-2 text-sm"}>Timestamp Trợ giảng
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <input
                                    onChange={r => {
                                        handleFileUpload({
                                            changeName: "supporter_timestamp",
                                            id: key,
                                            file: (r.target.files[0])
                                        })
                                    }}
                                    type={"file"}
                                    className={"focus:ring-0 text-sm w-full border rounded-md"}/>
                            </div>
                            <div className={"basis-1/2 px-2 mt-4"}>
                                <div className={"font-semibold block mb-2 text-sm"}>Báo cáo của giáo viên
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <textarea value={shift.teacher_report} onChange={(r) => {
                                    updateStatus({
                                        changeName: "teacher_report",
                                        id: key,
                                        changeValue: r.target.value
                                    })
                                }} className={"rounded w-full border-gray-300 text-sm"}>

                                </textarea>
                            </div>
                            <div className={"basis-1/2 px-2 mt-4"}>
                                <div className={"font-semibold block mb-2 text-sm"}>Báo cáo của trợ giảng
                                    <span className={"ml-1 text-red-600"}>*</span>
                                </div>
                                <textarea value={shift.supporter_report} onChange={(r) => {
                                    updateStatus({
                                        changeName: "supporter_report",
                                        id: key,
                                        changeValue: r.target.value
                                    })
                                }} className={"rounded w-full border-gray-300 text-sm"}>

                                </textarea>
                            </div>
                        </div>
                    )}
                    <div onClick={addBlankShift}
                         className={"cursor-pointer bg-green-800 text-white w-[20rem] p-2 my-2 rounded text-center"}>Thêm
                        ca học
                    </div>
                </div>
                :
                <div>
                    <div className={"px-2 italic text-sm text-green-800"}>Chưa có ca học, Chọn ngày học để thêm lịch học
                        hoặc ca học tùy chọn
                    </div>
                </div>
            }
        </div>
    )
}
export default ShiftsAttendanceInput