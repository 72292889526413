import {useContext, useState} from "react";
import {AppContext} from "../../AppContext";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const LoginScreen = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const login = () => {
        app.authService.loginByUsernameAndPassword({postData: data}).then((r) => {
            console.log(r)
            if (r?.status === 201) {
                nav('/verify/' + data.username)
            }

            if (r?.status === 203) {
                localStorage.setItem("token", r.data.token)
                localStorage.setItem("login_at", r.data.created_at)
                window.location.href = "/select_branch"
            }

            if (r?.status === 200) {
                localStorage.setItem("token", r.data.token)
                localStorage.setItem("login_at", r.data.created_at)
                window.location.href = "/"
            }
        })
    }
    const [data, setData] = useState({})
    return (
        <div className={"flex items-center justify-center px-5 h-[100vh] w-full"}>
            <div className={"max-w-[25rem] w-full text-center"}>
                <div className={"text-[3rem] text-blue-900 font-bold mb-3 capitalize"}>BSM</div>
                <div className={"p-5 bg-white rounded border py-10"}>
                    <div className="mb-6">
                        <input type="text" id="username"
                               value={data.username || ''}
                               onChange={(r) => setData({...data, username: r.target.value})}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 hohobg-gray-700 hohoborder-gray-600"
                               placeholder="" required/>
                    </div>
                    <div className="mb-6">
                        <input type="password" id="password"
                               value={data.password || ''}
                               onChange={(r) => setData({...data, password: r.target.value})}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               required/>
                    </div>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                            <input id="remember" type="checkbox" value=""
                                   className="w-4 h-4 border border-gray-300 rounded bg-gray-50 hohobg-gray-700"
                                   required/>
                        </div>
                        <label htmlFor="remember"
                               className="ml-2  text-sm font-medium text-gray-900">Ghi
                            nhớ đăng nhập</label>
                    </div>
                    <button type="button"
                            onClick={() => {
                                login()
                            }}
                            className={"bg-blue-900 text-white p-2 rounded hover:bg-blue-800 transition-all w-full"}>
                        Đăng nhập
                    </button>
                    <div className={"pt-5"}>
                        <span className={"mr-1 text-sm"}>Cơ sở chưa đăng ký tài khoản?</span>
                        <a href={"/register"} className={"text-sm text-blue-900 cursor-pointer"}>Đăng ký</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginScreen