const DateInput = ({label, name, data, setData, placeholder, required}) => {
    return (
        <div className={"px-2"}>
            <label htmlFor="first_name"
                   className="block mb-2 text-sm font-medium text-gray-900 ">
                {label}
                {required ? <span className={"ml-1 text-red-600 font-black"}>*</span> : null}

            </label>
            <input type="date" id={name}
                   value={data[name] || ''}
                   onChange={(r) => {
                       setData({...data, [name]: r.target.value})
                   }}
                   className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 hohobg-gray-700 hohoborder-gray-600 hohoplaceholder-gray-400  hohofocus:ring-blue-500 hohofocus:border-blue-500"
                   placeholder={placeholder} required/>
        </div>
    )
}
export default DateInput