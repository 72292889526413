import MainLayout from "../../layouts/MainLayout";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import FetchingAnimation from "../../components/FetchingAnimation";
import TextFilter from "../../components/filter/TextFilter";
import MessageComment from "../../components/MessageComment";
import TransactionComment from "../../components/TransactionComment";
import AttendanceComment from "../../components/AttendanceComment";
import RichTextInput from "../../components/inputs/RichTextInput";
import {useParams} from "react-router-dom";
import BoxChatButton from "../../components/chip/BoxChatButton";

const ClassroomTabScreen = () => {
    const {id} = useParams();
    const app = useContext(AppContext)
    const [fetching, setFetching] = useState(false)
    const [filter, setFilter] = useState({})
    const [classroom, setClassroom] = useState({})
    const [comment, setComment] = useState({})
    const storeComment = () => {

    }
    useEffect(() => {
        app.classroomService.getClassroomFullById({id: id, setData: setClassroom, setFetching: setFetching}).then()
    }, []);
    return (
        <MainLayout>
            <div>
                {!fetching ?
                    <div>
                        <div className={"flex flex-wrap"}>
                            <div className={'md:basis-1/2 basis-full p-2'}>
                                <div className={"p-2"}>
                                    <div className={"text-2xl font-bold mb-2"}>Tên lớp
                                        : {classroom.classroom_name}</div>
                                    <div className={"text-gray-500 px-1"}>
                                        <div>Mã lớp : {classroom.uuid}</div>
                                        <div>Bstudy Account : {classroom.bstudy_account}</div>
                                        <div>Tên giáo trình sử dụng : {classroom.textbook_name}</div>
                                    </div>
                                    {/*<img className={"w-[10rem]"} src={classroom.thumbnail}/>*/}
                                    <div className={"flex flex-wrap"}>
                                        <div className={"md:basis-1/3 my-5 p-1"}>
                                            <BoxChatButton label={"Group bài giảng"}
                                                           link={classroom.lesson_social_group}/>
                                        </div>
                                        <div className={"md:basis-1/3 my-5 p-1"}>
                                            <BoxChatButton label={"Group bài giảng"} link={classroom.parent_group}/>
                                        </div>
                                        <div className={"md:basis-1/3 my-5 p-1"}>
                                            <BoxChatButton label={"Group bài giảng"} link={classroom.student_group}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"p-2 rounded mb-5 border-t"}>
                                    <div className={"text-green-800 font-bold flex items-center"}>
                                        <i className='bx bxs-credit-card-alt text-xl mr-1' ></i>
                                        <span>Thẻ học</span>
                                    </div>
                                    {classroom.cards !== undefined?
                                        <div className={"flex flex-wrap"}>
                                            {classroom.cards.map((card,key)=>
                                                <div className={"md:basis-1/3 p-1"} key={key}>
                                                    <div className={"shadow border-green-900 basis-full bg-green-800 text-white text-sm p-2 rounded"}>
                                                        <div className={"font-bold"}>{card.uuid}</div>
                                                        <div>Tên học sinh : {card.student_name}</div>
                                                        <div>Số buổi được sử dụng : {card.available_lessons} buổi</div>
                                                        <div>Số buổi đã sử dụng : {card.used_lessons} buổi</div>
                                                        <div>Số tiền được sử dụng : {card.available_amount.toLocaleString()} đ</div>
                                                        <div>Số tiền đã sử dụng : {card.used_amount.toLocaleString()} đ</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        :null}
                                </div>
                                <div className={"p-2 rounded mb-5 border-t"}>
                                    <div className={"text-green-800 font-bold flex items-center"}>
                                        <i className='bx bxs-calendar-alt text-xl mr-1' ></i>
                                        <span>Lịch học</span>
                                    </div>
                                    {classroom.calendars !== undefined?
                                        <div className={"flex flex-wrap"}>
                                            {classroom.calendars.map((calendar,key)=>
                                                <div className={"md:basis-1/2 basis-full p-1"}>
                                                   <div className={"p-2 rounded text-green-800"}>
                                                       <div className={"flex items-center"}>
                                                           <i className='bx bxs-lemon mr-1' ></i>
                                                           <div className={"mr-2 font-bold"}>{calendar.week_day}</div>
                                                           <i className='bx bxs-time mr-2' ></i>
                                                           {calendar.start} - {calendar.end}
                                                       </div>
                                                       {calendar.shifts!==undefined
                                                           ?
                                                           <div className={"mt-1"}>
                                                               {calendar.shifts.map((shift,key)=>
                                                                   <div key={key} className={"text-sm border p-1 mb-1 rounded"}>
                                                                       <div className={"font-bold text-black"}>Ca {key+1} : {shift.start} - {shift.end} </div>
                                                                       <div><b className={"text-blue-900"}>Giáo viên</b> : {shift.teacher.label}</div>
                                                                       <div><b className={"text-blue-900"}>Trợ giảng</b> : {shift.supporter.label}</div>
                                                                       <div><b className={"text-blue-900"}>Phòng học</b> : {shift.room}</div>
                                                                   </div>
                                                               )}
                                                           </div>
                                                           :
                                                           <div>Không có ca học</div>
                                                       }
                                                   </div>
                                                </div>
                                            )}
                                        </div>
                                        :null}
                                </div>
                            </div>
                            <div className={'md:basis-1/2 basis-full p-2 '}>

                                <div>
                                    <div className={"flex flex-wrap"}>
                                        <div className={"basis-full md:basis-1/2 px-1"}>
                                            <TextFilter icon={<i className={"bx bxs-user"}></i>} setFilter={setFilter}
                                                        filter={filter} name={"user"}
                                                        placeholder={"Tìm theo tên người"}></TextFilter>
                                        </div>
                                        <div className={"basis-full md:basis-1/2 px-1"}>
                                            <TextFilter icon={<i className={"bx bxs-message"}></i>}
                                                        setFilter={setFilter}
                                                        filter={filter} name={"message"}
                                                        placeholder={"Tìm theo nội dung"}></TextFilter>
                                        </div>
                                    </div>
                                </div>

                                <div className={"border rounded p-2 py-5 max-h-[60vh] min-h-[50vh] overflow-y-scroll"}>
                                    {classroom.comments !== undefined ?
                                        <div>
                                            {classroom.comments.map((comment, key) =>
                                                <div key={key}>
                                                    <MessageComment comment={comment}/>
                                                </div>
                                            )}
                                        </div>
                                        : null}
                                </div>
                                <div className={"mt-2"}>
                                    <RichTextInput name={"text_content"} height={"180px"} placeholder={""}
                                                   data={comment}
                                                   setData={setComment}/>
                                    <button onClick={storeComment}
                                            className={"p-2 bg-blue-900 text-white my-2 rounded"}>Gửi bình luận
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    : <FetchingAnimation/>}
            </div>
        </MainLayout>
    )
}
export default ClassroomTabScreen