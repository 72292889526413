import {toast} from "react-toastify";
import SelectMultiInput from "./SelectMultiInput";
import SelectInput from "./SelectInput";
import Select from "react-select";
import QuickAddTeacher from "../QuickAddTeacher";
import QuickAddSupporter from "../QuickAddSupporter";

const CalendarInput = ({init, name, label, data, setData}) => {
    const dayOfWeek = [
        {value: 2, label: 'T2'},
        {value: 3, label: 'T3'},
        {value: 4, label: 'T4'},
        {value: 5, label: 'T5'},
        {value: 6, label: 'T6'},
        {value: 7, label: 'T7'},
        {value: 8, label: 'CN'},
    ]
    const addNewCalendar = () => {
        const newItem = {
            week_day: "",
            start: "",
            end: "",
            shifts: [],
        }
        setData({...data, [name]: [...data[name], newItem]})
    }

    const addShift = (key) => {
        setData({
            ...data, [name]: [...data[name].map((item, k) => {
                if (k === key) {
                    return {...item, shifts: [...item.shifts, {}]}
                }
                return item
            })]
        })
    }
    const updateShift = ({shift_key, key, property, value}) => {
        setData({
            ...data, [name]: data[name].map((calendar, k) => {
                if (k === key) {
                    console.log(calendar)
                    return {
                        ...calendar, shifts: calendar.shifts.map((shift, sk) => {
                            if (sk === shift_key) {
                                return {...shift, [property]: value}
                            }
                            return shift;
                        })
                    }
                }
                return calendar
            })
        })
    }
    const handleQuickAddTeacherCallback = (props) => {
        updateShift({
            shift_key: props.shift_key,
            key: props.key,
            property: props.property,
            value: props.value
        })
    }
    const handleQuickAddSupporterCallback = (props) =>{
        updateShift({
            shift_key: props.shift_key,
            key: props.key,
            property: props.property,
            value: props.value
        })
    };
    return (
        <div className={"mx-2"}>
            <label htmlFor="first_name"
                   className="block mb-2 text-sm font-medium text-gray-900 ">{label}</label>
            {data[name] !== [] ?
                <div className={"p-3 bg-white  rounded-lg"}>
                    {data[name].map((calendar, key) =>
                        <div key={key} className={"mb-5 border p-3 rounded relative"}>
                            <div
                                onClick={() => {
                                    setData({
                                        ...data, [name]: [...data[name].filter((item, k) => {
                                            return k !== key
                                        })]
                                    })
                                }}
                                className={"cursor-pointer absolute right-[-0.5rem] top-[-0.5rem]"}>
                                <i className={"bx bx-trash p-1 bg-red-900 hover:bg-red-600 transition-all text-white rounded-full"}></i>
                            </div>

                            <div className={"flex mb-3 flex-wrap"}>
                                <div className={"basis-1/3"}>
                                    <div className={"border p-2 rounded h-full"}>
                                        <div className={"flex mb-3"}>
                                            {dayOfWeek.map((day) =>
                                                <div
                                                    onClick={() => {
                                                        const newState = data[name].map((item, k) => {
                                                            if (k === key) {
                                                                return {...item, week_day: day.value}
                                                            }
                                                            return item
                                                        })

                                                        setData({...data, [name]: newState})
                                                    }}
                                                    className={"cursor-pointer hover:bg-blue-900 hover:text-white transition-all flex items-center justify-center rounded-full border mr-3 w-[2rem] h-[2rem]" + (parseInt(calendar.week_day) === parseInt(day.value) ? " bg-blue-900 text-white " : "")}
                                                    key={day.value}>{day.label}</div>
                                            )}
                                        </div>
                                        <div className={"mb-3 flex"}>
                                            <div className={"mr-2"}>
                                                <div className={"mr-2 text-blue-900 font-semibold mb-1"}>Bắt đầu:</div>
                                                <input onChange={(r) => {
                                                    const newState = data[name].map((item, k) => {
                                                        if (k === key) {
                                                            return {...item, start: r.target.value}
                                                        }
                                                        return item
                                                    })
                                                    setData({...data, [name]: newState})
                                                }} className={"p-2 border-gray-200 rounded"}
                                                       value={calendar.start} type={"time"}/>
                                            </div>
                                            <div className={"mr-2"}>
                                                <div className={"mr-2 text-blue-900 font-semibold mb-1"}>Kết thúc:</div>
                                                <input onChange={(r) => {
                                                    const newState = data[name].map((item, k) => {
                                                        if (k === key) {
                                                            return {...item, end: r.target.value}
                                                        }
                                                        return item
                                                    })
                                                    setData({...data, [name]: newState})
                                                }} className={"p-2 border-gray-200 rounded"}
                                                       value={calendar.end}
                                                       type={"time"}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"basis-2/3 px-3"}>
                                    {calendar.shifts !== undefined ?
                                        <div>
                                            {calendar.shifts.map((shift, shift_key) =>
                                                <div key={shift_key} className={"border p-1 rounded mb-3 shadow"}>
                                                    <div className={"cursor-pointer hover:text-red-900"}>
                                                        <i className='bx bx-minus-circle'></i>
                                                    </div>
                                                    <div className={"flex flex-wrap p-1"}>
                                                        <div className={"mb-3 basis-1/2"}>
                                                            <div className={"px-2"}>
                                                                <label className={"mb-1 block text-sm font-semibold"}>Giáo
                                                                    viên</label>
                                                                <Select
                                                                    options={init.teachersInit}
                                                                    onChange={(r) => {
                                                                        updateShift({
                                                                            shift_key: shift_key,
                                                                            key: key,
                                                                            property: 'teacher',
                                                                            value: r
                                                                        })
                                                                    }}
                                                                    value={shift.teacher || null}
                                                                />
                                                                <QuickAddTeacher data={{
                                                                    shift_key: shift_key,
                                                                    key: key,
                                                                    property: 'teacher',
                                                                }} callback={handleQuickAddTeacherCallback}/>
                                                            </div>
                                                        </div>
                                                        <div className={"mb-3 basis-1/2"}>
                                                            <div className={"px-2"}>
                                                                <label className={"mb-1 block text-sm font-semibold"}>Trợ
                                                                    giảng</label>
                                                                <Select
                                                                    onChange={(r) => {
                                                                        updateShift({
                                                                            shift_key: shift_key,
                                                                            key: key,
                                                                            property: 'supporter',
                                                                            value: r
                                                                        })
                                                                    }}
                                                                    options={init.supporterInit}
                                                                    value={shift.supporter || null}
                                                                />
                                                                <QuickAddSupporter
                                                                    data={{
                                                                        shift_key: shift_key,
                                                                        key: key,
                                                                        property: 'supporter',
                                                                    }}
                                                                    callback={handleQuickAddSupporterCallback}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={"mb-3 basis-1/3"}>
                                                            <div className={"px-2"}>
                                                                <label className={"mb-1 block text-sm font-semibold"}>Thời
                                                                    gian
                                                                    bắt đầu ca</label>
                                                                <input type={"time"}
                                                                       onChange={(r) => {
                                                                           updateShift({
                                                                               shift_key: shift_key,
                                                                               key: key,
                                                                               property: 'start',
                                                                               value: r.target.value
                                                                           })
                                                                       }}
                                                                       value={shift.start}
                                                                       className={"border block p-1 rounded-md border-gray-200 w-full"}/>
                                                            </div>
                                                        </div>
                                                        <div className={"mb-3 basis-1/3"}>
                                                            <div className={"px-2"}>
                                                                <label className={"mb-1 text-sm font-semibold block"}>Thời
                                                                    gian
                                                                    kết thúc ca</label>
                                                                <input type={"time"}
                                                                       value={shift.end}
                                                                       onChange={(r) => {
                                                                           updateShift({
                                                                               shift_key: shift_key,
                                                                               key: key,
                                                                               property: 'end',
                                                                               value: r.target.value
                                                                           })
                                                                       }}
                                                                       className={"border block p-1 rounded-md border-gray-200 w-full"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={"mb-3 basis-1/3"}>
                                                            <div className={"px-2"}>
                                                                <label className={"mb-1 text-sm font-semibold block"}>Phòng
                                                                    học</label>
                                                                <input type={"text"}
                                                                       value={shift.room}
                                                                       onChange={(r) => {
                                                                           updateShift({
                                                                               shift_key: shift_key,
                                                                               key: key,
                                                                               property: 'room',
                                                                               value: r.target.value
                                                                           })
                                                                       }}
                                                                       className={"border block p-1 rounded-md border-gray-200 w-full"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        : null}

                                    <div onClick={() => {
                                        addShift(key)
                                    }}
                                         className={"cursor-pointer transition-all text-blue-900 rounded max-w-[15rem] font-semibold"}>
                                        <i className='bx bx-add-to-queue mr-2'></i>
                                        Thêm
                                        ca học
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div onClick={addNewCalendar}
                         className={"cursor-pointer hover:bg-blue-600 transition-all p-2 bg-blue-900 text-white rounded max-w-[15rem] text-center"}>Thêm
                        buổi học
                    </div>
                </div>
                : null
            }
        </div>
    )
}
export default CalendarInput