import React, {useEffect, useState} from 'react';
import Select from 'react-select';

function CreateSelectInput({data, setData, name, label, placeholder}) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue) {
            const newOption = {value: inputValue, label: inputValue};

            setSelectedOptions([...selectedOptions, newOption]);
            setInputValue('');
        }
    };

    useEffect(() => {

        setData({...data, [name]: selectedOptions})
        console.log("data", data)
    }, [selectedOptions]);

    return (
        <div className={"px-2 mt-3"}>
            <label htmlFor={name}
                   className="block mb-2 text-sm font-medium text-gray-900 ">{label ?? name ?? ""}</label>
            <Select
                isMulti
                backspaceRemovesValue={true}
                value={selectedOptions}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onChange={(r)=>{
                    setSelectedOptions(r)
                }}
                isClearable={true}
                closeMenuOnSelect={true} // Prevent the menu from closing after a selection
                placeholder={placeholder}
                isSearchable
            />
        </div>
    );
}

export default CreateSelectInput;

