import PopupLayout from "../layouts/PopupLayout";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../AppContext";
import {useParams} from "react-router-dom";
import SelectInput from "../components/inputs/SelectInput";

const AddCardToClassroomPopup = () => {
    const app = useContext(AppContext)
    const [classroom, setClassroom] = useState({})
    const [init, setInit] = useState({})
    const [data, setData] = useState({})
    const [fetching, setFetching] = useState(false)
    const url = new URL(window.location.href);
    const classroom_id = url.searchParams.get('classroom_id');
    useEffect(() => {

        if (classroom_id) {
            app.classroomService.getClassroomById({
                id: classroom_id,
                setData: setClassroom,
                setFetching: setFetching
            }).then()

            app.classroomService.initForClassroom({
                setInit: setInit,
                setFetching: setFetching
            }).then()
        }

    }, []);

    const quickCreateCard = () => {
        const width = 600;
        const height = 800;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const popup = window.open('/quick_create_card', 'popup', `width=${width},height=${height},left=${left},top=${top}`);

        window.addEventListener('message', (event) => {
            if (event.origin === window.location.origin) {
                const dataFromPopup = event.data;
                setData({...data, card: event.data})
                setInit({...init, cardInit: [...init.cardInit, event.data]})
            }
        });
    }

    const addToClassroom = async () => {
        await app.cardService.applyCard({
            id: data.card?.value,
            postData: {
                apply_classroom : classroom_id
            },
        })
    }

    return (
        <PopupLayout>
            <div className={"flex justify-center items-center h-[100vh]"}>
                <div>
                    <div className={"text-center text-blue-900 font-bold mb-5 text-xl"}>Chọn thẻ học muốn thêm vào
                        lớp {classroom.uuid}</div>
                    <div>
                        <SelectInput
                            name={'card'}
                            label={false}
                            options={init.cardInit || []}
                            data={data}
                            setData={setData}
                        />
                        <button onClick={quickCreateCard}
                                className={"my-3 flex justify-center items-center mx-1 px-2 text-sm text-green-800 rounded"}>
                            <i className={"bx bxs-plus-circle mr-1"}></i>
                            Thêm thẻ học mới
                        </button>
                        <div onClick={addToClassroom} className={"text-center mx-2 my-3 rounded p-2 bg-blue-900 text-white"}>Thêm vào lớp học
                        </div>
                    </div>
                </div>
            </div>
        </PopupLayout>
    );
}
export default AddCardToClassroomPopup