const Check = ({value}) =>{
    if(value===1){
        return (
            <div className={"flex justify-center"}>
                <img alt={""} src={"https://cdn-icons-png.flaticon.com/512/5610/5610944.png"} className={"w-[1rem]"}/>
            </div>
        )
    }else {
        return ""
    }
}
export default Check