import 'react-confirm-alert/src/react-confirm-alert.css'; // Import CSS cho giao diện mặc định
import './App.css';
import RootScreen from "./screens/RootScreen";
import {ToastContainer} from "react-toastify";
import {AppProvider} from "./AppContext";
import {useEffect} from "react";

function App() {
    useEffect(() => {
        window.addEventListener('error', function (e) {
            console.log('dm')
        });
    }, []);
    return (
        <div>
            <AppProvider>
                <RootScreen/>
            </AppProvider>
        </div>
    );
}

export default App;
