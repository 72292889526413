const BoxChatButton = ({link,label}) =>{
    return(

           <a href={link!==""?link:null} target={"_blank"} className={"text-white "+(link!==""?'bg-blue-900':'bg-blue-300 cursor-no-drop')+" p-2 rounded flex justify-center items-center"}>
               <i className='bx bxs-chat'></i>
               {label}
           </a>

    )
}
export default BoxChatButton