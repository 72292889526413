import MainLayout from "../../layouts/MainLayout";
import HeadTable from "../../components/table/HeadTable";
import TextCol from "../../components/table/TextCol";
import {useContext, useEffect, useState} from "react";
import ActionCol from "../../components/table/ActionCol";
import UserInfoCol from "../../components/table/UserInfoCol";
import {Link} from "react-router-dom";
import TextFilter from "../../components/filter/TextFilter";
import SelectFilter from "../../components/filter/SelectFilter";
import Select from "react-select";
import Paginate from "../../components/table/Paginate";
import FetchingAnimation from "../../components/FetchingAnimation";
import {toast} from "react-toastify";
import EmptyItem from "../../components/EmptyItem";
import {AppContext} from "../../AppContext";
import MultiUserInfoCol from "../../components/table/MultiUserInfoCol";
import LateCol from "../../components/table/LateCol";
import EndDrawer from "../Drawer/EndDrawer";
import StudyLogTab from "./StudyLogTab";
import AcceptedStatusCol from "../../components/table/AcceptedStatusCol";

const StudyLogList = () => {
    const app = useContext(AppContext)
    const [fetching, setFetching] = useState(true)
    const perPageSelect = [
        {value: "10", label: "10"},
        {value: "20", label: "20"},
        {value: "50", label: "50"},
        {value: "100", label: "100"},
        {value: "200", label: "200"},
        {value: "500", label: "500"},
        {value: "1000", label: "1000"},
        {value: "-1", label: "Tất cả"},
    ]
    useEffect(() => {
        fetchData()
    }, [1])
    const [filter, setFilter] = useState({})
    const [studyLogs, setStudyLogs] = useState([])
    const [config, setConfig] = useState({
        perPage: 10,
        currentPage: 1,
        totalPage: 10,
        totalRecord: 0,
        startRecord: 1,
        endRecord: 10,
    })
    const fetchData = () => {

        const params = {
            postData: {currentPage: config.currentPage ?? 1, perPage: config.perPage ?? 10, ...filter},
            setStudyLogs: setStudyLogs,
            config: config,
            setConfig: setConfig,
            setFetching: setFetching
        }
        app.studyLogService.getListStudyLog(params).then()

    }
    const startFilter = () => {
        fetchData()
    }
    useEffect(() => {
        fetchData()
    }, [config.currentPage])

    useEffect(() => {
        if (config.currentPage !== 1) {
            setConfig({...config, currentPage: 1})
        } else {
            fetchData()
        }

    }, [config.perPage])
    const confirmDelete = (id) => {
        // app.deleteStudent({id: id, setFetching: setFetching, fetchData: fetchData}).then()
    }
    const [drawer, setDrawer] = useState(<StudyLogTab/>)
    const [showDrawer, setShowDrawer] = useState(false)
    const showDetail = ({id}) => {
        setDrawer(<StudyLogTab id={id}/>)
        setShowDrawer(true)
    }
    return (
        <div>
            <EndDrawer show={showDrawer} setShow={setShowDrawer}>
                {drawer}
            </EndDrawer>
            <MainLayout>
                <div className={"p-3"}>
                    <div className={"text-blue-900 font-bold text-2xl mb-3"}>
                        Danh sách buổi học
                    </div>
                    <div className={"mb-3 flex flex-wrap"}>
                        <Link to={"/study_log/attendance"}
                              className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                            <i className='bx bxs-plus-circle mr-2'></i>
                            Điểm danh
                        </Link>
                        <button
                            onClick={() => {
                                toast.success("Xuất dữ liệu thành công", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 1000,
                                })
                            }}
                            className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                            <i className='bx bxs-download mr-2'></i>
                            Xuất dữ liệu
                        </button>
                        <button
                            className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mb-3"}>
                            <i className='bx bxs-cloud-upload mr-2'></i>
                            Nhập dữ liệu
                        </button>
                    </div>
                    <div className={"z-50 mb-3 md:flex block flex-wrap w-full"}>
                        <button
                            onClick={() => {
                                startFilter()
                            }}
                            className={"h-[2.5rem] w-full md:w-auto bg-blue-900 p-2 text-white rounded px-4 hover:bg-blue-800 transition-all"}>
                            Lọc
                        </button>
                    </div>
                    <div className={"flex flex-row flex-wrap justify-between mb-3"}>

                        <Paginate config={config} setConfig={setConfig}/>
                        <div className={"flex items-center text-xl md:text-sm "}>
                            <span className={"mr-2 "}>Hiển thị: </span>
                            <Select
                                onChange={(r) => {
                                    setConfig({...config, perPage: r.value})
                                }}
                                className={"text-sm p-0 m-0 z-[99]"}
                                defaultValue={perPageSelect[0]}
                                placeholder={""}
                                options={perPageSelect}/>
                            <span className={"ml-2 text-xl md:text-sm"}>hàng
                        ( Từ hàng
                            <span className={"font-bold mx-1"}>
                                {config.startRecord}
                            </span>
                            đến hàng
                            <span className={"font-bold mx-1"}>
                            {config.endRecord}
                            </span>
                            trên tổng số
                            <span className={"font-bold mx-1"}>
                            {config.totalRecord}
                            </span>
                            hàng)
                        </span>
                        </div>

                    </div>
                    <div className={"max-h-[65vh] overflow-auto"}>
                        <table className={"table border shadow-lg min-w-max w-full"}
                               style={{borderCollapse: "separate", borderSpacing: 0}}>
                            <thead>
                            <tr>
                                <HeadTable label={'Trạng thái điểm danh'}
                                           style={{position: "sticky", zIndex: "30", left: 0}}/>
                                <HeadTable label={'Người điểm danh'}
                                           style={{position: "sticky", zIndex: "30", left: "10rem"}}/>
                                <HeadTable style={{textAlign: "center"}} label={'Lỗi điểm danh muộn'}/>

                                <HeadTable style={{textAlign: "center"}} label={"Ngày tháng năm buổi học"}/>
                                <HeadTable style={{textAlign: "center"}} label={"Thứ"}/>
                                <HeadTable style={{textAlign: "center"}} label={'Lớp'}/>
                                <HeadTable label={'Học sinh tham gia'}/>
                                <HeadTable style={{textAlign: "center"}} label={'Hành động'}/>
                            </tr>
                            </thead>
                            {!fetching ?
                                <tbody>
                                {studyLogs.map((log, key) =>
                                    <tr key={key}>
                                        <AcceptedStatusCol
                                            onClick={() => {
                                                showDetail({id: log.id})
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                fontWeight: "bold",
                                                color: "green",
                                                position: "sticky",
                                                zIndex: "20",
                                                left: 0,
                                                width: "20px !important"
                                            }}
                                            status={log.status}
                                            user={log.process?.accepted_user}
                                            time={log.process?.accepted_time}
                                        />
                                        <UserInfoCol
                                            style={{
                                                position:"sticky",
                                                zIndex: "30", left: "10rem"
                                            }}
                                            username={log.userId || key}
                                            avatar={log.userAvatar || 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'}
                                            name={log.userFullName || ''}
                                            address={''}
                                            entity={log.userType}
                                            timestamp={log.created_at}
                                        />
                                        <LateCol value={log.late}/>

                                        <TextCol style={{textAlign: "center"}} value={log.time}/>
                                        <TextCol
                                            style={{textAlign: "center"}}
                                            value={'Thứ 5'}
                                        />

                                        <TextCol
                                            style={{textAlign: "center"}}
                                            value={log.classroomUuid + " : " + log.classroomName}
                                        />
                                        <MultiUserInfoCol
                                            entity={'student'}
                                            users={log.studentsList || []}
                                        />

                                        <ActionCol
                                            confirmDelete={confirmDelete}
                                            entity={'study-log'}
                                            id={log.id || key}
                                        />
                                    </tr>
                                )}
                                </tbody> :
                                null}
                        </table>
                        {studyLogs.length === 0 ?
                            <EmptyItem/>
                            : null}
                    </div>

                    {fetching ? <FetchingAnimation/> : null}
                </div>
            </MainLayout>
        </div>
    )
}
export default StudyLogList