const CalendarCol = ({calendars}) => {
    console.log(calendars)
    return (
        <td className={"group cursor-pointer relative transition-all duration-500 bg-white shadow-lg p-2 px-3 border-[#dee2e6] border-[0.2px] min-w-[10rem]"}>
            {calendars !== undefined ?
                <div className={"group-hover:block hidden absolute z-50 top-[-2.5rem] left-0 border bg-white shadow w-[15rem]"}>
                    <div className={"p-2 text-xs"}>
                        {calendars.map((calendar) =>
                            <div>
                                {calendar.week_day} : {calendar.start} - {calendar.end}
                            </div>
                        )}
                    </div>
                </div>
                : null
            }
            <img className={"w-[2.5rem] m-auto "}
                 src={"https://png.pngtree.com/element_our/png_detail/20190103/vector-calendar-icon-png_308701.jpg"}/>
        </td>
    )
}
export default CalendarCol