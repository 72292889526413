import MainLayout from "../../layouts/MainLayout";
import {useContext, useState} from "react";
import {AppContext} from "../../AppContext";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import AvatarInput from "../../components/inputs/AvatarInput";

const RegisterHostScreen = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const [data, setData] = useState({})
    const register = () => {
        app.authService.registerHost({data: data}).then((r) => {
            if (r) {
                nav('/login')
            }
        })
    }
    return (
        <div className={"flex items-center justify-center px-5 h-[100vh] w-full"}>
            <div className={"max-w-[25rem] w-full text-center"}>
                <div className={"text-[2rem] text-blue-900 font-bold mb-3 capitalize"}>Đăng ký tài khoản</div>
                <div className={"p-5 bg-white rounded border py-10"}>
                    <AvatarInput
                        name={'avatar'}
                        label={false}
                        data={data}
                        setData={setData}
                    />
                    <div className="mb-6">
                        <input type="text" id="name"
                               value={data.name || ''}
                               onChange={(r) => setData({...data, name: r.target.value})}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 hohobg-gray-700 hohoborder-gray-600"
                               placeholder="Họ và tên" required/>
                    </div>
                    <div className="mb-6">
                        <input type="text" id="username"
                               value={data.username || ''}
                               onChange={(r) => setData({...data, username: r.target.value})}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 hohobg-gray-700 hohoborder-gray-600"
                               placeholder="Username" required/>
                    </div>
                    <div className="mb-6">
                        <input type="password" id="password"
                               value={data.password || ''}
                               onChange={(r) => setData({...data, password: r.target.value})}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder={"Mật khẩu"}
                               required/>
                    </div>
                    <div className="mb-6">
                        <input type="comfirm_password" id="password_confirmation"
                               value={data.password_confirmation || ''}
                               onChange={(r) => setData({...data, password_confirmation: r.target.value})}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               required
                               placeholder={"Xác nhận mật khẩu"}
                        />
                    </div>
                    <button type="button"
                            onClick={() => {
                                register()
                            }}
                            className={"bg-blue-900 text-white p-2 rounded hover:bg-blue-800 transition-all w-full"}>
                        Đăng ký
                    </button>
                    <div className={"pt-5"}>
                        <span className={"mr-1 text-sm"}>Đã có tài khoản?</span>
                        <a href={"/login"} className={"text-sm text-blue-900 cursor-pointer"}>Đăng nhập</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterHostScreen