import MainLayout from "../../layouts/MainLayout";
import {useContext, useState} from "react";
import FetchingAnimation from "../../components/FetchingAnimation";
import TextInput from "../../components/inputs/TextInput";
import Breadcrumbs from "../../components/Breadcrumbs";
import SelectInput from "../../components/inputs/SelectInput";
import TextAreaCol from "../../components/table/TextAreaCol";
import SwitchInput from "../../components/inputs/SwitchInput";
import {redirect, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../AppContext";

const CustomFieldsCreateScreen = () => {
    const app = useContext(AppContext)
    const nav = useNavigate()
    const {id} = useParams()
    const [init, setInit] = useState({
        initType: [
            {
                label: "Text",
                value: "Text"
            },
            {
                label: "Date",
                value: "date"
            },
            {
                label: "TextArea",
                value: "textarea"
            },
            {
                label: "Number",
                value: "number"
            },
        ],
        initEntity: [
            {
                label: "Học sinh",
                value: "student"
            },
            {
                label: "Lớp học",
                value: "classroom"
            },
            {
                label: "Giáo viên",
                value: "teacher"
            },
            {
                label: "Nhân viên",
                value: "account"
            },
            {
                label: "Trợ giảng",
                value: "supporter"
            },
        ]
    })
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({
        required: 1
    })
    const uploadCustomFields = () => {
        if (!id) {
            console.log(data)
            app.settingService.createNewCustomFields({postData: data, setFetching: setFetching}).then((r) => {
                if (r) {
                    nav("/setting")
                }
            })
        } else {

        }
    }
    return (
        <MainLayout>
            {!fetching ?
                <div>
                    <Breadcrumbs
                        parent={
                            {
                                label: "Lớp học",
                                link: "/classroom/list"
                            }
                        }
                        label={"Thêm thuộc tính"}
                    />
                    <div className={"flex flex-wrap"}>
                        <div className={"md:basis-3/4 basis-full bg-gray-50 rounded p-2"}>
                            <div className={"flex flex-wrap"}>
                                <div className={"md:basis-1/2 basis-full mb-3"}>
                                    <TextInput
                                        label={"Thuộc tính"}
                                        data={data}
                                        setData={setData}
                                        placeholder={"Tên thuộc tính"}
                                        name={"label"}
                                    />
                                </div>
                                <div className={"md:basis-1/2 basis-full mb-3"}>
                                    <TextInput
                                        label={"Mã thuộc tính"}
                                        data={data}
                                        setData={setData}
                                        placeholder={"Mã thuộc tính"}
                                        name={"key"}
                                    />
                                </div>
                                <div className={"md:basis-1/2 basis-full mb-3"}>
                                    <SelectInput
                                        label={"Kiểu thuộc tính"}
                                        data={data}
                                        setData={setData}
                                        placeholder={"Mã thuộc tính"}
                                        name={"type"}
                                        options={init.initType || []}
                                        nullable={true}
                                    />
                                </div>
                                <div className={"md:basis-1/2 basis-full mb-3"}>
                                    <SelectInput
                                        label={"Module chính"}
                                        data={data}
                                        setData={setData}
                                        name={"entity"}
                                        options={init.initEntity || []}
                                        nullable={true}
                                    />
                                </div>
                                <div className={"md:basis-1/2 basis-full mb-3"}>
                                    <SwitchInput
                                        label={"Bắt buộc"}
                                        data={data || 0}
                                        setData={setData}
                                        name={"required"}
                                    />
                                </div>
                                <div className={"md:basis-1/2 basis-full mb-3"}>
                                    <SwitchInput
                                        label={"Hiển thị trên bảng"}
                                        data={data || 0}
                                        setData={setData}
                                        name={"is_show_col"}
                                    />
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    uploadCustomFields()
                                }}
                                className={"my-2 px-2"}>
                                <button className={"p-2 bg-blue-900 rounded text-white"}>
                                    Thêm mới thuộc tính
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                : <FetchingAnimation/>}
        </MainLayout>
    )
}
export default CustomFieldsCreateScreen