import axios from './globalApi'
import {appVendor} from "../config";
import {toast} from "react-toastify";

export class ClassroomServices {
    _header
    _baseUrl = appVendor.base_url

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    }

    async listClassrooms({setClassrooms, setFetching, postData, config, setConfig}) {
        setFetching(true)
        try {
            const classrooms = await (await axios.post(this._baseUrl + "/classroom/list", postData, this._header))
            setClassrooms(classrooms.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async initForClassroom({setInit, setFetching}) {
        setFetching(true)
        try {
            const init = await (await axios.get(this._baseUrl + "/classroom/init", this._header))
            setInit(init.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async getClassroomById({id, setData, setFetching}) {
        setFetching(true)
        try {
            const classroom = await (await axios.get(this._baseUrl + "/classroom/detail/" + id, this._header))
            setData(classroom.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async getClassroomFullById({id, setData, setFetching}) {
        setFetching(true)
        try {
            const classroom = await (await axios.get(this._baseUrl + "/classroom/detail/" + id + "/full", this._header))
            setData(classroom.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }

    }

    async createNewClassroom({postData, setFetching}) {
        setFetching(true)
        try {
            const rs = await (await axios.post(this._baseUrl + "/classroom/store", postData, this._header))
            toast.success("Thêm mới thành công")
            return rs
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async updateClassroom({id, postData, setFetching}) {
        setFetching(true)
        try {
            const classrooms = await (await axios.put(this._baseUrl + "/classroom/update/" + id, postData, this._header))
            toast.success("Cập nhât thành công")
            return true

        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

}