import MainLayout from "../../layouts/MainLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import FetchingAnimation from "../../components/FetchingAnimation";
import {AppContext} from "../../AppContext";
import HeadTable from "../../components/table/HeadTable";
import AcceptedStatusCol from "../../components/table/AcceptedStatusCol";
import UserInfoCol from "../../components/table/UserInfoCol";
import LateCol from "../../components/table/LateCol";
import TextCol from "../../components/table/TextCol";
import MultiUserInfoCol from "../../components/table/MultiUserInfoCol";
import ActionCol from "../../components/table/ActionCol";
import EmptyItem from "../../components/EmptyItem";
import CheckCol from "../../components/table/CheckCol";

const TeacherDetailScreen = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const {id} = useParams()
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({})
    const switchToEdit = () => {
        nav("/teacher/edit/" + id)
    }
    useEffect(() => {
        app.teacherService.getTeacherById({
            id: id,
            setData: setData,
            setFetching: setFetching
        }).then()
    }, [])
    return (
        <MainLayout>
            <Breadcrumbs
                label={"Thông tin giáo viên"}
                parent={{
                    label: "Danh sách giáo viên",
                    link: "/student/list"
                }}
            />
            {!fetching ?
                <div>
                    <div className={"flex flex-wrap p-2 h-full"}>
                        <div className={"md:basis-1/6 basis-full p-2"}>
                            <div className={"w-full relative group "}>
                                <img src={data.avatar || ''} className={"w-full shadow rounded-2xl"}/>
                                <div
                                    onClick={switchToEdit}
                                    className={"rounded transition-all duration-500 absolute bottom-0 cursor-pointer right-0 group-hover:opacity-80 opacity-0 p-5 shadow-lg rounded-br-2xl bg-white"}>
                                    <i className={"bx bx-edit text-2xl"}></i>
                                </div>
                            </div>
                        </div>
                        <div className={"md:basis-1/3 basis-full p-2"}>
                            <div className={"border p-5 bg-gray-50 rounded-2xl h-full"}>
                                <div className={"text-blue-900 text-2xl font-bold flex items-center"}>
                                    <span>{data.name || ''}</span>
                                    {data.gender !== undefined ?
                                        <span className={"ml-2"}>
                                        {data.gender.value === 0
                                            ?
                                            <img className={"w-[1rem]"}
                                                 src={"https://icons.iconarchive.com/icons/custom-icon-design/flatastic-7/512/Male-icon.png"}
                                                 alt={""}/>
                                            :
                                            <img className={"w-[1rem]"}
                                                 src={"https://icons.iconarchive.com/icons/custom-icon-design/flatastic-7/256/Female-icon.png"}
                                                 alt={""}/>
                                        }
                                    </span>
                                        : null}
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-map'></i>
                                    <span className={"ml-2"}>{data.address || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-barcode'></i>
                                    <span className={"ml-2"}>Mã Giáo Viên: {data.uuid || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-user'></i>
                                    <span className={"ml-2"}>{data.username || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-envelope'></i>
                                    <span className={"ml-2"}>{data.email || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-phone'></i>
                                    <span className={"ml-2"}>{data.phone || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <i className='bx bxs-cake'></i>
                                    <span className={"ml-2"}>{data.birthday || ''}</span>
                                </div>
                                <div className={"mt-2 text-gray-600"}>
                                    <Link to={"/student/edit/"+id}>
                                        <i className='bx bxs-edit'></i> Sửa thông tin giáo viên
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={"md:basis-1/2 basis-full p-2"}>
                            {data.extra_information !== undefined ?
                                <div className={"border p-5 bg-gray-50 rounded-2xl h-full"}>
                                    <div className={"text-blue-900 font-bold mb-3"}>Thông tin thêm</div>
                                    <div className={"flex flex-wrap"}>
                                        {data.extra_information.map((item, key) =>
                                            <div key={key} className={"md:basis-1/2 flex items-center mb-2"}>
                                                <i className='bx bxs-book-content mr-2'></i>
                                                <span className={"font-semibold mr-2"}>{item.label}:</span>
                                                <span>{item.value}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className={"p-2 font-bold my-3 text-green-500 text-2xl uppercase"}>Lịch sử ca học</div>
                    <div className={"max-h-[65vh] overflow-auto"}>
                        <table className={"table border shadow-lg min-w-max w-full"}
                               style={{borderCollapse: "separate", borderSpacing: 0}}>
                            <thead>
                            <tr>
                                <HeadTable label={'Thời gian ca học'}
                                           style={{position: "sticky", zIndex: "30", left: 0, textAlign:"center"}}/>
                                <HeadTable style={{textAlign: "center"}} label={"Thứ"}/>
                                <HeadTable style={{textAlign: "center"}} label={'Thời lượng'}/>
                                <HeadTable style={{textAlign: "center"}} label={'Phòng học'}/>
                                <HeadTable style={{textAlign: "center"}} label={'Lớp học'}/>
                                <HeadTable style={{textAlign: "center"}} label={'Đã duyệt'}/>
                                {/*<HeadTable style={{textAlign: "center"}} label={'Lớp'}/>*/}
                            </tr>
                            </thead>
                            {!fetching ?
                                <tbody>
                                {data.working_shifts?.map((shift, key) =>
                                    <tr key={key}>
                                        <TextCol
                                            style={{position: "sticky", zIndex: "30", left: 0, textAlign:'center'}}
                                            value={shift.selected_day+" "+shift.start+" "+shift.end}
                                        />
                                        <TextCol
                                            style={{textAlign:"center"}}
                                            value={shift?.calendar?.week_day}
                                        />
                                        <TextCol
                                            style={{textAlign:"center"}}
                                            value={shift.duration + " phút"}
                                        />
                                        <TextCol
                                            style={{textAlign:"center"}}
                                            value={shift.room}
                                        />
                                        <TextCol
                                            style={{textAlign:"center"}}
                                            value={shift.classroom_name}
                                        />
                                        <CheckCol
                                            value={shift.study_log_status}
                                            trueValue={1}
                                        />
                                    </tr>
                                )}
                                </tbody> :
                                null}
                        </table>
                    </div>
                </div>
                : <FetchingAnimation/>}
        </MainLayout>
    )
}
export default TeacherDetailScreen