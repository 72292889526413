let base_url = "";
if (window.location.port === "3000") {
    base_url = "https://bsm.com.vn/api"
} else {
    base_url = "https://bsmlight.com/api"
}
export const appVendor = {
    name: "Bsm",
    base_url: base_url,
    version: "0.0.1"
}