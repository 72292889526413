import MainLayout from "../../layouts/MainLayout";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import TextFilter from "../../components/filter/TextFilter";
import SelectFilter from "../../components/filter/SelectFilter";
import Paginate from "../../components/table/Paginate";
import Select from "react-select";
import HeadTable from "../../components/table/HeadTable";
import TextCol from "../../components/table/TextCol";
import UserInfoCol from "../../components/table/UserInfoCol";
import IconLinkCol from "../../components/table/IconLinkCol";
import ActionCol from "../../components/table/ActionCol";
import EmptyItem from "../../components/EmptyItem";
import FetchingAnimation from "../../components/FetchingAnimation";
import MultiUserInfoCol from "../../components/table/MultiUserInfoCol";
import Calendar from "../../components/Calendar";
import CalendarCol from "../../components/table/CalendarCol";
import EndDrawer from "../Drawer/EndDrawer";
import StudyLogTab from "../StudyLog/StudyLogTab";
import ClassroomTabScreen from "./ClassroomTabScreen";

const ClassroomListScreen = () => {

    const app = useContext(AppContext)
    const [fetching, setFetching] = useState(true)
    const perPageSelect = [
        {value: "10", label: "10"},
        {value: "20", label: "20"},
        {value: "50", label: "50"},
        {value: "100", label: "100"},
        {value: "200", label: "200"},
        {value: "500", label: "500"},
        {value: "1000", label: "1000"},
        {value: "-1", label: "Tất cả"},
    ]
    const [filter, setFilter] = useState({})
    const [classrooms, setClassrooms] = useState([])
    const [config, setConfig] = useState({
        perPage: 10,
        currentPage: 1,
        totalPage: 10,
        totalRecord: 0,
        startRecord: 1,
        endRecord: 10,
    })
    const confirmDelete = (id) => {
        // app.deleteStudent({id: id, setFetching: setFetching, fetchData: fetchData}).then()
    }
    useEffect(()=>{
        startFilter()
    },[filter])
    const fetchData = () => {
        const params = {
            postData: {
                limit: config.perPage,
                offset: (config.currentPage - 1) * config.perPage,
                conditions: transformFilterObject(filter)
            },
            setClassrooms: setClassrooms,
            config: config,
            setConfig: setConfig,
            setFetching: setFetching
        }
        app.classroomService.listClassrooms(params).then()
    }

    function transformFilterObject(inputObject) {
        const transformedObject = {};

        for (const key in inputObject) {
            if (inputObject[key] && typeof inputObject[key] === 'object') {
                const {operator, value} = inputObject[key];
                transformedObject[`${key}:${operator}`] = value;
            }
        }

        return transformedObject;
    }

    const startFilter = () => {
        fetchData()
    }
    useEffect(() => {
        fetchData()
    }, [config.currentPage])

    useEffect(() => {
        if (config.currentPage !== 1) {
            setConfig({...config, currentPage: 1})
        } else {
            fetchData()
        }

    }, [config.perPage])
    useEffect(() => {
        fetchData()
    }, [1])

    const [showDrawer, setShowDrawer] = useState(false)
    const [drawer, setDrawer] = useState(<ClassroomTabScreen/>)
    const showDetail = ({id}) => {
        setDrawer(<ClassroomTabScreen id={id}/>)
        setShowDrawer(true)
    }
    return (
        <div>
            <EndDrawer show={showDrawer} setShow={setShowDrawer}>
                {drawer}
            </EndDrawer>
            <MainLayout>
                <div className={"p-3"}>
                    <div className={"text-blue-900 font-bold text-2xl mb-3"}>
                        Danh sách lớp học
                    </div>
                    <div className={"mb-3 flex flex-wrap"}>
                        <Link to={"/classroom/create"}
                              className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                            <i className='bx bxs-plus-circle mr-2'></i>
                            Thêm lớp học mới
                        </Link>
                        <button
                            onClick={() => {
                                toast.success("Xuất dữ liệu thành công", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 1000,
                                })
                            }}
                            className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                            <i className='bx bxs-download mr-2'></i>
                            Xuất dữ liệu
                        </button>
                        <button
                            className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mb-3"}>
                            <i className='bx bxs-cloud-upload mr-2'></i>
                            Nhập dữ liệu
                        </button>
                    </div>
                    <div className={"z-50 mb-3 md:flex block flex-wrap w-full"}>
                        <TextFilter
                            setFilter={setFilter}
                            filter={filter}
                            name={"uuid"}
                            icon={<i className='bx bxs-user-circle'></i>}
                            placeholder={"Mã lớp học"}
                        />
                        <TextFilter
                            setFilter={setFilter}
                            filter={filter}
                            name={"classroom_name"}
                            icon={<i className='bx bxs-user-circle'></i>}
                            placeholder={"Tên lớp học"}
                        />
                        <SelectFilter
                            setFilter={setFilter}
                            filter={filter}
                            options={classrooms.initStudents||[]}
                            name={"student_id"}
                            operator={'handler'}
                            icon={<i className='bx bxs-user-circle'></i>}
                            placeholder={"Học sinh"}
                        />
                        <button
                            onClick={() => {
                                startFilter()
                            }}
                            className={"h-[2.5rem] w-full md:w-auto bg-blue-900 p-2 text-white rounded px-4 hover:bg-blue-800 transition-all"}>
                            Lọc
                        </button>
                        <button
                            onClick={() => {
                                setFilter({})
                                fetchData()
                            }}
                            className={"ml-2 h-[2.5rem] w-full md:w-auto bg-blue-900 p-2 text-white rounded px-4 hover:bg-blue-800 transition-all"}>
                            Reset
                        </button>
                    </div>
                    <div className={"flex flex-row flex-wrap justify-between mb-3"}>

                        <Paginate config={config} setConfig={setConfig}/>
                        <div className={"flex items-center text-xl md:text-sm "}>
                            <span className={"mr-2 "}>Hiển thị: </span>
                            <Select
                                onChange={(r) => {
                                    setConfig({...config, perPage: r.value})
                                }}
                                className={"text-sm p-0 m-0 z-[99]"}
                                defaultValue={perPageSelect[0]}
                                placeholder={""}
                                options={perPageSelect}/>
                            <span className={"ml-2 text-xl md:text-sm"}>hàng
                        ( Từ hàng
                            <span className={"font-bold mx-1"}>
                                {config.startRecord}
                            </span>
                            đến hàng
                            <span className={"font-bold mx-1"}>
                            {config.endRecord}
                            </span>
                            trên tổng số
                            <span className={"font-bold mx-1"}>
                            {config.totalRecord}
                            </span>
                            hàng)
                        </span>
                        </div>

                    </div>
                    <div className={"max-h-[65vh] overflow-auto"}>
                        <table className={"table border shadow-lg min-w-max w-full"}
                               style={{borderCollapse: "separate", borderSpacing: 0}}>
                            <thead>
                            <tr>
                                <HeadTable label={'Class ID'} style={{zIndex: "30", left: 0}}/>
                                <HeadTable label={'Tên lớp'} style={{position: "sticky", zIndex: "30", left: "10rem"}}/>
                                <HeadTable label={'Nhóm thông báo'}/>
                                <HeadTable label={'Nhóm zalo phụ huynh'}/>
                                <HeadTable label={'Nhóm zalo lớp học'}/>
                                <HeadTable label={'Tên giáo trình sử dụng'}/>
                                <HeadTable label={'Lịch học'}/>
                                <HeadTable label={'Học sinh'}/>
                                <HeadTable label={'Cập nhật lịch học gần nhất'}/>
                                <HeadTable label={'Cập nhật GV,TG gần nhất'}/>
                                <HeadTable label={'Cập nhật thẻ học gần nhất'}/>
                                <HeadTable label={'Hành động'}/>
                            </tr>
                            </thead>
                            {!fetching ?
                                <tbody>
                                {classrooms.classrooms?.map((classroom, key) =>
                                    <tr key={key}>
                                        <TextCol
                                            onClick={() => {
                                                showDetail({id: classroom.id})
                                            }}
                                            value={classroom.uuid || ''}
                                            style={{position: "sticky", zIndex: "20", left: 0}}/>
                                        <UserInfoCol
                                            onClick={() => {
                                                showDetail({id: classroom.id})
                                            }}
                                            address={classroom.uuid}
                                            name={classroom.classroom_name}
                                            username={classroom.id}
                                            entity={"classroom"}
                                            avatar={classroom.thumbnail}
                                            style={{position: "sticky", zIndex: "20", left: "10rem"}}
                                        />
                                        <IconLinkCol
                                            img={"https://phukienre.com.vn/wp-content/uploads/2022/05/zalo-icon.png"}
                                            value={classroom.lesson_social_group}
                                            style={{textAlign: "center"}}
                                        />
                                        <IconLinkCol
                                            img={"https://phukienre.com.vn/wp-content/uploads/2022/05/zalo-icon.png"}
                                            value={classroom.parent_group}
                                            style={{textAlign: "center"}}
                                        />
                                        <IconLinkCol
                                            img={"https://phukienre.com.vn/wp-content/uploads/2022/05/zalo-icon.png"}
                                            value={classroom.student_group}
                                            style={{textAlign: "center"}}
                                        />
                                        <TextCol
                                            value={classroom.textbook_name}
                                            style={{textAlign: "center"}}
                                        />
                                        <CalendarCol
                                            calendars={classroom.calendar || []}
                                        />
                                        <MultiUserInfoCol
                                            entity={'student'}
                                            users={classroom.students || []}
                                        />
                                        <TextCol
                                            value={classroom.latest_update_calendar}
                                            style={{textAlign: "center"}}
                                        />
                                        <TextCol
                                            value={classroom.latest_update_relation_user}
                                            style={{textAlign: "center"}}
                                        />
                                        <TextCol
                                            value={classroom.latest_update_relation_card}
                                            style={{textAlign: "center"}}
                                        />
                                        <ActionCol
                                            confirmDelete={confirmDelete}
                                            entity={'classroom'}
                                            id={classroom.id || key}
                                        />
                                    </tr>
                                )}
                                </tbody> :
                                null}
                        </table>
                        {classrooms.length === 0 ?
                            <EmptyItem/>
                            : null}
                    </div>
                    {fetching ? <FetchingAnimation/> : null}
                </div>
            </MainLayout>
        </div>
    )
}
export default ClassroomListScreen