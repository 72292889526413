import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class AuthServices {

    _base_url
    _auth_header

    constructor(token) {
        this._auth_header = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        this._base_url = appVendor.base_url
    }

    async loginByUsernameAndPassword({postData}) {
        try {
            return await (await axios.post(this._base_url + "/login", postData, {}))
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    async getAuthProvider({setAuthBranch}) {

    }

    async logout() {
        localStorage.removeItem("token")
        localStorage.removeItem("branch")
        window.location.reload()
    }

    async checkLogin({setStatus}) {
        try {
            await (await axios.post(this._base_url + "/check_auth", {scope: window.location.pathname}, this._auth_header))
            setStatus(200)
        } catch (e) {
            setStatus(e.response.status)
        }
    }

    async registerHost({data}) {
        try {
            const hostResponse = await (await axios.post(this._base_url + "/register", data, this._auth_header))
            toast.success("Tạo tài khoản thành công, mời đăng nhập")
            return true
        } catch (e) {
            toast(e.response?.data?.message)
        }
    }

    async sendOtp({data, setFetching}) {
        setFetching(true)
        try {
            return await (await axios.post(this._base_url + "/send_otp", data))
        } catch (e) {
            toast.warn(e.response?.data?.message)
        } finally {
            setFetching(false)
        }
    }

    async verifyOtp({data, setFetching}) {
        setFetching(true)
        try {
            return await (await axios.post(this._base_url + "/verify_otp", data))
        } catch (e) {
            toast.warn(e.response?.data?.message)
        } finally {
            setFetching(false)
        }
    }

    async verifiedViaPhone({data, setFetching}) {
        setFetching(true)
        try {
            return await (await axios.post(this._base_url + "/verified_via_phone", data))
        } catch (e) {
            toast.warn(e.response?.data?.message)
        } finally {
            setFetching(false)
        }
    }

    async getAllUserForSelect({setAllUsers}) {
        try {
            const usersResponses = await (await axios.get(this._base_url + "/system/users", this._auth_header))
            setAllUsers(usersResponses.data)
        } catch (e) {
            toast.warn(e.response?.data?.message)
        }
    }
}