import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";

export class BranchService {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async getListBranch({setBranches, setFetching}) {
        setFetching(true)
        try {
            const branches = await (await axios.get(this._base_url + "/branch/list", this._header))
            setBranches(branches.data)
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async createBranch({postData, setFetching}) {
        try {
            await (await axios.post(this._base_url + "/branch/create", postData, this._header))
            return true
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async joinBranch({postData, setFetching}) {
        try {
            await (await axios.post(this._base_url + "/branch/join", postData, this._header))
            return true
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

}