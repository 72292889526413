const RenewFilter = ({filter, setFilter, type, color, min, max}) => {
    return (
        <button
            onClick={() => {
                setFilter({
                    ...filter, available_lessons_min: {
                        key: 'available_lessons',
                        operator: 'gt',
                        value: min
                    },
                    available_lessons_max:
                        {
                            key: 'available_lessons',
                            operator: 'le',
                            value: max
                        }
                })
            }}
            className={" md:w-auto w-full text-white p-2 rounded-xl mb-3 mr-2 px-5 transition-all "+color+ (filter.available_lessons_min?.value === min ? " border-blue-900 font-bold border-2 opacity-100 " : " opacity-70 ")}>
            Renew {type}
        </button>
    )
}
export default RenewFilter