const InternalFeedbackComment = ({comment}) => {
    return (
        <div
            className={"hover:bg-gray-100 cursor-pointer transition-all log bg-white border-b  p-1 py-2 rounded"}>
            <div className={"interactive_user"}>
                <div className={"flex items-center"}>
                    <img
                        className={"w-[2.5rem] h-[2.5rem] rounded-full border mr-2"}
                        src={comment.user_avatar}/>
                    <div className={"text-sm"}>
                        <div className={'font-bold'}>{comment.user_name}</div>
                        <div className={"text-sm text-gray-400"}> {comment.time}</div>

                    </div>
                </div>
            </div>
            <div className={"pl-[3rem] mt-2"}>
                <div dangerouslySetInnerHTML={{__html:comment.text_content}}></div>
                <div className={"border bg-blue-50 p-2 text-sm"}>
                    <div className={"mb-2"}>Điểm feedback:  <span className={"text-xl p-1 text-green-800 font-bold rounded"}>{comment.action_information?.feedback_score}</span></div>
                    <div>Thời gian Feedback: {comment.action_information?.feedback_time}</div>
                </div>
            </div>
        </div>
    )
}
export default InternalFeedbackComment