import React, {useState} from 'react';

const StarRating = ({label, data, setData, name, stars, description, required}) => {
    const [hoverRating, setHoverRating] = useState(0);

    const handleRating = (star) => {
        setData({...data, [name]: star});
    };

    const handleMouseEnter = (star) => {
        setHoverRating(star);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };
    let numbers = [];
    const handleMakeStartArray = () => {
        for (let i = 1; i <= stars; i++) {
            numbers.push(i);
        }
        return numbers
    }

    return (
        <div className={"px-2"}>
            <div className={"text-sm font-bold"}>
                {label ?? name}
                {required ? <span className={"text-red-600"}>*</span> : null}
            </div>
            <div className={"text-sm font-bold mb-3"}>{description}</div>
            <div className="flex justify-around text-center">
                {handleMakeStartArray().map((star) => (
                    <span
                        key={star}
                        className="text-2xl cursor-pointer"
                        onClick={() => handleRating(star)}
                        onMouseEnter={() => handleMouseEnter(star)}
                        onMouseLeave={() => handleMouseLeave()}
                    >
                        <div className={"text-sm"}>{star}</div>
                    <i className={`transition-all text-green-600 bx ${star <= (hoverRating || data[name]) ? 'bxs-circle' : 'bx-circle'}`}/>

                </span>
                ))}
            </div>
        </div>
    );
};

export default StarRating;
