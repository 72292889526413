import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import Error403Screen from "../Errors/Error403Screen";
import {Navigate, useNavigate} from "react-router-dom";
import Error500Screen from "../Errors/Error500Screen";
import BranchList from "../Branch/BranchList";

const AuthMiddleware = ({children}) => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const [status, setStatus] = useState()

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            window.location.href = "/login"
        }
        app.getSystemInfo().then((r) => {
            app.setAcl(r.data)
            if(r.data?.permissions?.includes("branch list") && r.data?.user_info?.branch===null){
                if(window.location.pathname !== "/select_branch"){
                    window.location.replace("/select_branch")
                }
            }
        })

    }, []);

    if (status === 401 || status === 400) {
        window.location.href = "/login"
    }
    if (status === 403) {
        return <Error403Screen/>
    }
    if (status === 500) {
        return <Error500Screen/>
    }
    if(status === 203){
        return <BranchList/>
    }
    return (
        <div>
            {children}
        </div>
    )

}
export default AuthMiddleware