import PopupLayout from "../../layouts/PopupLayout";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import TextInput from "../../components/inputs/TextInput";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const BranchList = () => {
    const app = useContext(AppContext)
    const nav = useNavigate()
    const [data, setData] = useState({})
    const [branches, setBranches] = useState([])
    const [fetching, setFetching] = useState(false)
    const listBranch = () => {
        app.branchService.getListBranch({
            setBranches: setBranches,
            setFetching: setFetching
        }).then()
    }

    const addBranch = () => {
        app.branchService.createBranch({
            postData: data,
            setFetching: setFetching
        }).then((r) => {
            if (r) {
                toast("Thêm thành công !")
                setBranches([...branches, data])
                setData({})
            }
        })
    }

    const joinBranch = ({code}) => {
        app.branchService.joinBranch({
            postData: {
                code: code
            },
            setFetching: setFetching
        }).then((r) => {
            if (r) {
                window.location.href = "/"
            }
        })
    }

    useEffect(() => {
        listBranch()
    }, []);

    return (
        <PopupLayout>
            <div className={"w-full h-[100vh] flex justify-center items-center"}>
                <div>
                    <div
                        className={" font-bold mb-10 text-3xl uppercase text-blue-900 flex justify-center items-center"}>
                        <i onClick={() => nav("/login")}
                           className='bx bx-log-out-circle mr-2 text-red-900 cursor-pointer'></i>
                        Chọn Chi nhánh

                    </div>
                    <div className={"flex flex-wrap min-w-[60rem] max-w-[100rem]"}>
                        {branches?.map((branch, key) =>
                            <div className={"basis-1/4 p-1 text-center"} key={key}>
                                <div>
                                    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow">
                                        <a href="#">
                                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">{branch.name}</h5>
                                        </a>
                                        <p className="mb-3 font-normal text-gray-700">{branch.code}</p>
                                        <a href="#"
                                           onClick={() => {
                                               joinBranch({
                                                   code: branch.code
                                               })
                                           }}
                                           className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                                            Truy cập
                                            <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={"mt-10"}>
                        <div className={"flex flex-wrap"}>
                            <div className={"basis-1/3"}>
                                <TextInput
                                    name={'name'}
                                    label={'Tên chi nhánh'}
                                    data={data}
                                    setData={setData}
                                    required={true}
                                />
                            </div>
                            <div className={"basis-1/3"}>
                                <TextInput
                                    name={'code'}
                                    label={'Mã chi nhánh'}
                                    data={data}
                                    setData={setData}
                                    required={true}
                                />
                            </div>
                            <div className={"basis-1/3"}>
                                <div className={"mb-7"}></div>
                                <div
                                    onClick={addBranch}
                                    className={"hover:bg-blue-700 transition-all rounded bg-blue-900 text-white p-2 text-center cursor-pointer"}>
                                    <i className='bx bxs-layer-plus'></i> Thêm chi nhánh
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </PopupLayout>
    );
}

export default BranchList