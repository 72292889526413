import MainLayout from "../../layouts/MainLayout";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import {Link, useParams} from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/inputs/TextInput";
import SelectMultiInput from "../../components/inputs/SelectMultiInput";
import FetchingAnimation from "../../components/FetchingAnimation";
import SelectInput from "../../components/inputs/SelectInput";
import NumberInput from "../../components/inputs/NumberInput";
import TransactionInput from "../Transaction/TransactionAddScreen";
import TransactionAddScreen from "../Transaction/TransactionAddScreen";
import RichTextInput from "../../components/inputs/RichTextInput";
import QuickAddStudent from "../../components/QuickAddStudent";
import QuickAddClassroom from "../../components/QuickAddClassroom";

const CardCreateScreen = () => {
    const app = useContext(AppContext)
    const [readOnlyData, setReadOnlyData] = useState({})
    const [init, setInit] = useState({})
    const [label, setLabel] = useState("")
    const {id} = useParams()
    const [transactionForm, setTransactionForm] = useState(true)
    useEffect(() => {
        if (id) {
            setLabel("Chỉnh sửa thông tin thẻ học")
            app.cardService.getCardById({id, setData, setFetching}).then()
        } else {
            setLabel("Thêm thẻ học mới")
        }
    }, [])
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({
        card_transaction: [],
        card_status: 1
    })
    const uploadCard = () => {
        if (id) {
            app.cardService.updateCard({id: id, postData: data, setFetching: setFetching}).then()
        } else {
            app.cardService.createCard({postData: data, setFetching: setFetching}).then()
        }
    }


    useEffect(() => {
        setData({...data, total_lessons: parseInt(data.bonus_lessons || 0) + parseInt(data.reality_lessons)})
    }, [data.bonus_lessons, data.reality_lessons])


    useEffect(() => {
        setData({
            ...data,
            total_amount: parseInt(data.reality_amount) - parseInt(data.promotion_amount || 0),
            promotion_percent: ((data.promotion_amount || 0) / (data.reality_amount)) * 100,
        })
    }, [data.reality_amount, data.promotion_amount])

    useEffect(() => {
        setData({...data, amount_per_lesson: (parseFloat(data.total_amount) / parseInt(data.total_lessons))})
    }, [data.total_lessons, data.total_amount])

    useEffect(() => {
        app.cardService.initForCard({setInit, setFetching}).then()
    }, [])

    return (
        <MainLayout>
            <div>
                <Breadcrumbs
                    parent={
                        {
                            label: "Thẻ học",
                            link: "/card/list"
                        }
                    }
                    label={label}
                />
                {!fetching ? <div>
                        <div className={"flex flex-wrap h-full transition-all"}>
                            <div className={"lg:w-3/5 px-1 h-full"}>
                                <div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                                        <div className={"text-blue-900 w-full px-2 mb-3 font-bold text-lg"}>Kết nối với học
                                            sinh
                                        </div>
                                        <div className={" w-full mb-3"}>
                                            <SelectInput
                                                options={init.studentInit || []}
                                                name={"student"} label={"Học sinh được thụ hưởng sử dụng"}
                                                placeholder={"Chọn"}
                                                data={data}
                                                setData={setData}
                                                nullable={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"px-2"}>
                                            <QuickAddStudent
                                                data={data}
                                                setData={setData}
                                                name={'student'}
                                            />
                                        </div>
                                    </div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1 mt-2"}>
                                        <div className={"text-blue-900 w-full px-2 mb-3 font-bold text-lg"}>Thông tin thẻ
                                            học
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"classroom_type"}
                                                label={"Ghi chú về LOẠI LỚP mà thẻ học này được sử dụng, theo đơn giá."}
                                                data={data}
                                                setData={setData}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <SelectInput

                                                name={"classroom"} label={"Lớp học"}
                                                data={data}
                                                setData={setData}
                                                options={init.classroomInit || []}
                                                nullable={true}
                                            />
                                            <div className={"p-2"}>
                                                <QuickAddClassroom
                                                    data={data}
                                                    setData={setData}
                                                    name={'classroom'}
                                                />
                                            </div>
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"commitment"} label={"Ghi chú về cam kết đầu ra nếu có."}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={(id ? "md:w-1/4" : "md:w-1/2") + " w-full mb-3"}>
                                            <TextInput
                                                name={"card_pdf"} label={"Link drive PDF đơn đăng ký"}
                                                data={data}
                                                setData={setData}
                                                image={"https://play-lh.googleusercontent.com/t-juVwXA8lDAk8uQ2L6d6K83jpgQoqmK1icB_l9yvhIAQ2QT_1XbRwg5IpY08906qEw=w600-h300-pc0xffffff-pd"}
                                            />
                                        </div>
                                        {id ?
                                            <div className={"md:w-1/4 w-full mb-3"}>
                                                <SelectInput
                                                    options={init.statusInit || []}
                                                    name={"card_status"} label={"Trạng thái thẻ học"} placeholder={"Chọn"}
                                                    data={data}
                                                    setData={setData}
                                                    nullable={true}
                                                />
                                            </div>

                                            : null}
                                    </div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1 mt-2"}>
                                        <div className={"text-blue-900 w-full px-2 mb-3 font-bold text-lg"}>Đăng ký buổi
                                            học
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"reality_lessons"} label={"Số buổi đăng ký gốc"}
                                                data={data}
                                                setData={setData}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"bonus_lessons"} label={"Số buổi được tặng thêm"}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"bonus_note"} label={"Ghi chú lý do tặng"}
                                                data={data}
                                                setData={setData}

                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"total_lessons"} label={"Số buổi thực tế đăng ký"}
                                                data={data}
                                                setData={setData}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"reality_amount"} label={"Học phí gốc"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <NumberInput
                                                name={"promotion_amount"} label={"Ưu đãi (vnđ)"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                            />
                                        </div>
                                        <div className={"md:w-1/6 w-full mb-3"}>
                                            <NumberInput
                                                name={"promotion_percent"}
                                                label={"Ưu đãi (%)"}
                                                data={data}
                                                setData={setData}
                                                suffix={"%"}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"md:w-5/12 w-full mb-3"}>
                                            <NumberInput
                                                name={"total_amount"} label={"Học phí thực tế cần thanh toán"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"md:w-5/12 w-full mb-3"}>
                                            <NumberInput
                                                name={"amount_per_lesson"} label={"Đơn giá mỗi buổi của thẻ học"}
                                                data={data}
                                                setData={setData}
                                                suffix={"đ"}
                                                disable={true}
                                            />
                                        </div>
                                        <div className={"w-full mb-3"}>
                                            <RichTextInput
                                                name={"note"} label={"Ghi chú kế hoạch thanh toán dự kiến"}
                                                data={data}
                                                setData={setData}
                                                height={"200px"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"w-full mb-3 mt-2"}>
                                    {!id ? <button
                                            onClick={uploadCard}
                                            className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Thêm
                                            mới
                                            thẻ học
                                        </button> :
                                        <button
                                            onClick={uploadCard}
                                            className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Cập
                                            nhật thẻ học
                                        </button>
                                    }
                                </div>
                            </div>
                            {id ?
                                <div className={"lg:w-2/5 px-1 h-full"}>
                                    <div>
                                        <div className={"w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                                            <div className={"text-blue-900 w-full px-2 mb-3 font-bold text-lg"}>
                                                Giao dịch với thẻ học
                                            </div>
                                            <div className={"text-sm text-blue-900 px-2"}>* Thêm giao dịch thanh toán số
                                                ngày
                                                học chưa thanh toán, renew cho thẻ học
                                            </div>
                                            <div className={"text-sm text-blue-900 px-2"}>* Giao dịch chỉ được thêm khi đăng
                                                ký
                                                thẻ
                                                học thành công (hoặc chỉnh sửa thẻ học)
                                            </div>
                                            <div className={"text-sm text-blue-900 px-2"}>* Giao dịch khi khởi tạo thành
                                                công
                                                không thể chỉnh sửa nếu không phải Admin
                                            </div>
                                            <div className={"text-sm text-blue-900 px-2"}>* Giao dịch tạo thành công sẽ lưu
                                                vào
                                                sổ quỹ (cashier)
                                            </div>
                                            {init.initTransactions ?
                                                <div className={"px-2"}>
                                                    {init.initTransactions.length > 0 ?
                                                        <div className={"my-3"}>
                                                            <table className={"w-full border"}>
                                                                <thead className={"text-left"}>
                                                                <tr className={"text-sm border"}>
                                                                    <th className={"border p-1"}>Tiêu đề</th>
                                                                    <th className={"border p-1"}>Số tiền</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {data.card_transaction.map((transaction, key) =>
                                                                    <tr className={"text-sm border text-left"}>
                                                                        <td className={"border p-1"}>{transaction.message}</td>
                                                                        <td className={"border p-1"}>{parseInt(((transaction.value) ?? "0")).toLocaleString()} đ</td>
                                                                    </tr>
                                                                )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className={"text-gray-600 text-sm mb-3"}>* Chưa có giao
                                                                dịch
                                                            </div>
                                                        </div>
                                                    }
                                                </div> : null
                                            }

                                        </div>
                                        {id ?
                                            <div className={"w-full border p-2 rounded bg-gray-50 pt-5 pb-1 mt-2"}>
                                                <TransactionAddScreen/>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div> :
                    <FetchingAnimation/>}
            </div>
        </MainLayout>
    )
}
export default CardCreateScreen