import {toast} from "react-toastify";
import axios from './globalApi'
import {appVendor} from "../config";

export class StudentServices {

    _baseUrl
    _header

    constructor(token, baseUrl) {

        this._header = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
        this._baseUrl = appVendor.base_url
    }

    async getListStudent({setStudents, setFetching, config, setConfig, postData}) {
        console.log("postData",postData)
        setFetching(true)
        try {
            const response = await (await axios.post(this._baseUrl + "/student/list", postData, this._header))
            setStudents(response.data)
        } catch (e) {
            toast.error(e.response.message)
        } finally {
            setFetching(false)
        }
    }

    async getStudentCustomFields({setCustomFields}) {
        try {
            const customFields = await (await axios.get(this._baseUrl + "/custom_fields/list/student", this._header))
            setCustomFields(customFields.data)
            console.log(customFields.data)
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    async getStudentById({id, setData, setFetching}) {
        setFetching(true)
        try {
            const student = await (await axios.get(this._baseUrl + "/student/detail/" + id, this._header))
            setData(student.data)
        } catch (e) {
            toast.error(e.response.data.message)
        }
        setFetching(false)
    }

    async createStudent({postData, setFetching}) {

        setFetching(true)
        try {
           return  await (await axios.post(this._baseUrl + "/student/store", postData, this._header))

        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async updateStudent({id, postData, setFetching}) {
        setFetching(true)
        try {
            await (await axios.put(this._baseUrl + "/student/update/" + id, postData, this._header))
            toast.success('Cập nhật thành công')
            window.location.href = "/student/list"
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

    async deleteStudent({id, setFetching}) {
        setFetching(true)
        try {
            await (await axios.delete(this._baseUrl + "/student/delete/" + id, this._header))
            toast.success('Xóa thành công')
        } catch (e) {
            toast.warn(e.response.data.message)
        } finally {
            setFetching(false)
        }
    }

}