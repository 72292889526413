const DateTimeInput = ({label, name, data, setData, placeholder}) => {
    return (
        <div className={"px-2"}>
            <label htmlFor="first_name"
                   className="block mb-2 text-sm font-medium text-gray-900 ">{label ?? name}</label>
            <input type="datetime-local" id={name}
                   value={data[name] || ''}
                   onChange={(r) => {
                       setData({...data, [name]: r.target.value})
                   }}
                   className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 hohobg-gray-700 hohoborder-gray-600 hohoplaceholder-gray-400  hohofocus:ring-blue-500 hohofocus:border-blue-500"
                   placeholder={placeholder} required/>
        </div>
    )
}
export default DateTimeInput