import {BrowserRouter, Route, Routes} from "react-router-dom";
import DashboardScreen from "./Common/DashboardScreen";
import Error404Screen from "./Errors/Error404Screen";
import LoginScreen from "./Common/LoginScreen";
import StudentList from "./Student/StudentList";
import StudentCreate from "./Student/StudentCreate";
import StudentDetail from "./Student/StudentDetail";
import ClassroomListScreen from "./Classroom/ClassroomListScreen";
import ClassroomCreateScreen from "./Classroom/ClassroomCreateScreen";
import CardListScreen from "./Card/CardListScreen";
import CardCreateScreen from "./Card/CardCreateScreen";
import StudyLogList from "./StudyLog/StudyLogList";
import StudyLogAttendance from "./StudyLog/StudyLogAttendance";
import CardInfoScreen from "./Card/CardInfoScreen";
import SettingScreen from "./Setting/SettingScreen";
import CustomFieldsCreateScreen from "./Setting/CustomFieldsCreateScreen";
import TeacherListScreen from "./Teacher/TeacherListScreen";
import TeacherCreateScreen from "./Teacher/TeacherCreateScreen";
import AccountCreateScreen from "./Account/AccountCreateScreen";
import AccountListScreen from "./Account/AccountListScreen";
import SupporterListScreen from "./Supporter/SupporterListScreen";
import SupporterCreateScreen from "./Supporter/SupporterCreateScreen";
import Error403Screen from "./Errors/Error403Screen";
import ClassroomDetailScreen from "./Classroom/ClassroomDetailScreen";
import AddCardToClassroomPopup from "../popup/AddCardToClassroomPopup";
import QuickCreateCardPopup from "../popup/QuickCreateCardPopup";
import CardFeedbackPopup from "../popup/CardFeedbackPopup";
import TeacherDetailScreen from "./Teacher/TeacherDetailScreen";
import RegisterHostScreen from "./Host/RegisterHostScreen";
import VerifyScreen from "./Common/VerifyScreen";
import BranchList from "./Branch/BranchList";
import PermissionGroupList from "./PermissionGroup/PermissionGroupList";
import QuickCreateStudent from "../popup/QuickCreateStudent";
import QuickCreateClassroomPopup from "../popup/QuickCreateClassroomPopup";
import QuickCreateTeacherPopUp from "../popup/QuickCreateTeacherPopup";
import QuickCreateSupporterPopUp from "../popup/QuickCreateSupporterPopup";


const RootScreen = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} Component={DashboardScreen}/>
                <Route path={"/login"} Component={LoginScreen}/>

                <Route path={"/student/list"} Component={StudentList}/>
                <Route path={"/student/create"} Component={StudentCreate}/>
                <Route path={"/student/edit/:id"} Component={StudentCreate}/>
                <Route path={"/student/detail/:id"} Component={StudentDetail}/>

                <Route path={"/classroom/list"} Component={ClassroomListScreen}/>
                <Route path={"/classroom/create"} Component={ClassroomCreateScreen}/>
                <Route path={"/classroom/edit/:id"} Component={ClassroomCreateScreen}/>
                <Route path={"/classroom/detail/:id"} Component={ClassroomDetailScreen}/>

                <Route path={"/study_log/list"} Component={StudyLogList}/>
                <Route path={"/study_log/attendance"} Component={StudyLogAttendance}/>

                <Route path={"/card/list"} Component={CardListScreen}/>
                <Route path={"/card/create"} Component={CardCreateScreen}/>
                <Route path={"/card/edit/:id"} Component={CardCreateScreen}/>
                <Route path={"/card/detail/:id"} Component={CardInfoScreen}/>

                <Route path={"/setting"} Component={SettingScreen}/>
                <Route path={"/setting/custom-field/create"} Component={CustomFieldsCreateScreen}/>

                <Route path={"/teacher/list"} Component={TeacherListScreen}/>
                <Route path={"/teacher/create"} Component={TeacherCreateScreen}/>
                <Route path={"/teacher/edit/:id"} Component={TeacherCreateScreen}/>
                <Route path={"/teacher/detail/:id"} Component={TeacherDetailScreen}/>

                <Route path={"/supporter/list"} Component={SupporterListScreen}/>
                <Route path={"/supporter/create"} Component={SupporterCreateScreen}/>
                <Route path={"/supporter/edit/:id"} Component={SupporterCreateScreen}/>

                <Route path={"/account/list"} Component={AccountListScreen}/>
                <Route path={"/account/create"} Component={AccountCreateScreen}/>
                <Route path={"/account/edit/:id"} Component={AccountCreateScreen}/>

                <Route path={"add_card_to_classroom"} Component={AddCardToClassroomPopup}/>
                <Route path={"quick_create_card"} Component={QuickCreateCardPopup}/>
                <Route path={"card_feedback"} Component={CardFeedbackPopup}/>
                <Route path={"quick_create_student"} Component={QuickCreateStudent}/>
                <Route path={"quick_create_classroom"} Component={QuickCreateClassroomPopup}/>
                <Route path={"quick_create_teacher"} Component={QuickCreateTeacherPopUp}/>
                <Route path={"quick_create_supporter"} Component={QuickCreateSupporterPopUp}/>

                <Route path={"permission_groups"} Component={PermissionGroupList}/>

                <Route path={'/select_branch'} Component={BranchList}/>
                <Route path={'/register'} Component={RegisterHostScreen}/>
                <Route path={'/verify/:username'} Component={VerifyScreen}/>
                <Route path={"/forbidden"} Component={Error403Screen}/>
                <Route path={"/*"} Component={Error404Screen}/>
            </Routes>
        </BrowserRouter>
    )
}
export default RootScreen;