import {useContext, useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {boxLibrary} from "../box-icon-init";
import app from "../App";
import {AppContext} from "../AppContext";

export const MenuItem = ({icon, name, link, sub, onClick}) => {

    sub = sub || []
    const nav = useNavigate()
    return (
        <div onClick={onClick || null}
             className={"relative group text-white cursor-pointer hover:bg-blue-800 p-2 transition-all"}>
            <Link to={link}>
                <div className={"text-center mb-0"}>
                    {icon}
                </div>
                <div className={"text-xs"}>{name}</div>
            </Link>
            {sub.length > 0 ?
                <div className={"absolute hidden group-hover:block top-0 min-w-[15rem] left-[3.5rem]"}>
                    <div className={"ml-4 text-gray-700 shadow bg-white border text-left text-sm rounded"}>
                        {sub.map((item, key) =>
                            <div key={key} className={"p-2 hover:bg-gray-100 transition-all"}>
                                <Link to={item.link}>
                                    {item.label}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>

                : null
            }
        </div>
    )
}
const SideBarMenu = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const logout = () => {
        app.authService.logout().then()
    }
    const [menu, setMenu] = useState([]);
    useEffect(() => {
        let permissionMenu = [
            {
                name: 'Trang chủ',
                link: '/',
                icon: boxLibrary.home
            }
        ]
        if (app.acl?.permissions.includes('customer list')) {
            permissionMenu.push({
                name: "KH tiềm năng",
                link: "/customer/list",
                icon: 'bx bxs-user'
            })
        }
        if (app.acl?.permissions.includes('student list')) {
            permissionMenu.push({
                name: "Học sinh",
                link: '/student/list',
                icon: 'bx bxs-user',
                sub: [
                    {
                        link: "/student/list",
                        label: "Danh sách học sinh"
                    },
                    {
                        link: "/student/create",
                        label: "Thêm học sinh mới"
                    }
                ]
            })
        }
        if (app.acl?.permissions.includes('card list')) {
            permissionMenu.push({
                name: "Thẻ học",
                link: '/card/list',
                icon: 'bx bxs-credit-card',
                sub: [
                    {
                        link: "/card/list",
                        label: "Danh sách thẻ học"
                    },
                    {
                        link: "/card/create",
                        label: "Thêm thẻ học mới"
                    }
                ]
            })
        }
        if (app.acl?.permissions.includes('classroom list')) {
            permissionMenu.push({
                name: "Lớp học",
                link: '/classroom/list',
                icon: 'bx bxs-book-open',
                sub: [
                    {
                        link: "/classroom/list",
                        label: "Danh sách lớp học"
                    },
                    {
                        link: "/classroom/create",
                        label: "Thêm lớp học mới"
                    }
                ]
            })
        }
        if (app.acl?.permissions.includes('studylog list')) {
            permissionMenu.push({
                name: "Điểm danh",
                link: '/study_log/list',
                icon: 'bx bxl-unity',
                sub: [
                    {
                        link: "/study_log/list",
                        label: "Lịch sử điểm danh"
                    },
                    {
                        link: "/study_log/attendance",
                        label: "Thực hiện điểm danh"
                    },
                    {
                        link: "/study_log/attendance",
                        label: "Thống kê buổi học"
                    },
                    {
                        link: "/study_log/attendance",
                        label: "Doanh thu thực tế"
                    },
                    {
                        link: "/study_log/attendance",
                        label: "Thống kê buổi dạy"
                    }
                ]
            })
        }
        if (app.acl?.permissions.includes('teacher list')) {
            permissionMenu.push({
                name: "Giáo viên",
                link: '/teacher/list',
                icon: 'bx bxs-user-voice',
                sub: [
                    {
                        link: "/teacher/list",
                        label: "Danh sách giáo viên"
                    },
                    {
                        link: "/teacher/create",
                        label: "Thêm giáo viên mới"
                    }
                ]
            })
        }
        if (app.acl?.permissions.includes('supporter list')) {
            permissionMenu.push({
                name: "Trợ giảng",
                link: '/supporter/list',
                icon: 'bx bxs-user-plus',
                sub: [
                    {
                        link: "/supporter/list",
                        label: "Danh sách trợ giảng"
                    },
                    {
                        link: "/supporter/create",
                        label: "Thêm trợ giảng mới"
                    }
                ]
            })
        }
        if (app.acl?.permissions.includes('account list')) {
            permissionMenu.push({
                name: "Nhân viên",
                link: '/account/list',
                icon: 'bx bxs-user-badge',
                sub: [
                    {
                        link: "/account/list",
                        label: "Danh sách nhân viên"
                    },
                    {
                        link: "/account/create",
                        label: "Thêm nhân viên mới"
                    }
                ]
            })
        }
        if (app.acl?.permissions.includes('cashier list')) {
            permissionMenu.push({
                name: "Sổ quỹ",
                link: '/cashier/list',
                icon: 'bx bxs-report',
            })
        }
        if (app.acl?.permissions.includes('finance list')) {
            permissionMenu.push({
                name: "Tài chính",
                link: '/finance/list',
                icon: 'bx bxs-objects-vertical-center',
            })
        }
        if (app.acl?.permissions.includes('inventory list')) {
            permissionMenu.push({
                name: "Xuất nhập tồn",
                link: '/inventory/list',
                icon: 'bx bxs-box',
            })
        }

        if (app.acl?.permissions.includes('setting list')) {
            permissionMenu.push({
                name: "Phân quyền",
                link: '/permission_groups',
                icon: 'bx bxs-key',
            })
        }
        setMenu(permissionMenu)
    }, [app.acl])
    return (
        <ul className={"text-center"}>
            {menu.map((item, key) =>
                <MenuItem
                    key={key}
                    icon={<i className={item.icon}></i>}
                    name={item.name}
                    link={item.link}
                    sub={item.sub || []}
                />
            )}
            <MenuItem
                icon={<i className='bx bxs-lock-open'></i>}
                name={"Đăng xuất"}
                link={"logout"}
                onClick={() => {
                    logout()
                }}
            />
        </ul>
    )
}
export default SideBarMenu