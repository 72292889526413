// axiosConfig.js
import axios from 'axios';
import {toast} from "react-toastify";

const instance = axios.create();

// Thêm các interceptors nếu cần thiết
instance.interceptors.request.use(
    (config) => {
        // Thực hiện các xử lý trước khi gửi yêu cầu
        return config;
    },
    (error) => {

        // return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // Xử lý phản hồi từ máy chủ
        return response;
    },
    (error) => {
        switch (error.response.status) {
            case 404:
                window.location.replace('/404')
                break
            case 400:
                break
            case 403:
                window.location.replace('/forbidden')
                break
            case 401:
                window.location.replace('/login')
        }
        return Promise.reject(error);
    }
);

export default instance;
