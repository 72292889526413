import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../AppContext";
import {Link} from "react-router-dom";
import TextFilter from "../../components/filter/TextFilter";
import SelectFilter from "../../components/filter/SelectFilter";
import Paginate from "../../components/table/Paginate";
import Select from "react-select";
import MainLayout from "../../layouts/MainLayout";
import FetchingAnimation from "../../components/FetchingAnimation";
import EmptyItem from "../../components/EmptyItem";
import ActionCol from "../../components/table/ActionCol";
import CustomFieldsColData from "../../components/CustomFieldsColData";
import TextCol from "../../components/table/TextCol";
import IconLinkCol from "../../components/table/IconLinkCol";
import HeadTable from "../../components/table/HeadTable";
import CustomFieldsCols from "../../components/CustomFieldsCols";
import {toast} from "react-toastify";
import UserInfoCol from "../../components/table/UserInfoCol";
import ListCol from "../../components/table/ListCol";

const StudentList = () => {
    const app = useContext(AppContext);
    const [students, setStudents] = useState([]);
    const startFilterAction = useRef(null);
    const [fetching, setFetching] = useState(false);
    const perPageSelect = [
        {value: "10", label: "10"},
        {value: "20", label: "20"},
        {value: "50", label: "50"},
        {value: "100", label: "100"},
    ];
    const [filter, setFilter] = useState({});
    const [config, setConfig] = useState({
        perPage: 10,
        currentPage: 1,
        totalPage: 10,
        totalRecord: 0,
        startRecord: 1,
        endRecord: 10,
    });
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            startFilter()
        }
    };
    const fetchData = () => {
        const query = {
            postData: {
                limit: config.perPage,
                offset: (config.currentPage - 1) * config.perPage,
                conditions: transformFilterObject(filter),
            },
            config: config,
            setConfig: setConfig,
            setFetching: setFetching,
            setStudents: setStudents,
        };

        app.studentService.getListStudent(query).then()
    };

    function transformFilterObject(inputObject) {
        const transformedObject = {};

        for (const key in inputObject) {
            if (inputObject[key] && typeof inputObject[key] === 'object') {
                const {operator, value} = inputObject[key];
                transformedObject[`${key}:${operator}`] = value;
            }
        }

        return transformedObject;
    }

    useEffect(()=>{
        startFilter()
    },[filter])

    useEffect(() => {
        fetchData();
    }, [config]);
    const confirmDelete = () => {

    }

    function startFilter() {
        fetchData()
    }

    return (
        <MainLayout>
            <div className={"p-3"}>
                <div className={"text-blue-900 font-bold text-2xl mb-3"}>
                    Danh sách học sinh
                </div>
                <div className={"mb-3 flex flex-wrap"}>
                    <Link to={"/student/create"}
                          className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                        <i className='bx bxs-plus-circle mr-2'></i>
                        Thêm học sinh
                    </Link>
                    <button
                        onClick={() => {
                            toast.success("Xuất dữ liệu thành công", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 1000,
                            })
                        }}
                        className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mr-2 mb-3"}>
                        <i className='bx bxs-download mr-2'></i>
                        Xuất dữ liệu
                    </button>
                    <button
                        className={"bg-blue-900 md:w-auto w-full text-white p-2 rounded hover:bg-blue-800 transition-all mb-3"}>
                        <i className='bx bxs-cloud-upload mr-2'></i>
                        Nhập dữ liệu
                    </button>
                </div>
                <div className={"z-50 mb-3 md:flex block flex-wrap w-full"}>
                    <TextFilter
                        setFilter={setFilter}
                        filter={filter}
                        name={"name"}
                        icon={<i className='bx bxs-user-circle'></i>}
                        placeholder={"Tên học sinh"}

                    />
                    <SelectFilter
                        setFilter={setFilter}
                        filter={filter}
                        icon={<i className='bx bxs-phone-call'></i>}
                        name={"gender"}
                        options={[
                            {
                                value: 0,
                                label: "Nam"
                            },
                            {
                                value: 1,
                                label: "Nữ"
                            }
                        ]}

                        placeholder={"Giới tính"}
                    />
                    <SelectFilter
                        setFilter={setFilter}
                        filter={filter}
                        icon={<i className='bx bxs-phone-call'></i>}
                        name={"birthday_month"}
                        operator={'handler'}
                        options={[
                            {value: 1, label: "Tháng 1"},
                            {value: 2, label: "Tháng 2"},
                            {value: 3, label: "Tháng 3"},
                            {value: 4, label: "Tháng 4"},
                            {value: 5, label: "Tháng 5"},
                            {value: 6, label: "Tháng 6"},
                            {value: 7, label: "Tháng 7"},
                            {value: 8, label: "Tháng 8"},
                            {value: 9, label: "Tháng 9"},
                            {value: 10, label: "Tháng 10"},
                            {value: 11, label: "Tháng 11"},
                            {value: 12, label: "Tháng 12"},
                        ]}
                        placeholder={"Tháng sinh nhật"}
                    />
                    <SelectFilter
                        setFilter={setFilter}
                        filter={filter}
                        name={"classroom_id"}
                        operator={'handler'}
                        options={students.selectClassrooms || []}
                        icon={<i className='bx bxs-user-circle'></i>}
                        placeholder={"Lớp học"}

                    />
                    <SelectFilter
                        setFilter={setFilter}
                        filter={filter}
                        name={"account_id"}
                        operator={'handler'}
                        options={students.selectAccounts || []}
                        icon={<i className='bx bxs-user-circle'></i>}
                        placeholder={"Nhân viên phụ trách"}

                    />
                    <button
                        ref={startFilterAction}
                        onClick={() => {
                            startFilter()
                        }}
                        className={"h-[2.5rem] w-full md:w-auto bg-blue-900 p-2 text-white rounded px-4 hover:bg-blue-800 transition-all"}>
                        Lọc
                    </button>
                    <button
                        onClick={() => {
                            setFilter({})
                        }}
                        className={"ml-2 h-[2.5rem] w-full md:w-auto bg-blue-900 p-2 text-white rounded px-4 hover:bg-blue-800 transition-all"}>
                        Reset
                    </button>
                </div>
                <div className={"flex flex-row flex-wrap justify-between mb-3"}>

                    <Paginate config={config} setConfig={setConfig}/>
                    <div className={"flex items-center text-xl md:text-sm "}>
                        <span className={"mr-2 "}>Hiển thị: </span>
                        <Select
                            onChange={(r) => {
                                setConfig({...config, perPage: r.value})
                            }}
                            className={"text-sm p-0 m-0 z-[99]"}
                            defaultValue={perPageSelect[0]}
                            placeholder={""}
                            options={perPageSelect}/>
                        <span className={"ml-2 text-xl md:text-sm"}>hàng
                        ( Từ hàng
                            <span className={"font-bold mx-1"}>
                                {config.startRecord}
                            </span>
                            đến hàng
                            <span className={"font-bold mx-1"}>
                            {config.endRecord}
                            </span>
                            trên tổng số
                            <span className={"font-bold mx-1"}>
                            {config.totalRecord}
                            </span>
                            hàng)
                        </span>
                    </div>

                </div>
                <div className={"max-h-[65vh] overflow-auto"}>
                    <table className={"table border shadow-lg min-w-max w-full"}
                           style={{borderCollapse: "separate", borderSpacing: 0}}>
                        <thead>
                        <tr>
                            <HeadTable label={'Student ID'} style={{zIndex: "30", left: 0}}/>
                            <HeadTable label={'Họ và tên'} style={{position: "sticky", zIndex: "30", left: "10rem"}}/>
                            <HeadTable label={'Tên tiếng Anh'}/>
                            <HeadTable label={'Danh sách thẻ học'}/>
                            <HeadTable label={'Chăm sóc 1-1'}/>
                            <HeadTable label={'Giới tính'}/>
                            <HeadTable label={'Sinh nhật'}/>
                            <HeadTable label={'Số điện thoại'}/>
                            <HeadTable label={'Facebook'}/>
                            <HeadTable label={'Email'}/>
                            {students.custom_fields !== [] ?
                                <CustomFieldsCols
                                    custom_fields={students.custom_fields}
                                /> : null
                            }
                            <HeadTable label={'Hành động'}/>
                        </tr>
                        </thead>
                        {!fetching ?
                            <tbody>
                            {students.students?.map((student, key) =>
                                <tr key={key}>
                                    <TextCol value={student.uuid || ''}
                                             style={{position: "sticky", zIndex: "20", left: 0}}/>
                                    <UserInfoCol
                                        username={student.id || key}
                                        avatar={student.avatar || 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'}
                                        name={student.name || ''}
                                        address={student.address || ''}
                                        entity={"student"}
                                        style={{position: "sticky", zIndex: "20", left: "10rem"}}/>
                                    <TextCol
                                        style={{textAlign: "center"}}
                                        value={student.english_name || '-'}
                                    />
                                    <ListCol
                                        style={{textAlign: "center"}}
                                        value={student.cards || ''}
                                    />
                                    <IconLinkCol
                                        img={"https://play-lh.googleusercontent.com/Zs0-SVFd7FHABCT6buDhAVmOZHlIJS8Z37LMmWghcJV9N4ryZfpI0dZa0jzejcJ3cQ"}
                                        style={{textAlign: "center"}}
                                        value={student.zalo_1_1 || ''}
                                    />
                                    <TextCol
                                        style={{textAlign: "center"}}
                                        value={student.gender || ''}
                                    />
                                    <TextCol
                                        style={{textAlign: "center"}}
                                        value={student.birthday || ''}
                                    />
                                    <TextCol
                                        style={{textAlign: "center"}}
                                        value={student.phone || ''}
                                    />
                                    <IconLinkCol
                                        img={"https://www.facebook.com/images/fb_icon_325x325.png"}
                                        style={{textAlign: "center"}}
                                        value={student.facebook_link || ''}
                                    />
                                    <TextCol
                                        style={{textAlign: "center"}}
                                        value={student.student_email || ''}
                                    />
                                    {student.custom_fields !== []
                                        ?
                                        <CustomFieldsColData
                                            custom_fields={students.custom_fields || []}
                                            data={student}
                                        />
                                        :
                                        null
                                    }
                                    <ActionCol
                                        confirmDelete={confirmDelete}
                                        entity={'student'}
                                        id={student.id || key}
                                    />
                                </tr>
                            )}
                            </tbody> :
                            null}
                    </table>
                    {students.length === 0 ?
                        <EmptyItem/>
                        : null}
                </div>
                {fetching ? <FetchingAnimation/> : null}
            </div>
        </MainLayout>
    )
}
export default StudentList;