import Select from "react-select";

const SelectInput = ({label, name, data, setData, placeholder, options, required, nullable = false}) => {
    return (
        <div className={"px-2"}>
            {label ?
                <label htmlFor="first_name"
                       className="flex mb-2 text-sm font-medium text-gray-900 ">{label ?? name}
                    {required ? <span className={"ml-1 font-black text-md text-red-600"}>*</span> : null}
                </label>
                : null}
            <Select
                isClearable={true}
                defaultValue={!nullable ? options[0] : null}
                options={options}
                value={data[name]}
                className={"text-black"}
                onChange={(r) => setData({...data, [name]: r})}
                placeholder={placeholder}
            />
        </div>
    )
}
export default SelectInput