import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import MainLayout from "../../layouts/MainLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import SelectInput from "../../components/inputs/SelectInput";
import StudentAttendanceInput from "../../components/inputs/StudentAttendanceInput";
import FetchingAnimation from "../../components/FetchingAnimation";
import {useNavigate} from "react-router-dom";
import ImageInput from "../../components/inputs/ImageInput";
import RichTextInput from "../../components/inputs/RichTextInput";
import VideoRecordInput from "../../components/inputs/VideoRecordInput";
import TextInput from "../../components/inputs/TextInput";
import DateInput from "../../components/inputs/DateInput";
import ShiftsAttendanceInput from "../../components/inputs/ShiftsAttendanceInput";
import QuickAddClassroom from "../../components/QuickAddClassroom";

const StudyLogAttendance = () => {
    const [analytic, setAnalytic] = useState({
        alive: 0,
        leave: 0,
        none_status: 0,
        cal: 0,
        not_cal: 0,
        total: 0,
    })

    const [fetching, setFetching] = useState(false)

    const [data, setData] = useState({
        classroom: {},
        teacher: {},
        supporter: {},
        students: [],
        video: {},
        calendar: {},
        shifts: []
    })
    const [hintCalendars, setHintCalendars] = useState([])
    useEffect(() => {
        if (data.classroom !== undefined) {
            console.log(init.hintCalendars)
            setHintCalendars(init.hintCalendars?.filter(c => parseInt(c.classroom_id) === parseInt(data.classroom.value)))
            console.log('hints', hintCalendars)
        }
    }, [data.classroom]);
    useEffect(() => {
        if (data.students !== undefined) {
            let alive = 0
            let leave = 0
            let none_status = 0
            let cal = 0
            let not_cal = 0
            setAnalytic({...analytic, total: data.students.length})
            data.students.map((student) => {
                if (student.attendance_status !== undefined) {
                    if (student.attendance_status.label.includes('Đi học')) {
                        alive++
                    }
                    if (student.attendance_status.label.includes('Vắng')) {
                        leave++
                    }
                    if (student.attendance_status.label.includes('điểm danh')) {
                        none_status++
                    }
                }
                if (student.calculate_status !== undefined) {
                    if (student.calculate_status.label.includes('Có')) {
                        cal++
                    }
                    if (student.calculate_status.label.includes('Không')) {
                        not_cal++
                    }

                }
            })
            setAnalytic({
                ...analytic,
                alive: alive,
                total: data.students.length,
                leave: leave,
                none_status: none_status,
                cal: cal,
                not_cal: not_cal
            })
        }
    }, [data]);
    const app = useContext(AppContext)
    const [init, setInit] = useState({
        initClassrooms: [],
        initTeachers: [],
        initSupporters: [],
        initStudent: [],
        initCalendar: [],
        initShifts: [],
    });
    useEffect(() => {
        setData({
            ...data,
            shifts: init.initShifts,
            students: init.initStudents
        })
    }, [init])
    const nav = useNavigate();
    const attendanceEffect = async (data) => {
        app.studyLogService.createStudyLog({setFetching: setFetching, data: data}).then(r=>{
            if(r === true){
                nav("/study_log/list")
            }
        })

    }
    useEffect(() => {
        app.studyLogService.getInitInBranch({
            setInit: setInit,
            init: init
        }).then()
    }, [])

    useEffect(() => {
        if (data.classroom !== {}) {
            if (data.classroom.value !== undefined && data.selected_day !== undefined) {
                setData({...data, calendar: null})
                setInit({...init, initCalendar: []})
                app.studyLogService.getCalendarByClassAndDate({
                    data: init,
                    setData: setInit,
                    postData: {
                        classroom_id: data.classroom.value,
                        selected_day: data.selected_day,
                    }
                }).then()
            }
        }
    }, [data.selected_day])

    useEffect(() => {
        setInit({
            ...init,
            initShifts: [],
            initStudent: [],
            initCalendar: [],
            initTeachers: [],
            initSupporters: [],
        })
        setData({
            ...data,
            students: [],
            shifts: [],
            calendar: null,
            selected_day: null,
        })
        if (data.classroom.value !== undefined) {
            app.studyLogService.getStudyLogInitData({
                postData: {
                    classroom_id: data.classroom.value,
                },
                setInit: setInit,
                init: init
            }).then()
        }

    }, [data.classroom])

    useEffect(() => {
        if (data.calendar !== null) {
            app.studyLogService.getStudyLogShiftData({
                postData: {
                    classroom_id: data.classroom.value,
                    calendar_id: data.calendar.value
                },
                setInit: setInit,
                init: init
            }).then()
        }
    }, [data.calendar]);

    useEffect(() => {
        document.title = "Điểm danh"
    }, [])
    return (
        <MainLayout>
            {!fetching ?
                <div>
                    <div>
                        <Breadcrumbs
                            parent={
                                {
                                    label: "Danh sách buổi học",
                                    link: "/study-log/list"
                                }
                            }
                            label={"Điểm danh"}
                        />
                    </div>
                    <div className={"flex flex-wrap h-full transition-all"}>
                        <div className={"basis-full px-1 h-full"}>
                            <div>
                                <div className={"font-semibold text-xl mb-1 text-blue-900"}>Thông tin điểm danh</div>

                                <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1 mt-5"}>

                                    <div className={"md:w-1/5 w-full mb-3"}>
                                        <div className={"px-2"}>
                                            <label htmlFor={"user"}
                                                   className="block mb-2 text-sm font-medium text-gray-900 ">{"Người điểm danh"}</label>
                                            <input
                                                className={"cursor-no-drop bg-white border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 rounded"}
                                                value={"ADMIN.0001 - Phạm Quang Linh"}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className={"md:w-1/5 w-full mb-3"}>
                                        <SelectInput
                                            name={"classroom"} label={"Chọn lớp điểm danh"} placeholder={"C.00x"}
                                            data={data}
                                            setData={setData}
                                            options={init.initClassrooms}
                                            required={true}
                                        />
                                        <div className={"px-2"}>
                                            <QuickAddClassroom
                                                data={data}
                                                setData={setData}
                                                name={'classroom'}
                                            />
                                        </div>
                                    </div>

                                    <div className={"md:w-1/5 w-full mb-3"}>
                                        <DateInput
                                            name={"selected_day"} label={"Ngày học"}
                                            setData={setData}
                                            data={data}
                                            required={true}
                                        />
                                    </div>
                                    <div className={"md:w-1/5 w-full mb-3"}>
                                        <SelectInput
                                            name={"calendar"} label={"Chọn buổi học"}
                                            setData={setData}
                                            data={data}
                                            options={init.initCalendar || []}
                                            nullable={true}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className={"p-2"}>
                                    <div className={"my-2 text-sm"}>Danh sách buổi học của lớp</div>
                                    <table className="table-auto border border-collapse border-gray-300">
                                        <thead>
                                        {hintCalendars?.length?<tr>
                                            <th className="px-4 py-2 border-b">Thứ</th>
                                            <th className="px-4 py-2 border-b">Bắt đầu</th>
                                            <th className="px-4 py-2 border-b">Kết thúc</th>
                                        </tr>:
                                        <span className={"p-1 text-sm"}>Hãy chọn lớp học trước </span>
                                        }
                                        </thead>
                                        <tbody>
                                        {hintCalendars?.map((hint, key) =>
                                            <tr>
                                                <td className="px-4 py-2 border-b">{hint.week_day}</td>
                                                <td className="px-4 py-2 border-b">{hint.start}</td>
                                                <td className="px-4 py-2 border-b">{hint.end}</td>
                                            </tr>
                                        )}

                                        </tbody>
                                    </table>

                                </div>
                                <ShiftsAttendanceInput
                                    name={'shifts'}
                                    data={data}
                                    setData={setData}
                                    init={init}
                                    setInit={setInit}
                                />
                                <StudentAttendanceInput
                                    name={"students"}
                                    data={data}
                                    setData={setData}
                                    init={init}
                                    setInit={setInit}
                                />
                                <div className={"text-sm mb-1"}>Thống kê điểm danh:</div>
                                <div className={"flex flex-wrap mb-3"}>
                                    <div className={"basis-1/8"}>
                                        <div className={"p-1 text-sm bg-green-600 text-white rounded mr-2"}>
                                            <span> Đi học: </span>
                                            <span>{analytic.alive} / {analytic.total || 0}</span>
                                        </div>
                                    </div>
                                    <div className={"basis-1/8"}>
                                        <div className={"p-1 text-sm bg-red-800 text-white rounded mr-2"}>
                                            <span> Vắng: </span>
                                            <span>{analytic.leave} / {analytic.total || 0}</span>
                                        </div>
                                    </div>
                                    <div className={"basis-1/8"}>
                                        <div className={"p-1 text-sm bg-gray-800 text-white rounded mr-2"}>
                                            <span> Không điểm danh: </span>
                                            <span>{analytic.none_status} / {analytic.total || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"text-sm mb-1"}>Thống kê trừ buổi:</div>
                                <div className={"flex flex-wrap mb-3"}>
                                    <div className={"basis-1/8"}>
                                        <div className={"p-1 text-sm bg-green-800 text-white rounded mr-2"}>
                                            <span>Trừ buổi: </span>
                                            <span>{analytic.cal} / {analytic.total || 0}</span>
                                        </div>
                                    </div>
                                    <div className={"basis-1/8"}>
                                        <div className={"p-1 text-sm bg-gray-800 text-white rounded mr-2"}>
                                            <span>Không trừ buổi: </span>
                                            <span>{analytic.not_cal} / {analytic.total || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"flex flex-wrap my-10"}>
                                    <div className={"basis-full mb-3"}>
                                        <RichTextInput
                                            name={'document'}
                                            label={'Nội dung bài học'}
                                            data={data}
                                            setData={setData}
                                            height={"300px"}
                                            required={true}

                                        />
                                    </div>
                                    <div className={"md:basis-1/2 basis-full"}>
                                        <VideoRecordInput
                                            name={"video"}
                                            label={"Video bài học"}
                                            data={data}
                                            setData={setData}
                                        />
                                    </div>
                                    <div className={"md:basis-1/2 basis-full"}>
                                        <TextInput
                                            name={"attachments"}
                                            label={"Tài liệu"}
                                            data={data}
                                            setData={setData}
                                            placeholder={'Link drive'}
                                        />
                                    </div>
                                </div>
                                <div className={"flex flex-wrap"}>
                                    <div className={"md:basis-1/2 basis-full"}>
                                        <ImageInput
                                            name={"study_image"}
                                            data={data}
                                            setData={setData}
                                            label={"Ảnh có mặt toàn bộ học sinh"}
                                        />
                                    </div>
                                    <div className={"md:basis-1/2 basis-full"}>
                                        <RichTextInput
                                            name={'note'}
                                            label={'Ghi chú danh sách tên của các học sinh CÓ VÀO HỌC, nhưng CHƯA THẤY ĐƯỢC XẾP VÀO LỚP NÀY trên hệ thống, sẽ được admin ĐIỂM DANH BÙ'}
                                            data={data}
                                            setData={setData}
                                            height={"300px"}
                                        />
                                    </div>

                                </div>
                                <hr className={"my-5"}/>
                                <button
                                    onClick={() => {
                                        attendanceEffect(data).then()
                                    }}
                                    className={"hover:bg-blue-600 transition-all bg-blue-900 text-white p-2 rounded-md"}>
                                    Điểm
                                    danh
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                : <FetchingAnimation/>}

        </MainLayout>
    )
}
export default StudyLogAttendance;

