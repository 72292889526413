const EndDrawer = ({children, show, setShow}) => {
    return (
        <div className={"z-[999999] transition-all absolute w-full h-full right-0" + (show ? "" : " hidden")}>
            <div className={"bg-black bg-opacity-50 h-full flex justify-end"}>
                <div className={"bg-white p-5 flex flex-col items-baseline w-[40%] relative"}>
                    <div className={"overflow-y-scroll h-full w-full pr-2"}>
                        {children}
                    </div>
                    <div className={"border-t-2 w-full pt-2"}></div>

                    <div>
                        <div
                            onClick={()=>setShow(false)}
                            className={"border-2 cursor-pointer hover:bg-blue-600 transition-all w-[2rem] h-[2rem] flex justify-center items-center bg-blue-950 rounded-full absolute -left-4  top-[25rem] shadow"}>
                            <i className='bx bxs-chevron-right text-white'></i>
                        </div>
                        <button onClick={() => {
                            setShow(false)
                        }} className={"text-white bg-blue-950 p-2 rounded"}>
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EndDrawer