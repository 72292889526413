import MainLayout from "../../layouts/MainLayout";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import {useNavigate, useParams} from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/inputs/TextInput";
import FetchingAnimation from "../../components/FetchingAnimation";
import SelectMultiInput from "../../components/inputs/SelectMultiInput";
import CalendarInput from "../../components/inputs/CalendarInput";
import AvatarInput from "../../components/inputs/AvatarInput";
import SelectInput from "../../components/inputs/SelectInput";

const ClassroomCreateScreen = () => {
    const nav = useNavigate()
    const app = useContext(AppContext)
    const [init, setInit] = useState({})
    const [label, setLabel] = useState("")
    const {id} = useParams()
    useEffect(() => {
        if (id) {
            setLabel("Chỉnh sửa thông tin lớp học")
            app.classroomService.getClassroomById({id, setData, setFetching}).then()
        } else {
            setLabel("Thêm lớp học mới")
        }
    }, [])
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({
        calendars: [],
        cards: [],
    })
    const uploadClassroom = () => {
        if (id) {
            app.classroomService.updateClassroom({
                id: id,
                postData: data,
                setFetching: setFetching
            }).then((isUpdated) => {
                if (isUpdated) {
                    nav('/classroom/list')
                }
            })
        } else {
            app.classroomService.createNewClassroom({postData: data, setFetching: setFetching}).then()
        }
    }
    useEffect(() => {
        app.classroomService.initForClassroom({setInit, setFetching}).then()
    }, [1])

    const quickCreateCard = () => {
        const width = 600;
        const height = 800;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const popup = window.open('/quick_create_card', 'popup', `width=${width},height=${height},left=${left},top=${top}`);

        window.addEventListener('message', (event) => {
            if (event.origin === window.location.origin) {
                const dataFromPopup = event.data;
                setData({...data, cards: [...data.cards, event.data]})
                setInit({...data, cardInit: [...init.cardInit, event.data]})
            }
        });
    }

    return (
        <MainLayout>
            <div>
                <Breadcrumbs
                    parent={
                        {
                            label: "Lớp học",
                            link: "/classroom/list"
                        }
                    }
                    label={label}
                />
                {!fetching ? <div>
                        <div className={"flex flex-wrap h-full transition-all"}>
                            <div className={"lg:w-3/4 px-1 h-full"}>
                                <div>
                                    <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                                        <div className={"w-full mb-3"}>
                                            <AvatarInput
                                                name={"thumbnail"} label={"Ảnh đại diện lớp"}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"classroom_name"} label={"Tên lớp học"} placeholder={""}
                                                data={data}
                                                setData={setData}
                                                required={true}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"lesson_social_group"}
                                                label={"Link nhóm Zalo nội bộ trao đổi nội dung dạy"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"parent_group"} label={"Link nhóm Zalo hội phụ huynh"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"student_group"} label={"Link nhóm Zalo hội học sinh"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>

                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <TextInput
                                                name={"textbook_name"}
                                                label={"Tên giáo trình sử dụng"}
                                                placeholder={""}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                        <div className={"md:w-1/2 w-full mb-3"}>
                                            <SelectInput
                                                name={"account_id"} label={"Nhân viên phụ trách"} placeholder={""}
                                                data={data}
                                                setData={setData}
                                                nullable={true}
                                                options={init.accountInit || []}
                                                required={true}
                                            />
                                        </div>


                                        <div className={"w-full mb-3"}>
                                            <CalendarInput
                                                init={init}
                                                name={"calendars"}
                                                label={"Lịch học"}
                                                data={data}
                                                setData={setData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"lg:w-1/4 px-1 bg-gray-50 border p-2 rounded"}>
                                <div>
                                    <SelectMultiInput
                                        name={"cards"} label={"Thẻ học"} placeholder={""}
                                        data={data}
                                        setData={setData}
                                        nullable={true}
                                        options={init.cardInit || []}
                                    />
                                </div>
                                <button onClick={quickCreateCard}
                                        className={"my-3 flex justify-center items-center mx-1 px-2 text-sm text-green-800 rounded"}>
                                    <i className={"bx bxs-plus-circle mr-1"}></i>
                                    Thêm thẻ học mới
                                </button>
                            </div>
                        </div>
                        <div className={"w-full mb-3 px-2 mt-5"}>
                            {!id ? <button
                                    onClick={uploadClassroom}
                                    className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Thêm mới
                                    lớp học
                                </button> :
                                <button
                                    onClick={uploadClassroom}
                                    className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Chỉnh
                                    sửa lớp học
                                </button>
                            }
                        </div>
                    </div> :
                    <FetchingAnimation/>}
            </div>
        </MainLayout>
    )
}
export default ClassroomCreateScreen