import MainLayout from "../../layouts/MainLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useContext, useEffect, useState} from "react";
import TextInput from "../../components/inputs/TextInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import AvatarInput from "../../components/inputs/AvatarInput";
import FetchingAnimation from "../../components/FetchingAnimation";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Select from "react-select";
import {AppContext} from "../../AppContext";
import CustomFields from "../../components/inputs/CustomFields";

const SupporterCreateScreen = () => {
    const [customFields, setCustomFields] = useState([])
    const app = useContext(AppContext)
    const [currentExtraInformation, setCurrentExtraInformation] = useState(null)
    const [label, setLabel] = useState("")
    const {id} = useParams()
    useEffect(() => {
        if (id) {
            setLabel("Chỉnh sửa thông tin trợ giảng")

        } else {
            setLabel("Thêm trợ giảng mới")
        }
    }, [])
    const nav = useNavigate()
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({})
    const addExtraInformation = ({label}) => {
        const newExtraInformation = {
            label: label,
            value: ""
        }
        if (data.extra_information === undefined) {
            setData({...data, extra_information: [newExtraInformation]})
        } else if (!data.extra_information.some(item => item.label === label)) {
            setData({...data, extra_information: [...data.extra_information, newExtraInformation]})
        } else {
            toast.warn("Thông tin đã có sẵn", {
                autoClose: 200
            })
        }
    }
    const removeExtraInformation = ({label}) => {
        console.log(label);
        const newData = data.extra_information.filter(item => item.label !== label)
        setData({...data, extra_information: newData})
    }
    const setValueForExtraInformation = ({value, label}) => {
        const newData = data.extra_information.map((item) => {
            if (item.label === label) {
                return {label: label, value: value}
            }
            return item
        })
        setData({...data, extra_information: newData})
    }
    const uploadSupporter = async () => {
        if (id) {
             app.supporterService.updateSupporter({id: id, postData: {...data}, setFetching: setFetching}).then(r=>{
                 nav("/supporter/list")
             })
        } else {
            app.supporterService.createSupporter({postData: {...data}, setFetching: setFetching}).then(r=>{
                if(r){
                    nav("/supporter/list")
                }
            })
        }

    }
    useEffect(() => {
        document.title = label
    })
    useEffect(() => {
        if (id) {
            setFetching(true)
            app.supporterService.getSupporterById({
                id: id,
                setData: setData,
                setFetching: setFetching
            }).then()
        }
        app.supporterService.getSupporterCustomFields({setCustomFields: setCustomFields}).then()

    }, [])
    const [init, setInit] = useState({})
    return (
        <MainLayout>
            <div>
                <Breadcrumbs
                    parent={
                        {
                            label: "Trợ giảng",
                            link: "/supporter/list"
                        }
                    }
                    label={label}
                />
            </div>
            {!fetching ? <div>
                    <div className={"flex flex-wrap h-full transition-all"}>
                        <div className={"lg:w-3/4 px-1 h-full"}>
                            <div>
                                <div className={"font-semibold text-xl mb-1 text-blue-900"}>Thông tin cơ bản *</div>
                                <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                                    <div className={"w-full"}>
                                        <AvatarInput data={data} setData={setData} label={"Ảnh trợ giảng"}
                                                     placeholder={"Chọn ảnh"}
                                                     name={"avatar"}/>
                                    </div>
                                    <div className={"md:w-1/2 w-full mb-3"}>
                                        <TextInput
                                            name={"name"} label={"Tên trợ giảng"} placeholder={"Nguyễn Văn A"} data={data}
                                            setData={setData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"lg:w-1/4 px-1 "}>
                            {customFields !== [] ?
                                <div>
                                    <div className={"font-semibold text-xl mb-1"}>Thông tin thêm</div>
                                    <div>
                                        {customFields.map((customField) =>
                                            <CustomFields
                                                key={customField.key}
                                                data={data}
                                                setData={setData}
                                                customDataKey={'extra_information'}
                                                fieldKey={customField.key}
                                                label={customField.label}
                                                type={customField.type}
                                            />
                                        )}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className={"w-full mb-3 px-2 mt-5"}>
                        {!id ? <button
                                onClick={uploadSupporter}
                                className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Thêm mới trợ giảng
                            </button> :
                            <button
                                onClick={uploadSupporter}
                                className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>Chỉnh sửa
                                trợ giảng
                            </button>
                        }
                    </div>
                </div> :
                <FetchingAnimation/>}

        </MainLayout>
    )
}
export default SupporterCreateScreen