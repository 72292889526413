import axios from './globalApi'
import {toast} from "react-toastify";
import {appVendor} from "../config";
import {useState} from "react";

export class CommentServices {
    _base_url = appVendor.base_url
    _header

    constructor(token) {
        this._header = {
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }
    }

    async storeComment({data, setData, comment, setComment}) {

        try {
            const storeComment = await (await axios.post(this._base_url + "/comment/store", comment, this._header))
            setData({...data, comments: [storeComment.data, ...data.comments]})
            setComment({...comment, text_content: ""})
            toast.success("Thành công !")
        } catch (e) {
            toast.warn(e.respond.data.message)
        }

    }

}