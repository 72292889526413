import UserInteractiveChip from "../../components/chip/UserInteractiveChip";
import Check from "../../components/chip/Check";
import UserInformationChip from "../../components/chip/UserInformationChip";
import StudyLogConfirmProcess from "../../components/StudyLogConfirmProcess";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../AppContext";
import {Link} from "react-router-dom";

const StudyLogTab = ({id}) => {
    const app = useContext(AppContext)
    const [log, setLog] = useState({})
    useEffect(() => {
        fetchData()
    }, [id]);
    const fetchData = () =>{
        if (id) {
            app.studyLogService.getDetailStudyLogForTab({id: id, setLog: setLog}).then()
        }
    }
    return (
        <div>
            <div className={"text-2xl font-bold uppercase text-blue-900 mb-3"}>Chi tiết điểm danh</div>
            <div className={"flex flex-wrap border-t border-b pb-2 px-2"}>
                <div className={"md:basis-1/2 basis-full p-1"}>
                    <div className={"mb-3 font-bold"}>Thời gian buổi học</div>
                    <div className={"flex text-xs opacity-80"}>
                        <div className={"mr-3"}>
                            <div>Bắt đầu: {log.startTime || ''}</div>
                            <div>Bắt đầu: {log.endTime || ''}</div>
                            <div>Thời lượng: {log.duration || 0} phút</div>
                        </div>
                        <div
                            className={"font-bold bg-blue-950 text-white rounded-full w-[3rem] h-[3rem] flex justify-center items-center"}>
                            {log.week_day || ''}
                        </div>
                    </div>
                </div>
                <div className={"md:basis-1/2 basis-full p-1 pr-3"}>
                    <div className={"mb-3 font-bold"}>Người thực hiện điểm danh:</div>
                    <UserInteractiveChip
                        avatar={log.interactive_user?.avatar || ''}
                        name={log.interactive_user?.name || ''}
                        time={log.created_at}
                    />
                </div>

            </div>
            <div className={"my-5"}>
                <div className={"font-bold mb-2"}>Nội dung buổi học:</div>
                <div className={"border p-2 rounded"}>
                    <div dangerouslySetInnerHTML={{__html: log.document ?? ''}}>

                    </div>
                </div>
            </div>
            <div>
                <div className={"font-bold mb-2"}>Học sinh:</div>
                <div className={"flex mb-2"}>
                    <div className={"text-white text-xs p-1 rounded shadow bg-green-600 mr-2"}>Đi học:
                        2/{log.studentLogList?.length}</div>
                    <div className={"text-white text-xs p-1 rounded shadow bg-red-800 mr-2"}>Vắng:
                        1/{log.studentLogList?.length}</div>
                    <div className={"text-white text-xs p-1 rounded shadow bg-gray-600"}>Không điểm danh:
                        1/{log.studentLogList?.length}</div>
                </div>
                <div>
                    <div className={"overflow-x-scroll my-5"}>
                        <table className={"border text-sm study-card-log-table min-w-[60rem]"}>
                            <thead>
                            <tr className={"border"}>
                                <td className={"font-bold border px-2 sticky left-0 bg-white"}>Mã thẻ học</td>
                                <td className={"font-bold border px-2"}>Mã học sinh</td>
                                <td className={"font-bold border px-2"}>Tên học sinh</td>
                                <td className={"font-bold border px-2"}>Trạng thái điểm danh</td>
                                <td className={"font-bold border px-2"}>Trừ buổi</td>
                                <td className={"font-bold border px-2"}>GV Nhận xét</td>
                                <td className={"font-bold border px-2"}>TG Nhận xét</td>
                            </tr>
                            </thead>
                            <tbody>
                            {log.studentLogList?.map((student, key) =>
                                <tr className={"border"}>
                                    <td className={"px-2 border p-1 sticky left-0 bg-white"}>{student.card_uuid}</td>
                                    <td className={"px-2 border p-1"}>{student.student_uuid}</td>
                                    <td className={"px-2 border p-1"}>{student.student_name}</td>
                                    <td className={"px-2 border p-1"}>{student.attendance_status}</td>
                                    <td className={"px-2 border p-1"}><Check value={student.hasCalculate}/></td>
                                    <td className={"px-2 border p-1"}>{student.teacher_comment}</td>
                                    <td className={"px-2 border p-1"}>{student.supporter_comment}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className={"font-bold mb-2"}>Ca học:</div>
                    <div className={"overflow-x-scroll my-5"}>

                        <table className={"border text-sm study-card-log-table min-w-[60rem]"}>
                            <thead>
                            <tr className={"border"}>
                                <td className={"font-bold border px-2 sticky left-0 bg-white"}>Ca</td>
                                <td className={"font-bold border px-2"}>Bắt đầu</td>
                                <td className={"font-bold border px-2"}>Kết thúc</td>
                                <td className={"font-bold border px-2"}>Phòng học</td>
                                <td className={"font-bold border px-2"}>Giáo viên</td>
                                <td className={"font-bold border px-2"}>Trợ giảng</td>
                                <td className={"font-bold border px-2"}>Báo cáo của giáo viên</td>
                                <td className={"font-bold border px-2"}>Báo cáo của trợ giảng</td>
                            </tr>
                            </thead>
                            <tbody>
                            {log.working_shifts?.map((shift, key) =>
                                <tr key={key} className={"border"}>
                                    <td className={"px-2 border p-1 sticky left-0 bg-white"}>{key+1}</td>
                                    <td className={"px-2 border p-1"}>{shift.start}</td>
                                    <td className={"px-2 border p-1"}>{shift.end}</td>
                                    <td className={"px-2 border p-1"}>{shift.room}</td>
                                    <td className={"px-2 border p-1"}>
                                        <Link target={"_blank"} className={"font-bold text-blue-900"} to={"/teacher/"+shift.teacher_id}>{shift.teacher_name}</Link>
                                        <a target={"_blank"} href={shift.teacher_timestamp}><i className='ml-2 bx bxs-file-image text-red-900 cursor-pointer'></i></a>
                                    </td>
                                    <td className={"px-2 border p-1"}>
                                        <Link target={"_blank"} className={"font-bold text-blue-900"} to={"/supporter/"+shift.supporter_id}>{shift.supporter_name}</Link>
                                        <a target={"_blank"} href={shift.supporter_timestamp}><i className='ml-2 bx bxs-file-image text-red-900 cursor-pointer'></i></a>
                                    </td>
                                    <td className={"px-2 border p-1"}>{shift.teacher_report}</td>
                                    <td className={"px-2 border p-1"}>{shift.supporter_report}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div>
                <div className={"font-bold mb-2"}>Ảnh điểm danh:</div>
                <div>
                    <a target={"_blank"}
                       href={log.study_image}>
                        <img alt={""} className={"w-[5rem] rounded"}
                             src={log.study_image}/>
                    </a>
                </div>
                <div className={"my-3"}>
                    <div className={"font-bold mb-2"}>Ghi chú tên các học sinh cần điểm danh bù nếu có</div>
                    <div className={"border p-2 text-sm text-gray-600 rounded"}>
                        <div dangerouslySetInnerHTML={{__html:log.study_note}}></div>
                    </div>
                </div>
            </div>
            <div>
                <div className={"font-bold mb-2"}>Phản hồi của phụ huynh - học sinh về buổi học:</div>
                {log.reports?.map((report, key) =>
                    <div key={key} className={"text flex items-center"}>
                        <span><i
                            className={"bx  mr-2 text-xl " + (report.valid ? "text-green-400 bxs-check-circle" : "text-red-800 bxs-x-circle")}></i></span>
                        <span>HS/PH <b>{report.name}</b> xác nhận {report.valid ? " đúng " : " sai "} thông tin </span>
                        <span className={"italic"}>{report.message}</span>
                    </div>
                )}


            </div>
            <div className={"my-5"}>
                <div className={"font-bold"}>Duyệt buổi học</div>

                <StudyLogConfirmProcess log={log} id={id} fetchData={fetchData}/>

            </div>
            <div onClick={fetchData} className={"cursor-pointer"}>Làm mới nếu quy trình duyệt bị lỗi</div>
            <div className={"h-[5rem]"}></div>
        </div>
    );
}
export default StudyLogTab