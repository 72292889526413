import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {AppContext} from "../AppContext";
import PopupLayout from "../layouts/PopupLayout";
import AvatarInput from "../components/inputs/AvatarInput";
import TextInput from "../components/inputs/TextInput";
import CustomFields from "../components/inputs/CustomFields";
import FetchingAnimation from "../components/FetchingAnimation";

const QuickCreateSupporterPopUp = () => {
    const [customFields, setCustomFields] = useState([])
    const app = useContext(AppContext)
    const [fetching, setFetching] = useState(false)
    const [data, setData] = useState({})
    const uploadTeacher = async () => {
        app.supporterService.createSupporter({postData: {...data}, setFetching: setFetching}).then((r) => {
            if (r) {
                window.opener.postMessage({
                    value: r.data?.id,
                    label:r.data?.name +" - "+ r.data?.uuid
                }, '*');
                window.close()
            }
        })

    }
    useEffect(() => {
        app.supporterService.getSupporterCustomFields({setCustomFields: setCustomFields}).then()
    }, [])
    return (<PopupLayout>
        {!fetching ? <div>
            <div className={"flex flex-wrap h-full transition-all"}>
                <div className={"lg:w-3/4 w-full px-1 h-full mb-5"}>
                    <div>
                        <div className={"font-semibold text-xl mb-1 text-blue-900"}>Thông tin cơ bản *</div>
                        <div className={"flex flex-wrap w-full border p-2 rounded bg-gray-50 pt-5 pb-1"}>
                            <div className={"w-full"}>
                                <AvatarInput data={data} setData={setData} label={"Ảnh giáo viên"}
                                             placeholder={"Chọn ảnh"}
                                             name={"avatar"}/>
                            </div>

                            <div className={"md:w-1/2 w-full mb-3"}>
                                <TextInput
                                    name={"name"} label={"Tên giáo viên"} placeholder={"Nguyễn Văn A"} data={data}
                                    setData={setData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"lg:w-1/4 w-full px-1 "}>
                    {customFields !== [] ? <div>
                        <div className={"font-semibold text-xl mb-1"}>Thông tin thêm</div>
                        <div>
                            {customFields.map((customField) => <CustomFields
                                key={customField.key}
                                data={data}
                                setData={setData}
                                customDataKey={'extra_information'}
                                fieldKey={customField.key}
                                label={customField.label}
                                type={customField.type}
                            />)}
                        </div>
                    </div> : null}
                </div>
            </div>
            <div className={"w-full mb-3 px-2 mt-5"}>
                <button
                    onClick={uploadTeacher}
                    className={"p-2 cursor-pointer hover:bg-blue-800 rounded bg-blue-900 text-white"}>
                    Thêm trợ giảng
                </button>
            </div>
        </div> : <FetchingAnimation/>
        }

    </PopupLayout>)
}
export default QuickCreateSupporterPopUp