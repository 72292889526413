import MainLayout from "../../layouts/MainLayout";
import NewsFeed from "../../components/NewsFeed";

const DashboardScreen = () => {
    return (
        <MainLayout>
            <div className={"flex flex-wrap"}>
                <div className={"basis-1/4"}></div>
                <div className={"basis-1/2"}>

                </div>
                <div className={"basis-1/4"}></div>
            </div>
        </MainLayout>
    )
}
export default DashboardScreen