import {appVendor} from "../config";
import axios from './globalApi'
import {toast} from "react-toastify";

export class SettingService {
    _baseUrl
    _header

    constructor(token, baseUrl) {

        this._header = {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
        this._baseUrl = appVendor.base_url
    }

    async getAllCustomFields({setCustomFields}) {
        try {
            const CustomFieldsResponse = await (await axios.get(this._baseUrl + '/setting/list/custom_fields', this._header))
            setCustomFields(CustomFieldsResponse.data)
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    async createNewCustomFields({postData, setFetching}) {
        setFetching(true)
        try {
            await (await axios.post(this._baseUrl + '/setting/store/custom_fields', postData, this._header))
            toast.success('Thêm mới thành công')
            return true
        } catch (e) {
            toast.warning(e.response.data.message)
            return false;
        } finally {
            setFetching(false)

        }
    }
}